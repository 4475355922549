import React, { useMemo, useReducer } from 'react';
import { Divider, Header } from 'semantic-ui-react';
import NamespaceRouteForm from './NamespaceRouteForm';
import NamespaceRouteTable from './NamespaceRouteTable';
import NMService from '../../../../../services/nm.service';
import { useSetAtom } from 'jotai';
import { addGlobalMessageAtom } from '../../../../../store/globalMessage';
import ConfirmationModal from '../../../../../components/modal/ConfirmationModal';

const reducer = (state, action) => {
    switch (action.type) {
        case 'update':
            return { ...state, ...action.payload };
        default:
            throw new Error();
    }
};

function NamespaceRoutes(props) {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const nodeId = useMemo(() => props.nodeId || [], [props.nodeId]);
    const namespace = useMemo(() => props.data, [props.data]);
    const routes = useMemo(() => props.data.routes || [], [props.data.routes]);
    const [state, dispatchLocal] = useReducer(reducer, {
        selectedRoute: {},
        showFormType: undefined,
        showDeleteModal: false,
    });

    const toggleForm = (showFormType = undefined, selectedRoute = {}) => dispatchLocal({ type: 'update', payload: { selectedRoute, showFormType } })

    const toggleDeleteModal = (showDeleteModal = false, selectedRoute = {}) =>
        dispatchLocal({ type: 'update', payload: { selectedRoute: selectedRoute, showDeleteModal: showDeleteModal, showFormType: undefined }, })

    const onDeleteRoute = () => {
        const p = state.selectedRoute.destination.split('/');
        if (!state.selectedRoute.namespace || state.selectedRoute.namespace === 'default') {
            NMService.deleteRouteConfig(props.nodeId, p[0], p[1]).then((r) =>
                    addGlobalMessage({
                        header: 'Deleted route',
                        content: 'Route ssuccessfully deleted',
                        type: 'positive',
                    })
            ).then(() => {
                toggleDeleteModal();
                props.updateNamespacesConfig();
            }).catch(e => null)
        } else {
            NMService.deleteRouteConfigNamespace(props.nodeId, p[0], p[1], state.selectedRoute.namespace).then((r) =>
                    addGlobalMessage({
                        header: 'Deleted route configuration',
                        content: 'Route successfully deleted',
                        type: 'positive',
                    })
                ).then(() => {
                    toggleDeleteModal();
                    props.updateNamespacesConfig();
                }).catch(e => null)
        }
    };

    return (
        <>
            <Header as="h5" style={{ marginTop: '1rem', marginBottom: '0' }}>
                Route table
            </Header>
            <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
            <NamespaceRouteTable
                data={routes}
                toggleForm={toggleForm}
                deleteRoute={toggleDeleteModal}
            />
            {state.showFormType && (
                <NamespaceRouteForm
                    data={state.selectedRoute}
                    namespace={namespace.name}
                    type={state.showFormType}
                    nodeId={nodeId}
                    interfaces={namespace.interfaces}
                    updateNamespacesConfig={props.updateNamespacesConfig}
                    toggleForm={toggleForm}
                />
            )}
            <ConfirmationModal
                open={state.showDeleteModal}
                header="Delete route"
                content={`Are you sure you want to delete route ${state.selectedRoute.destination}?`}
                onConfirm={() => onDeleteRoute()}
                onDismiss={() => toggleDeleteModal()}
            />
        </>
    );
}

export default NamespaceRoutes;
