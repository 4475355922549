export const evaluateWhen = (condition, watchFields) => {
    const getWhenComponent = (value) => {
        if (!value) return;
        const reValueComponent = /^[ ]*(and|or|not)?[ ]*(.*) ([=!]+) (.*)$/;
        let match = value.match(reValueComponent);
        if (match == null) {
            return null;
        }
        return {
            boolOp: match[1],
            variable: match[2].slice(1).replace('/', '.'),
            oper: match[3].trim(),
            value: match[4].trim(),
        };
    };

    const splitWhen = (when) => {
        if (!when) return;
        return when
            .replace(/ and /g, ' ;and')
            .replace(/ or /g, ' ;or')
            .replace(/ not /g, ' ;not')
            .split(';')
            .map((e) => getWhenComponent(e))
            .filter((e) => e);
    };

    let conditionList = [];
    try {
        conditionList = splitWhen(condition);
    } catch {
        conditionList = [];
    }

    let formattedCondition = '';
    conditionList.forEach((e) => {
        if (e.boolOp) {
            if (e.boolOp !== 'nor') {
                formattedCondition += ` ${e.boolOp === 'or' ? '||' : '&&'} '${watchFields[e.variable]}' ${e.oper} '${e.value
                    }'`;
            } else {
                formattedCondition += ` !(${watchFields[e.variable]} || ${e.value})`;
            }
        } else {
            formattedCondition += `'${watchFields[e.variable]}' ${e.oper} '${e.value}'`;
        }
    });

    try {
        return eval(formattedCondition);
    } catch {
        return true;
    }
};

const checkInteger = (value, unsigned, typeMin, typeMax, schemaMin, schemaMax) => {
    if (isNaN(value)) {
        return 'value is not valid integer';
    }

    value = parseInt(value);
    if (!Number.isInteger(value)) {
        return 'value is not valid integer';
    }

    if (unsigned) {
        if (value < 0) {
            return 'value must be positive integer';
        }
    }

    return checkLimits(value, typeMin, typeMax, schemaMin, schemaMax);
};

const checkString = (value, schema) => {
    const hasmaxlength = schema.hasOwnProperty('maxlength');
    const hasminlength = schema.hasOwnProperty('minlength');
    const hasregex = schema.hasOwnProperty('regex');

    if (value === undefined && (hasmaxlength || hasminlength || hasregex)) {
        return 'field is mandatory';
    }

    if (hasmaxlength) {
        if (value.length > schema['maxlength']) {
            return 'value is too long, should be up to ' + schema['maxlength'] + ' characters';
        }
    }
    if (hasminlength) {
        if (value.length < schema['minlength']) {
            return 'value is too short, should be at least ' + schema['minlength'] + ' characters long';
        }
    }

    if (hasregex) {
        let re = new RegExp(schema['regex']);
        if (!re.test(value)) {
            return 'value is not in valid format, should match regex ' + schema['regex'];
        }
    }
    return undefined;
};

const checkFloat = (value, unsigned, typeMin, typeMax, schemaMin, schemaMax) => {
    if (isNaN(value)) {
        return 'value is not valid number';
    }

    value = parseFloat(value);
    if (unsigned) {
        if (value < 0) {
            return 'value must be positive number';
        }
    }
    return checkLimits(value, typeMin, typeMax, schemaMin, schemaMax);
};

const checkLimits = (value, typeMin, typeMax, schemaMin, schemaMax) => {
    if (value < typeMin) {
        return 'value must be greater than ' + typeMin;
    }

    if (value > typeMax) {
        return 'value must be lower than ' + typeMax;
    }

    if (schemaMin !== undefined && value < schemaMin) {
        return 'value must be greater than ' + schemaMin;
    }

    if (schemaMax !== undefined && value > schemaMax) {
        return 'value must be lower than ' + schemaMax;
    }

    return undefined;
};

const checkIpAddress = (value) => {
    const regexExp =
        /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;

    if (!regexExp.test(value)) {
        return 'value is not valid IP address';
    }
    return undefined;
};

export const validateLeaf = (value, schema) => {
    let schemaMin = undefined;
    let schemaMax = undefined;

    if (schema.hasOwnProperty('mandatory')) {
        if (schema['mandatory'] === true) {
            if (value === '' || value === undefined) {
                return 'field is mandatory';
            }
        } else {
            if (value !== 0 && !value) {
                return undefined;
            }
        }
    } else {
        if (value !== 0 && !value) {
            return undefined;
        }
    }

    const ftype = schema.type;
    if (schema.hasOwnProperty('minvalue')) {
        schemaMin = schema['minvalue'];
    }
    if (schema.hasOwnProperty('minValue')) {
        schemaMin = schema['minValue'];
    }
    if (schema.hasOwnProperty('maxvalue')) {
        schemaMax = schema['maxvalue'];
    }
    if (schema.hasOwnProperty('maxValue')) {
        schemaMax = schema['maxValue'];
    }

    if (ftype === 'string') {
        return checkString(value, schema);
    }

    if (ftype === 'ipaddress') {
        return checkIpAddress(value);
    }

    if (ftype === 'uint8') {
        return checkInteger(value, true, 0, 255, schemaMin, schemaMax);
    } else if (ftype === 'uint16') {
        return checkInteger(value, true, 0, 65535, schemaMin, schemaMax);
    } else if (ftype === 'uint32') {
        return checkInteger(value, true, 0, 4294967295, schemaMin, schemaMax);
    } else if (ftype === 'ufloat') {
        return checkFloat(value, true, 0, 4294967295, schemaMin, schemaMax);
    } else if (ftype === 'float') {
        return checkFloat(value, false, -4294967295, 4294967295, schemaMin, schemaMax);
    } else if (ftype === 'int') {
        return checkInteger(value, false, -4294967295, 4294967295, schemaMin, schemaMax);
    }

    return undefined;
};

export const getError = (name, errors) => {
    return name
        .split('.')
        .map((e) => e)
        .filter((e) => e)
        .reduce(function (prev, curr) {
            return prev ? prev[curr] : null;
        }, errors);
};