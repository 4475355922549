import axios from 'axios';
import { BASE_URL, BASE_URL_NM } from '../config';

export function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token')
    return (user && token) ? { Authorization: 'Bearer ' + token } : {}
}

export default class NMService {
    // ************** Nodes & assigned modules
    static getNodes() {
        return axios.get(BASE_URL_NM + '/inventory/node/fullview', { headers: authHeader() });
    }
    static getNodeDetails(nodeid) {
        return axios.get(BASE_URL_NM + '/inventory/node/' + nodeid, { headers: authHeader() });
    }
    static getAllNodeModules() {
        return axios.get(BASE_URL_NM + '/inventory/nodemodule/', { headers: authHeader() });
    }
    static getNodeModules(nodeid) {
        return axios.get(BASE_URL_NM + '/inventory/nodemodule/node/' + nodeid, { headers: authHeader() });
    }
    static getAvailableModules(nodeid) {
        return axios.get(BASE_URL_NM + '/inventory/nodemodule/available/' + nodeid, { headers: authHeader() });
    }
    static getAllNodesModules() {
        return axios.get(BASE_URL_NM + '/inventory/nodemoduleall', { headers: authHeader() });
    }
    static getModuleList() {
        return axios.get(BASE_URL_NM + '/inventory/module/', { headers: authHeader() });
    }
    static updateNode(nodedata) {
        return axios.post(BASE_URL_NM + '/inventory/node', nodedata, { headers: authHeader() });
    }
    static deleteNode(nodeid) {
        return axios.delete(BASE_URL_NM + '/inventory/node/' + nodeid, { headers: authHeader() });
    }
    static deleteNodeModule(nodeid, moduleid, instanceid) {
        return axios.delete(BASE_URL_NM + '/inventory/nodemodule/' + moduleid + '/' + nodeid + '/' + instanceid, { headers: authHeader() });
    }
    static getNodeVpnConfig(id) {
        return axios.get(BASE_URL_NM + '/inventory/node/vpn/' + id, { headers: authHeader() });
    }
    static updateNodeVpnConfig(id, data) {
        return axios.post(BASE_URL_NM + '/inventory/node/vpn/' + id, data, { headers: authHeader() });
    }
    /* Declarate installed module as unassign module */
    static assignModule(moduledata) {
        return axios.post(BASE_URL_NM + '/inventory/nodemodule', moduledata, { headers: authHeader() });
    }
    static updateNodePermissions(nodeid, permissions) {
        return axios.post(BASE_URL_NM + '/inventory/node/permissions/' + nodeid, permissions, { headers: authHeader() });
    }
    static updateNodeKeys(hwid) {
        return axios.post(BASE_URL_NM + '/inventory/node/keys/' + hwid, {}, { headers: authHeader() });
    }
    static getNodeModule(nodeid, moduleid, instanceid) {
        return axios.get(BASE_URL_NM + '/inventory/nodemodule/' + moduleid + '/' + nodeid + '/' + instanceid, { headers: authHeader() });
    }
    static updateNodeModuleConfig(nodedata) {
        return axios.post(BASE_URL_NM + '/inventory/nodemodule/updateconfig', nodedata, { headers: authHeader() });
    }
    static updateNodeModuleRunningConfig(nodedata) {
        return axios.post(BASE_URL_NM + '/inventory/nodemodule/updaterunningconfig', nodedata, { headers: authHeader() });
    }
    static updateNodeNetworkConfig(nodedata) {
        return axios.post(BASE_URL_NM + '/inventory/nodemodule/updatenetworkconfig', nodedata, { headers: authHeader() });
    }
    static updateNodeModulePermissions(permissions) {
        return axios.post(BASE_URL_NM + '/inventory/nodemodule/permissions/', permissions, { headers: authHeader() });
    }
    static saveModulesAsTemplate(templateData) {
        return axios.post(BASE_URL_NM + '/inventory/nodemoduletemplate/fromnode', templateData, { headers: authHeader() });
    }
    static assignTemplateToNode(assignData) {
        return axios.post(BASE_URL_NM + '/inventory/nodemoduletemplate/assign', assignData, { headers: authHeader() });
    }
    static assignTemplateToNodeList(assignData) {
        return axios.post(BASE_URL_NM + '/inventory/nodemoduletemplate/assignlist', assignData, { headers: authHeader() });
    }
    static getNodeTags() {
        return axios.get(BASE_URL_NM + '/inventory/node/tags', { headers: authHeader() });
    }
    static getNodeGroups(nodeid) {
        return axios.get(BASE_URL_NM + '/inventory/node/' + nodeid, { headers: authHeader() });
    }
    static getNodeModuleGraph(nodeid, moduleid, instanceid) {
        return axios.get(BASE_URL_NM + '/inventory/visualization/dashboard/instance/' + nodeid + '/' + moduleid + "/" + instanceid, { headers: authHeader() });
    }
    static deleteNodeModuleGraph(graphId) {
        return axios.delete(BASE_URL_NM + '/inventory/visualization/dashboard/' + graphId, { headers: authHeader() });
    }
    static saveNodeModuleGraph(graphData) {
        return axios.post(BASE_URL_NM + '/inventory/visualization/dashboard', graphData, { headers: authHeader() });
    }
    static getNodeModuleEnabledPanels(moduleid) {
        return axios.get(BASE_URL_NM + '/inventory/visualization/template/panel/module/active/' + moduleid, { headers: authHeader() });
    }
    static createDefaultGraphsForNode(nodeid) {
        return axios.post(BASE_URL_NM + '/inventory/visualization/dashboard/' + nodeid, {}, { headers: authHeader() });
    }
    static refreshGrafanaDashboard( dashUid ) {
        return axios.post(BASE_URL_NM + '/inventory/visualization/dashboard/refresh/' + dashUid, {}, { headers: authHeader() });
    }
    static getNodeModuleActivePanels(dashboardId) {
        return axios.get(BASE_URL_NM + '/inventory/visualization/panel/' + dashboardId, { headers: authHeader() });
    }
    static deleteNodeModuleActivePanel(uid, panelid) {
        return axios.delete(BASE_URL_NM + '/inventory/visualization/panel/' + uid + '/' + panelid, { headers: authHeader() });
    }
    static saveNodeModuleActivePanel(panelData) {
        return axios.post(BASE_URL_NM + '/inventory/visualization/panel', panelData, { headers: authHeader() });
    }
    static getSnmpTemplate () {
        return axios.get(BASE_URL_NM + '/snmp/template', { headers: authHeader() });
    }
    static getSnmpTemplateByName (name) {
        return axios.get(BASE_URL_NM + '/snmp/template/' + name, { headers: authHeader() });
    }
    static addSnmpTemplate (data) {
        return axios.post(BASE_URL_NM + '/snmp/template', data, { headers: authHeader() });
    }
    static updateSnmpTemplate (data) {
        return axios.put(BASE_URL_NM + '/snmp/template', data, { headers: authHeader() });
    }
    static deleteSnmpTemplateByName (name) {
        return axios.delete(BASE_URL_NM + '/snmp/template/' + name, { headers: authHeader() });
    }
    static getSnmpSecurityProfile () {
        return axios.get(BASE_URL_NM + '/snmp/securityprofile', { headers: authHeader() });
    }
    static getSnmpSecurityProfileByUuid (uuid) {
        return axios.get(BASE_URL_NM + '/snmp/securityprofile/' + uuid, { headers: authHeader() });
    }
    static addSnmpSecurityProfile (data) {
        return axios.post(BASE_URL_NM + '/snmp/securityprofile', data, { headers: authHeader() });
    }
    static updateSnmpSecurityProfile (data) {
        return axios.put(BASE_URL_NM + '/snmp/securityprofile', data, { headers: authHeader() });
    }
    static deleteSnmpSecurityProfileByUuid (uuid) {
        return axios.delete(BASE_URL_NM + '/snmp/securityprofile/' + uuid, { headers: authHeader() });
    }
    static getSnmpHostGroup () {
        return axios.get(BASE_URL_NM + '/snmp/hostgroup', { headers: authHeader() });
    }
    static getSnmpHostGroupByUuid (uuid) {
        return axios.get(BASE_URL_NM + '/snmp/hostgroup/' + uuid, { headers: authHeader() });
    }
    static addSnmpHostGroup (data) {
        return axios.post(BASE_URL_NM + '/snmp/hostgroup', data, { headers: authHeader() });
    }
    static updateSnmpHostGroup (data) {
        return axios.put(BASE_URL_NM + '/snmp/hostgroup', data, { headers: authHeader() });
    }
    static deleteSnmpHostGroupByUuid (uuid) {
        return axios.delete(BASE_URL_NM + '/snmp/hostgroup/' + uuid, { headers: authHeader() });
    }
    static getFolders(nodeid) {
        return axios.get(BASE_URL_NM + '/inventory/visualization/folder/' + nodeid, { headers: authHeader() });
    }
    static getNodeSnapshots() {
        return axios.get(BASE_URL_NM + '/inventory/snapshot', { headers: authHeader() });
    }
    static saveNodeSnapshot(snapshotData) {
        return axios.post(BASE_URL_NM + '/inventory/snapshot', snapshotData, { headers: authHeader() });
    }
    static applyNodeSnapshot(snapid) {
        return axios.post(BASE_URL_NM + '/inventory/applysnapshot/' + snapid, {}, { headers: authHeader() });
    }
    static deleteNodeSnapshot(snapid) {
        return axios.delete(BASE_URL_NM + '/inventory/snapshot/' + snapid, { headers: authHeader() });
    }
    static suspendMeasurements(suspendMeasurements) {
        return axios.post(BASE_URL_NM + '/inventory/nodemodule/suspendmeasurements', suspendMeasurements, { headers: authHeader() });
    }
    static getsuspendedMeasurements(params) {
        const queryItems = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
        return axios.get(BASE_URL_NM + '/inventory/nodemodule/suspendmeasurements?' + queryItems.join('&'), { headers: authHeader() });
    }

    // *************** reporting
    static getGrafanaDashboards() {
        return axios.get(BASE_URL_NM + '/inventory/grafana/dashboard', { headers: authHeader() });
    }
    static getReports() {
        return axios.get(BASE_URL_NM + '/inventory/grafana/report', { headers: authHeader() });
    }
    static addReport(report) {
        return axios.post(BASE_URL_NM + '/inventory/grafana/report', report, { headers: authHeader() });
    }
    static deleteReport(reportId) {
        return axios.delete(BASE_URL_NM + '/inventory/grafana/report/' + reportId, { headers: authHeader() });
    }

    // ************** license management stuff
    static getModulesBundle(nodeid) {
        return axios.get(BASE_URL_NM + '/inventory/assignedmodule/bundle/' + nodeid, { headers: authHeader() });
    }
    static getInstalledModules(nodeid) {
        return axios.get(BASE_URL_NM + '/inventory/installedmodule/' + nodeid, { headers: authHeader() });
    }
    static installAdditionalModule(data) {
        return axios.post(BASE_URL_NM + '/inventory/installedmodule/', data, { headers: authHeader() });
    }
    static deleteInstalledModule(nodeid, module, version) {
        return axios.delete(BASE_URL_NM + '/inventory/installedmodule/' + nodeid + '/' + module + '/' + version, { headers: authHeader() });
    }
    static deleteAllInstalledModule(nodeid) {
        return axios.delete(BASE_URL_NM + '/inventory/installedmodule/' + nodeid, { headers: authHeader() });
    }
    
    static getAssignedModules(nodeid) {
        return axios.get(BASE_URL_NM + '/inventory/assignedmodule/' + nodeid, { headers: authHeader() });
    }
    static assignUnassignedModule(data) {
        return axios.post(BASE_URL_NM + '/inventory/assignedmodule', data, { headers: authHeader() });
    }
    static unassignModule(uuuid) {
        return axios.delete(BASE_URL_NM + '/inventory/assignedmodule/' + uuuid, { headers: authHeader() });
    }
    static assignToTenant(data) {
        return axios.post(BASE_URL_NM + '/inventory/registry/assigntenant', data, { headers: authHeader() });
    }
    static getUnassignedModules(nodeid) {
        return axios.get(BASE_URL_NM + '/inventory/unassignedmodule/' + nodeid, { headers: authHeader() });
    }
    static deleteUnassignedModule(uuuid) {
        return axios.delete(BASE_URL_NM + '/inventory/unassignedmodule/' + uuuid, { headers: authHeader() });
    }
    static deleteAllUnassignedModule(nodeid) {
        return axios.delete(BASE_URL_NM + '/inventory/unassignedmodule/node/' + nodeid, { headers: authHeader() });
    }
    static unassignAllModule(nodeid){
        return axios.delete(BASE_URL_NM + '/inventory/assignedmodule/node/' + nodeid, { headers: authHeader(), });
    }
    static setModuleFavouite(data){
        return axios.post(BASE_URL_NM + '/inventory/tenantmodule/favourite', data, { headers: authHeader(), });
    }
    // ************** registry stuff

    static getRegistry() {
        return axios.get(BASE_URL_NM + '/inventory/registry', { headers: authHeader() });
    }
    static getRegistryDetails(hwid) {
        return axios.get(BASE_URL_NM + '/inventory/registry/' + hwid, { headers: authHeader() });
    }
    static getUnmanagedNoTenantProbes() {
        return axios.get(BASE_URL_NM + '/inventory/registry/unmanagednotenant', { headers: authHeader() });
    }
    static manageNode(hwid) {
        return axios.post(BASE_URL_NM + '/inventory/registry/' + hwid + '/manage', {}, { headers: authHeader() });
    }
    static deleteUnmanagedNode(hwid) {
        return axios.delete(BASE_URL_NM + '/inventory/registry/' + hwid, { headers: authHeader() });
    }

    // *************** software modules stuff
    static getSWModules() {
        return axios.get(BASE_URL_NM + '/inventory/module', { headers: authHeader() });
    }
    static deleteSWModule ( moduleid, version ) {
        return axios.delete ( BASE_URL_NM + '/inventory/module/delete/' + moduleid  + "/" + version, { headers: authHeader() } );
    }
    static uploadSWModule ( data ) {
        return axios.post ( BASE_URL_NM + '/inventory/module/upload', data, { headers: authHeader() });
    }
    static getSWModuleDetails(name, version) {
        return axios.get(BASE_URL_NM + '/inventory/module/' + name + '/' + version, { headers: authHeader() });
    }
    static getSWModuleVisDashTemplates(name) {
        return axios.get(BASE_URL_NM + '/inventory/visualization/template/dashboard/module/' + name, { headers: authHeader() });
    }
    static updateSWModuleVisDashTemplate(templatedata) {
        return axios.post(BASE_URL_NM + '/inventory/visualization/template/dashboard', templatedata, { headers: authHeader() });
    }
    static deleteSWModuleVisDashTemplate(moduleid, templateid) {
        return axios.delete(BASE_URL_NM + '/inventory/visualization/template/dashboard/' + moduleid + '/' + templateid, { headers: authHeader() });
    }
    static getSWModuleVisPanelTemplates(name) {
        return axios.get(BASE_URL_NM + '/inventory/visualization/template/panel/module/' + name, { headers: authHeader() });
    }
    static updateSWModuleVisPanelTemplate(dashboardData) {
        return axios.post(BASE_URL_NM + '/inventory/visualization/template/panel', dashboardData, { headers: authHeader() });
    }
    static deleteSWModuleVisPanelTemplate(moduleid, dashboardid) {
        return axios.delete(BASE_URL_NM + '/inventory/visualization/template/panel/' + moduleid + '/' + dashboardid, { headers: authHeader() });
    }
    static updateSWModuleCommonConfig(moduleData) {
        return axios.post(BASE_URL_NM + '/inventory/module/commonconfig', moduleData, { headers: authHeader() });
    }
    static updateSWModuleVisTemplates(moduleData) {
        return axios.post(BASE_URL_NM + '/inventory/module/vistemplates', moduleData, { headers: authHeader() });
    }
    static updateSWModuleVisDashboard(name, version, dashboardData) {
        return axios.post(BASE_URL_NM + '/inventory/module/' + name + '/' + version + '/dashboard', dashboardData, { headers: authHeader() });
    }
    static deleteSWModuleVisDashboard(name, version, dashboardUid) {
        return axios.delete(BASE_URL_NM + '/inventory/module/' + name + '/' + version + '/dashboard/' + dashboardUid, { headers: authHeader() });
    }
    static getSWModuleHistory(name, instance, node) {
        return axios.get(BASE_URL_NM + '/inventory/nodemodule/history/' + name + '/' + instance + '/' + node, { headers: authHeader() });
    }

    // *************** location stuff
    static getLocations() {
        return axios.get(BASE_URL_NM + '/inventory/location', { headers: authHeader() });
    }
    static getLocationDetails(locationId) {
        return axios.get(BASE_URL_NM + '/inventory/location/' + locationId, { headers: authHeader() });
    }
    static updateLocation(locationData) {
        return axios.post(BASE_URL_NM + '/inventory/location/', locationData, { headers: authHeader() });
    }
    static deleteLocation(locationId) {
        return axios.delete(BASE_URL_NM + '/inventory/location/' + locationId, { headers: authHeader() });
    }
    static getLocationParams() {
        return axios.get(BASE_URL_NM + '/inventory/locationparams', { headers: authHeader() });
    }
    static deleteLocationParam(paramName) {
        return axios.delete(BASE_URL_NM + '/inventory/locationparams/' + paramName, { headers: authHeader() });
    }
    static addLocationParam(paramData) {
        return axios.post(BASE_URL_NM + '/inventory/locationparams', paramData, { headers: authHeader() });
    }
    static updateLocationData(locationData) {
        return axios.post(BASE_URL_NM + '/inventory/location/data', locationData, { headers: authHeader() });
    }

    // *************** template stuff
    static getNMTemplates() {
        return axios.get(BASE_URL_NM + '/inventory/nodemoduletemplate', { headers: authHeader() });
    }
    static getNMTemplateDetails(templateid) {
        return axios.get(BASE_URL_NM + '/inventory/nodemoduletemplate/' + templateid, { headers: authHeader() });
    }
    static updateNMTemplate(templateData) {
        return axios.post(BASE_URL_NM + '/inventory/nodemoduletemplate', templateData, { headers: authHeader() });
    }
    static cloneNMtemplate(templateData) {
        return axios.post(BASE_URL_NM + '/inventory/nodemoduletemplate/clone', templateData, { headers: authHeader() });
    }
    static getNMTemplateModule(templateid, moduleid, instanceid) {
        return axios.get(BASE_URL_NM + '/inventory/nodemoduletemplate/module/' + templateid + '/' + moduleid + '/' + instanceid, { headers: authHeader() });
    }
    static updateNMTemplateModuleRunConfig(data) {
        return axios.post(BASE_URL_NM + '/inventory/nodemoduletemplate/module/update', data, { headers: authHeader() });
    }
    static updateNMTemplateModuleConfig(data) {
        return axios.post(BASE_URL_NM + '/inventory/nodemoduletemplate/module/update', data, { headers: authHeader() });
    }
    static deleteNMTemplateModule(templateid, moduleid, instanceid) {
        return axios.delete(BASE_URL_NM + '/inventory/nodemoduletemplate/' + templateid + '/' + moduleid + '/' + instanceid, { headers: authHeader() });
    }
    static addNMTemplateModule(assignData) {
        return axios.post(BASE_URL_NM + '/inventory/nodemoduletemplate/module', assignData, { headers: authHeader() });
    }
    static deleteNMTemplate(templateid) {
        return axios.delete(BASE_URL_NM + '/inventory/nodemoduletemplate/' + templateid, { headers: authHeader() });
    }
    static deleteNMHardlink(nodeid, templateid) {
        return axios.delete(BASE_URL_NM + '/inventory/nodetemplate/' + nodeid + '/' + templateid, { headers: authHeader() });
    }

    // *************** device stuff
    static getDeviceModels() {
        return axios.get(BASE_URL_NM + '/inventory/devicemodel', { headers: authHeader() });
    }
    static getDeviceModelDetails(id) {
        return axios.get(BASE_URL_NM + '/inventory/devicemodel/' + id, { headers: authHeader() });
    }

    // *************** users & groups stuff
    static getLocalUsers() {
        return axios.get(BASE_URL_NM + '/inventory/users', { headers: authHeader() });
    }
    static getLocalUser(username) {
        return axios.get(BASE_URL_NM + '/inventory/user/' + username, { headers: authHeader() });
    }
    static getGroupList() {
        return axios.get(BASE_URL_NM + '/inventory/groups', { headers: authHeader() });
    }
    static getRoleList() {
        return axios.get(BASE_URL_NM + '/useridentity/usergrouprole', { headers: authHeader() });
    }
    static getUserRoleList() {
        return axios.get(BASE_URL_NM + '/useridentity/userrole', { headers: authHeader() });
    }
    static addGroup(groupData) {
        return axios.post(BASE_URL_NM + '/useridentity/localuser/group', groupData, { headers: authHeader() });
    }
    static deleteGroup(groupName) {
        return axios.delete(BASE_URL_NM + '/useridentity/localuser/group/' + groupName, { headers: authHeader() });
    }
    static addUserLegacy(userdata) {
        return axios.post(BASE_URL_NM + '/useridentity/localuser/', userdata, { headers: authHeader() });
    }
    static addUser(userdata) {
        return axios.post(BASE_URL_NM + '/useridentity/localuser/' + userdata.username, userdata, { headers: authHeader() });
    }
    static updateUser(userdata) {
        return axios.put(BASE_URL_NM + '/useridentity/localuser/' + userdata.username, userdata, { headers: authHeader() });
    }
    static getNodeIdentityVersion() {
        return axios.get(BASE_URL_NM + '/useridentity/version/', { headers: authHeader() });
    }
    static lockLocalUser(username) {
        return axios.post(BASE_URL_NM + '/useridentity/localuser/' + username + '/lock', {}, { headers: authHeader() });
    }
    static unlockLocalUser(username) {
        return axios.post(BASE_URL_NM + '/useridentity/localuser/' + username + '/unlock', {}, { headers: authHeader() });
    }
    static addLocalUserToGroup(usergroup) {
        return axios.post(BASE_URL_NM + '/useridentity/localuser/' + usergroup.username + '/group', usergroup, { headers: authHeader() });
    }
    static removeLocalUserFromGroup(username, groupname) {
        return axios.delete(BASE_URL_NM + '/useridentity/localuser/' + username + '/group/' + groupname, { headers: authHeader() });
    }
    static deleteUser(username) {
        return axios.delete(BASE_URL_NM + '/useridentity/localuser/' + username, { headers: authHeader() });
    }
    static changePasswordForUser(passwordData) {
        return axios.post(BASE_URL_NM + '/useridentity/localuser/changepassword', passwordData, { headers: authHeader() });
    }

    // *************** alarms stuff
    static getAllAlarmRules() {
        return axios.get(BASE_URL_NM + '/inventory/alarmrule/', { headers: authHeader() });
    }
    static getAlarmRulesForModule(module, version) {
        return axios.get(BASE_URL_NM + '/inventory/alarmrule/' + module + '/' + version, { headers: authHeader() });
    }
    static getAlarmRulesForHWID(module, version, hwid) {
        return axios.get(BASE_URL_NM + '/inventory/alarmrule/' + module + '/' + version + '/' + hwid, { headers: authHeader() });
    }
    static getAlarmRulesForInstance(module, version, hwid, instanceid) {
        return axios.get(BASE_URL_NM + '/inventory/alarmrule/' + module + '/' + version + '/' + hwid + '/' + instanceid, { headers: authHeader() });
    }
    static addAlarmRule(ruleData) {
        return axios.post(BASE_URL_NM + '/inventory/alarmrule', ruleData, { headers: authHeader() });
    }
    static deleteAlarmRule(ruleID) {
        return axios.delete(BASE_URL_NM + '/inventory/alarmrule/' + ruleID, { headers: authHeader() });
    }
    static getAlarms() {
        return axios.get(BASE_URL_NM + '/data/activealarms', { headers: authHeader() });
    }
    static getAlarmSeverityList() {
        return axios.get(BASE_URL_NM + '/inventory/alarmrule/severity', { headers: authHeader() });
    }

    // *************** network management stuff
    static getFullNetworkConfig(nodeid) {
        return axios.get(BASE_URL_NM + '/inventory/node/' + nodeid + '/network/config', { headers: authHeader() });
    }
    static postFullNetworkConfig(nodeid, data) {
        return axios.post(BASE_URL_NM + '/inventory/node/' + nodeid + '/network/config', data, { headers: authHeader() });
    }
    static postInterfaceConfig(nodeid, data) {
        return axios.post(BASE_URL_NM + '/inventory/node/' + nodeid + '/network/config/interface', data, { headers: authHeader() });
    }
    static deleteInterfaceConfig(nodeid, iface) {
        return axios.delete(BASE_URL_NM + '/inventory/node/' + nodeid + '/network/config/interface/' + iface, { headers: authHeader() });
    }
    static postRouteConfig(nodeid, data) {
        return axios.post(BASE_URL_NM + '/inventory/node/' + nodeid + '/network/config/route', data, { headers: authHeader() });
    }
    static deleteRouteConfigNoUse(nodeid, p1) {
        return axios.delete(BASE_URL_NM + '/inventory/node/' + nodeid + '/network/config/route/' + p1, { headers: authHeader() });
    }
    static deleteRouteConfig(nodeid, p1, p2) {
        return axios.delete(BASE_URL_NM + '/inventory/node/' + nodeid + '/network/config/route/' + p1 + '/' + p2, { headers: authHeader() });
    }
    static deleteRouteConfigNamespace(nodeid, prefix, mask, namespace) {
        return axios.delete(BASE_URL_NM + '/inventory/node/' + nodeid + '/network/config/route/' + prefix + '/' + mask + '/' + namespace, { headers: authHeader() });
    }
    static postNamespaceConfig(nodeid, data) {
        return axios.post(BASE_URL_NM + '/inventory/node/' + nodeid + '/network/config/namespace', data, { headers: authHeader() });
    }
    static deleteNamespaceConfig(nodeid, namespace) {
        return axios.delete(BASE_URL_NM + '/inventory/node/' + nodeid + '/network/config/namespace/' + namespace, { headers: authHeader() });
    }

    // *************** configuration stuff
    static getGlobalConfigValue(configKey) {
        return axios.get(BASE_URL_NM + '/inventory/globalconfig/value/' + configKey, { headers: authHeader() });
    }
    static updateGlobalConfigValue(configValue) {
        return axios.post(BASE_URL_NM + '/inventory/globalconfig/value', configValue, { headers: authHeader() });
    }
    static getAutoProvisioningKeywords() {
        return axios.get(BASE_URL_NM + '/inventory/autoprovisioningkeyword/valid', { headers: authHeader() });
    }
    static addAutoProvisioningKeyword(autoProvisioningData) {
        return axios.post(BASE_URL_NM + '/inventory/autoprovisioningkeyword', autoProvisioningData, { headers: authHeader() });
    }
    static deleteAutoProvisioningKeyword(keyword) {
        return axios.delete(BASE_URL_NM + '/inventory/autoprovisioningkeyword/keyword/' + keyword, { headers: authHeader() });
    }

    // *************** dhcp stuff
    static getDhcpGeneralConfiguration() {
        return axios.get(BASE_URL_NM + '/services/dhcp/global', { headers: authHeader() });
    }
    static updateDhcpGeneralConfiguration(config) {
        return axios.post(BASE_URL_NM + '/services/dhcp/global', config, { headers: authHeader() });
    }
    static getDhcpOptions() {
        return axios.get(BASE_URL_NM + '/services/dhcp/options', { headers: authHeader() });
    }
    static addDhcpOption(data) {
        return axios.post(BASE_URL_NM + '/services/dhcp/option', data, { headers: authHeader() });
    }
    static deleteDhcpOption(name) {
        return axios.delete(BASE_URL_NM + '/services/dhcp/option/' + name, { headers: authHeader() });
    }
    static getDhcpSubnets() {
        return axios.get(BASE_URL_NM + '/services/dhcp/subnet', { headers: authHeader() });
    }
    static addOrUpdateDhcpSubnet(data) {
        return axios.post(BASE_URL_NM + '/services/dhcp/subnet', data, { headers: authHeader() });
    }
    static deleteDhcpSubnet(uuid) {
        return axios.delete(BASE_URL_NM + '/services/dhcp/subnet/' + uuid, { headers: authHeader() });
    }

    // *************** measurement status
    static getLatestMeasurements(hwid, moduleid, instanceid) {
        return axios.get(BASE_URL_NM + '/data/latestmeasurement/' + hwid + '/' + moduleid + '/' + instanceid, { headers: authHeader() });
    }
    static getSkippedMeasurements() {
        return axios.get(BASE_URL_NM + '/data/skippedmeasurement', { headers: authHeader() });
    }
    static updateSkippedMeasurementsPeriod(hours) {
        return axios.post(BASE_URL_NM + '/data/skippedmeasurement/period?hours=' + hours, {}, { headers: authHeader() });
    }

    // *************** multitenant
    static getTenants() {
        return axios.get(BASE_URL_NM + '/tenantmanagementservice/tenant', { headers: authHeader() });
    }
    static getTenantByID(id) {
        return axios.get(BASE_URL_NM + '/tenantmanagementservice/tenant/' + id, { headers: authHeader() });
    }
    static addTenant(data) {
        return axios.post(BASE_URL_NM + '/tenantmanagementservice/tenant', data, { headers: authHeader() });
    }
    static deleteTenant(uuid) {
        return axios.delete(BASE_URL_NM + '/tenantmanagementservice/tenant/' + uuid, { headers: authHeader() });
    }
    static activateTenant(uuid) {
        return axios.post(BASE_URL_NM + '/tenantmanagementservice/tenant/' + uuid + '/activate', {}, { headers: authHeader() });
    }
    static deactivateTenant(uuid) {
        return axios.post(BASE_URL_NM + '/tenantmanagementservice/tenant/' + uuid + '/deactivate', {}, { headers: authHeader() });
    }
    static setTenant(data) {
        return axios.post(BASE_URL_NM + '/useridentity/settenant', data, { headers: authHeader() });
    }

    // *************** common stuff
    static login() {
        return axios.post(BASE_URL_NM + '/identity/login', {});
    }
    static logout() {
        return axios.post(BASE_URL_NM + '/useridentity/logout', {}, { headers: authHeader() });
    }

    // *************** actions
    static executeOnDemand(onDemandData) {
        return axios.post(BASE_URL_NM + '/ondemand/ondemand', onDemandData, { headers: authHeader() });
    }

    // *************** machine Learning
    static MLlearn(data) {
        return axios.post(BASE_URL + '/ml/config', data, { headers: authHeader() });
    }
    static MLrelearn(data) {
        return axios.post(BASE_URL + '/ml/config/relearn', data, { headers: authHeader() });
    }
    static MLactivate(data) {
        return axios.post(BASE_URL + '/ml/config/activate', data, { headers: authHeader() });
    }
    static MLdeactivate(data) {
        return axios.post(BASE_URL + '/ml/config/deactivate', data, { headers: authHeader() });
    }
    static MLremove(data) {
        return axios.post(BASE_URL + '/ml/config/remove', data, { headers: authHeader() });
    }
    static getMLalgorithms() {
        return axios.get(BASE_URL + '/ml/algorithms', { headers: authHeader() });
    }
    static MLActivateModel(model_id) {
        return axios.get(BASE_URL + '/ml/config/activate/' + model_id, { headers: authHeader() });
    }
    static MLDeactivateModel(model_id) {
        return axios.get(BASE_URL + '/ml/config/deactivate/' + model_id, { headers: authHeader() });
    }
    static MLRemoveModel(model_id) {
        return axios.get(BASE_URL + '/ml/config/remove/' + model_id, { headers: authHeader() });
    }
    static getMLAllConfigs() {
        return axios.get(BASE_URL + '/ml/config/all', { headers: authHeader() });
    }
    static getMLLearnedConfigs() {
        return axios.get(BASE_URL + '/ml/config/learned', { headers: authHeader() });
    }
    static getMLActiveConfigs() {
        return axios.get(BASE_URL + '/ml/config/active', { headers: authHeader() });
    }
    static getMLLearningConfigs() {
        return axios.get(BASE_URL + '/ml/config/learning', { headers: authHeader() });
    }
    static getMLErroneousConfigs() {
        return axios.get(BASE_URL + '/ml/config/erroneous', { headers: authHeader() });
    }
    static getMLModelStatus(model_id) {
        return axios.get(BASE_URL + '/ml/config/status/' + model_id, { headers: authHeader() });
    }
    static getMLModelsForNodemodule(nodeHWID, { moduleid, version, instanceid }) {
        return axios.get(BASE_URL + '/ml/nodemodulemodel/' + nodeHWID + '/' + moduleid + '/' + version + '/' + instanceid, { headers: authHeader() });
    }
    static getMLModelsForNodemoduleName(modulename) {
        return axios.get(BASE_URL + '/ml/nodemodulemodel/' + modulename, { headers: authHeader() });
    }
    static getDistinctMLFilters(hwid, moduleid, moduleversion, instanceid){
        return axios.get(BASE_URL_NM + "/ml/" + hwid + "/" + moduleid + "/" + moduleversion + "/" + instanceid, { headers: authHeader() })
    }

    // *************** object service
    static getObjectServiceDestinations(filter = '') {
        return axios.get(BASE_URL_NM + '/objectservice/destination' + filter, { headers: authHeader() });
    }
    static getObjectServiceDestination(uuid) {
        return axios.get(BASE_URL_NM + '/objectservice/destination/' + uuid, { headers: authHeader() });
    }
    static addObjectServiceDestination(data) {
        return axios.post(BASE_URL_NM + '/objectservice/destination', data, { headers: authHeader() });
    }
    static deleteObjectServiceDestination(uuid) {
        return axios.delete(BASE_URL_NM + '/objectservice/destination/' + uuid, { headers: authHeader() });
    }
    static postObjectServiceDestinationsList(data) {
        return axios.post(BASE_URL_NM + '/objectservice/destination/import', data, { headers: authHeader() });
    }
    static getObjectServiceObjectTable(filter) {
        return axios.get(BASE_URL_NM + '/objectservice/objecttable' + filter, { headers: authHeader() });
    }
    static postObjectServiceObjectEntry(data) {
        return axios.post(BASE_URL_NM + '/objectservice/objecttable', data, { headers: authHeader() });
    }
    static deleteObjectServiceObjectEntry(uuid) {
        return axios.delete(BASE_URL_NM + '/objectservice/objecttable/' + uuid, { headers: authHeader() });
    }

    // *************** license managment
    static getLicenseByUuid ( uuid ) {
        return axios.get ( BASE_URL_NM + "/inventory/license/" + uuid, { headers: authHeader() } )
    }
    static getLicenseById ( id ) {
        return axios.get ( BASE_URL_NM + "/inventory/license/node/" + id, { headers: authHeader() } )
    }
    static getAllLicenses (  ) {
        return axios.get ( BASE_URL_NM + "/inventory/license/all", { headers: authHeader() } )
    }
    static deleteLicense ( uuid ) {
        return axios.delete ( BASE_URL_NM + "/inventory/license/" + uuid, { headers: authHeader() } )
    }
    static postLicense ( data ) {
        return axios.post ( BASE_URL_NM + "/inventory/license/add", data, { headers: authHeader() } )
    }
    static setLicenseInactive ( uuid ) {
        return axios.post ( BASE_URL_NM + "/inventory/license/deactivate/" + uuid, {}, { headers: authHeader() } )
    }
    static setLicenseActive ( uuid ) {
        return axios.post ( BASE_URL_NM + "/inventory/license/activate/" + uuid, {}, { headers: authHeader() } )
    }
    

    // TODO obsolete - remove
    static assignLicenseTypeToNode ( type, id ) {
        return axios.post ( BASE_URL_NM + "/inventory/license/assign/" + type + "/" + id, {}, { headers: authHeader() } )
    }
    static assignLicenseToNode ( uuid, id ) {
        return axios.post ( BASE_URL_NM + "/inventory/license/assign/uuid/" + uuid + "/" + id, {}, { headers: authHeader() } )
    }
    // TODO obsolete - remove
    static upgradeLicenseByType (type, id ) {
        return axios.post ( BASE_URL_NM + "/inventory/license/upgrade/" + type + "/" + id, {}, { headers: authHeader() } )
    }
    static upgradeLicense (uuid, id ) {
        return axios.post ( BASE_URL_NM + "/inventory/license/upgrade/uuid/" + uuid + "/" + id, {}, { headers: authHeader() } )
    }
    static assignLicenseToNodeList( data ) {
        return axios.post ( BASE_URL_NM + "/inventory/license/assignlist", data, { headers: authHeader() } )
    }
    static removeLicenseFromNode( id ) {
        return axios.post ( BASE_URL_NM + "/inventory/license/remove/" + id, {}, { headers: authHeader() } )
    }
    static getTotalLicenses ( ) {
        return axios.get ( BASE_URL_NM + "/inventory/license/total", { headers: authHeader() } );
    }
    static getValidLicenses ( ) {
        return axios.get ( BASE_URL_NM + "/inventory/license/valid", { headers: authHeader() } );
    }
    static getAvailableLicenses ( ) {
        return axios.get ( BASE_URL_NM + "/inventory/license/available", { headers: authHeader() } );
    }
    // TODO obsolete - remove
    static getAvailableUpgradeLicensesByType ( type ) {
        return axios.get ( BASE_URL_NM + "/inventory/license/available/upgrade/" + type, { headers: authHeader() } );
    }
    static getMaxDestinations ( ) {
        return axios.get ( BASE_URL_NM + "/inventory/license/maxdestinations", { headers: authHeader() } );
    }

    // NOTIFICATIONS
    static getNotificationsChannels ( ) {
        return axios.get ( BASE_URL_NM + "/inventory/notification/channel", { headers: authHeader() } );
    }
    static getNotificationsChannelTemplates ( ) {
        return axios.get ( BASE_URL_NM + "/inventory/notification/channel/templates", { headers: authHeader() } );
    }
    static getNotificationsChannelById (id) {
        return axios.get ( BASE_URL_NM + "/inventory/notification/channel/" + id, { headers: authHeader() } );
    }
    static postNotificationsChannel (data) {
        return axios.post ( BASE_URL_NM + "/inventory/notification/channel", data, { headers: authHeader() } );
    }
    static deleteNotificationsChannelById (id) {
        return axios.delete ( BASE_URL_NM + "/inventory/notification/channel/" + id, { headers: authHeader() } );
    }

    // EULA
    static getEulaAgreementStatus = () => {
        return axios.get ( BASE_URL_NM + "/eula", { headers: authHeader() } );
    }
    static setEulaAgreementStatus = () => {
        return axios.post ( BASE_URL_NM + "/eula", {}, { headers: authHeader() } );
    }

    // responders
    static getResponderList = (moduleid, nodeid) => {
        return axios.get ( BASE_URL_NM + "/inventory/nodemodule/responder/" + moduleid + "/" + nodeid, { headers: authHeader() } );
    }

    // event collector
    static getECTemplate = (uuid) => {
        return axios.get ( BASE_URL_NM + "/eventcollector/template/" + uuid, { headers: authHeader() } );
    }
    static getECTemplates = () => {
        return axios.get ( BASE_URL_NM + "/eventcollector/template", { headers: authHeader() } );
    }
    static postECTemplate = (data) => {
        return axios.post ( BASE_URL_NM + "/eventcollector/template", data, { headers: authHeader() } );
    }
    static putECTemplate = (data) => {
        return axios.put ( BASE_URL_NM + "/eventcollector/template", data, { headers: authHeader() } );
    }
    static deleteECTemplate = (uuid) => {
        return axios.delete ( BASE_URL_NM + "/eventcollector/template/" + uuid, { headers: authHeader() } );
    }
    static getECSourceGroup = (uuid) => {
        return axios.get ( BASE_URL_NM + "/eventcollector/ecgroup/" + uuid, { headers: authHeader() } );
    }
    static getECSourceGroups = () => {
        return axios.get ( BASE_URL_NM + "/eventcollector/ecgroup", { headers: authHeader() } );
    }
    static postECSourceGroup = (data) => {
        return axios.post ( BASE_URL_NM + "/eventcollector/ecgroup", data, { headers: authHeader() } );
    }
    static putECSourceGroup = (data) => {
        return axios.put ( BASE_URL_NM + "/eventcollector/ecgroup", data, { headers: authHeader() } );
    }
    static deleteECSourceGroup = (uuid) => {
        return axios.delete ( BASE_URL_NM + "/eventcollector/ecgroup/" + uuid, { headers: authHeader() } );
    }
    static getECSource = (uuid) => {
        return axios.get ( BASE_URL_NM + "/eventcollector/source/" + uuid, { headers: authHeader() } );
    }
    static getECSources = () => {
        return axios.get ( BASE_URL_NM + "/eventcollector/source", { headers: authHeader() } );
    }
    static postECSource = (data) => {
        return axios.post ( BASE_URL_NM + "/eventcollector/source", data, { headers: authHeader() } );
    }
    static putECSource = (data) => {
        return axios.put ( BASE_URL_NM + "/eventcollector/source", data, { headers: authHeader() } );
    }
    static deleteECSource = (uuid) => {
        return axios.delete ( BASE_URL_NM + "/eventcollector/source/" + uuid, { headers: authHeader() } );
    }
    static postECSource_Template = (data) => {
        return axios.post ( BASE_URL_NM + "/eventcollector/sourcetemplate", data, { headers: authHeader() } );
    }
    static deleteECSource_Template = (sourceuuid, templateuuid) => {
        return axios.delete ( BASE_URL_NM + "/eventcollector/sourcetemplate/" + sourceuuid + '/' + templateuuid, { headers: authHeader() } );
    }
    static postECSource_SourceGroup = (data) => {
        return axios.post ( BASE_URL_NM + "/eventcollector/sourcegroup", data, { headers: authHeader() } );
    }
    static deleteECSource_SourceGroup = (sourceuuid, groupuuid) => {
        return axios.delete ( BASE_URL_NM + "/eventcollector/sourcegroup/" + sourceuuid + '/' + groupuuid, { headers: authHeader() } );
    }
}
