import React from 'react';

import PermissionsConfig from './PermissionsConfig';
import DefaultColumnsConfig from './DefaultColumnsConfig';
import AutoProvisioningConfig from './AutoProvisioningConfig';
import ConfigActions from './ConfigActions';

export default function GlobalConfiguration() {
    return (
        <>
            <ConfigActions />
            <PermissionsConfig />
            <AutoProvisioningConfig />
            <DefaultColumnsConfig />
        </>
    );
}