import { useFieldArray, useForm } from "react-hook-form";
import { useFormFields } from "../../../../hooks/useFormFields";
import { Divider, Form, Header, Icon } from "semantic-ui-react";
import NMFieldValidator from "../../../../utils/NMFieldValidator";
import { useEffect } from "react";
import NMService from "../../../../services/nm.service";
import { addGlobalMessageAtom } from "../../../../store/globalMessage";
import { useSetAtom } from "jotai";

const EventCollectorTemplateForm = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);

    const {
        register,
        setValue,
        watch,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();
    const { renderInput } = useFormFields({ register, errors, setValue, watch });

    const {
        fields: regexlist,
        remove: removeRegex,
        append: appendRegex,
    } = useFieldArray({ control, name: 'regexlist' });

    const onSubmit = (values) => {
        values = {
            ...values,
            regexlist: values.regexlist.map(e => e.var).map(e => e.trim()).filter(e => e)
        }
        if (typeof values.description === 'string' && values.description.trim() === '')
            delete values.description

        values.uuid !== undefined ?
        NMService.putECTemplate(values).then((r) =>
            addGlobalMessage({
                header: "Template updated successfully",
                content: "Template updated successfully",
                type: "positive",
            })
        ).catch(e => null)
        .finally(() => props.close()) :
        NMService.postECTemplate(values).then((r) =>
            addGlobalMessage({
                header: "Template added successfully",
                content: "Template added successfully",
                type: "positive",
            })
        ).catch(e => null)
        .finally(() => props.close())
    }

    useEffect(() => {
        props.data?.uuid
        ? reset({
            ...props.data,
            regexlist: props.data?.regexlist.map(e => ({ var: e }))
        })
        : reset({ name: "", description: "", regexlist: [] })
    }, [props.data, reset])

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Header as="h4" style={{ margin: "1rem 0.5rem" }}>
                {props.data?.uuid ? "Edit template" : "Add new template"}
            </Header>
            <Divider style={{ marginLeft: "0.5rem", marginTop: "0.5rem", marginBottom: "1.5rem" }} />
            <div style={{ padding: "1rem" }}>
                <Form.Group widths={3}>
                    {renderInput("Name", "name", { validate: v => NMFieldValidator.validateString(v, /^[a-zA-Z0-9._-]{1,32}$/, 1, 32, true) })}
                    {renderInput("Description", "description", { validate: v => NMFieldValidator.validateString(v, /^[a-zA-Z0-9._ -]{1,255}$/, 1, 255), notRequired: true })}
                </Form.Group>
                <Form.Group>
                    <label style={{ paddingLeft: '0.5rem', float: "left" }}>Add new RegEx</label>
                    <Icon
                        name="plus"
                        link
                        style={{ margin: '0 0 1.5rem 0.5rem', color: '#4183c4' }}
                        title="Add new RegEx"
                        onClick={() => appendRegex({ var: undefined })}
                    />
                </Form.Group>
                {regexlist.map((regexField, regexIndex) => (
                    <Form.Group key={regexField.id} style={{ margin: '1rem 0 0 3rem' }}>
                        <Icon
                            link
                            name="trash alternate"
                            style={{ color: '#4183C4', position: 'relative', marginTop: '0.5rem' }}
                            onClick={() => removeRegex(regexIndex)}
                        />
                        {renderInput('RegEx', `regexlist.${regexIndex}.var`, { hideLabel: true, width: 4 })}
                    </Form.Group>
                ))}
                <Divider hidden />
                <Form.Group>
                    <Form.Button size="small" type="submit" primary content="Save" />
                    <Form.Button
                        size="small"
                        type="button"
                        onClick={() => props.close()}
                        content="Cancel"
                    />
                </Form.Group>
            </div>
        </Form>
    );
}

export default EventCollectorTemplateForm;