

import { useQuery } from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchTemplates = ({ 
    select, 
    options 
} = {}) => {
    const query = useQuery({
        queryKey: ['getNMTemplates'],
        queryFn: () =>
            NMService.getNMTemplates()
                .then(r => r.data)
                .catch(e => []),
        select,
        ...options
    })
    return { ...query, data: query.data ?? [] }
}