import React, { useMemo } from 'react';
import { Button } from 'semantic-ui-react';
import { useAtom } from 'jotai';
import { globalSnmpHostGroupActions, globalSnmpHostGroupAtom } from '../../store/globalSnmpHostGroups';
import { useGetGlobalPermission } from '../../hooks/useGetGlobalPermission';
import { ADMIN } from '../../constants/layout';
import { useFetchSnmpSecurityProfile } from '../../hooks/useFetchSnmpSecurityProfile';
import { PermissionsGateV } from '../../layouts/PermissionGate/PermissionGateV';
import CustomTable from '../../components/CustomTable';

function HostGroupTable(props) {
    const hasPermision = useGetGlobalPermission(ADMIN);
    const [state, dispatch] = useAtom(globalSnmpHostGroupAtom);
    const { AddHost, EditHost, DeleteHost } = globalSnmpHostGroupActions;

    const {
        data: snmpSecurityProfiles,
    } = useFetchSnmpSecurityProfile({});

    const data = useMemo(() => props.data, [props.data]);

    const columns = useMemo(
        () => [
            {
                id: 'name',
                Header: 'Name',
                accessor: 'name',
                width: 3,
            },
            {
                id: 'security_profile',
                Header: 'Security profile',
                accessor: 'security_profile',
                Cell: ({ row }) => {
                    const foundProfile = snmpSecurityProfiles.find((e) => e.uuid === row.original.security_profile);
                    const name = foundProfile ? foundProfile.name : ''
                    return <p>{name}</p>
                },
                width: 3,
            },
            {
                id: 'templates',
                Header: 'Templates',
                accessor: 'templates',
            },
            {
                id: 'check_ping',
                Header: 'Check ping',
                accessor: 'check_ping',
                Cell: ({ row }) => <p>{row.original.check_ping ? 'Yes' : 'No'}</p>,
                width: 2,
            },
            {
                id: 'port',
                Header: 'Port',
                accessor: 'port',
                width: 2,
            },
        ],
        [] // eslint-disable-line
    );

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: 'actions',
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <PermissionsGateV hasPermission={hasPermision}>
                            <Button
                                circular
                                icon="edit"
                                type="button"
                                onClick={() => dispatch({ type: EditHost, value: row.original })}
                            />
                        </PermissionsGateV>
                        <PermissionsGateV hasPermission={hasPermision}>
                            <Button
                                circular
                                icon="trash"
                                type="button"
                                onClick={() => dispatch({ type: DeleteHost, value: row.original })}
                            />
                        </PermissionsGateV>
                    </div>,
                disableGlobalFilter: true,
                disableFilters: true,
                width: 2,
            },
        ]);
    };

    return (
        <CustomTable
            data={data}
            columns={columns}
            tableHooks={tableHooks}
            active={state?.selectedHost?.uuid}
            footerButton={() => 
                <Button
                    primary
                    size='tiny'
                    floated='right'
                    type="button"
                    content="Add new host"
                    onClick={() => dispatch({ type: AddHost })}
                />
            }
        />
    );
}

export default HostGroupTable;
