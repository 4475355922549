import { Container, Message } from 'semantic-ui-react';

const NotFound = () => {
    return (
        <Container
            fluid
            textAlign='center'
            style={{ fontSize: 150 }}
        >
            <Message warning>
                <Message.Header>
                    404
                </Message.Header>
                <Message.Content style={{ fontSize: 100 }}>
                    page not found
                </Message.Content>
            </Message>
        </Container>
    );

}

export default NotFound;
