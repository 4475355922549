import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal, Button, Icon } from 'semantic-ui-react'

const ConfirmationModal = ({ open, header, content, onConfirm, onDismiss }) => {
    const [icon, setIcon] = useState('checkmark')

    useEffect(() => {
        setIcon('checkmark')
    }, [open])

    if (!open) {
        return null;
    }

    return createPortal(
        <Modal basic size="small" open>
            <Modal.Header>
                <Icon name="archive" />
                {header}
            </Modal.Header>
            <Modal.Content>
                <div>{content}</div>
            </Modal.Content>
            <Modal.Actions>
                <Button basic color="red" inverted onClick={() => onDismiss()}>
                    <Icon name="remove" /> Dismiss
                </Button>
                <Button color="green" inverted onClick={() => {setIcon('circle notched');onConfirm()}}>
                    <Icon name={icon} loading={icon !== 'checkmark'} style={{ lineHeight: 0.85 }}/> Confirm
                </Button>
            </Modal.Actions>
        </Modal>,
        document.getElementById('backdrop-root')
    )
}

export default ConfirmationModal;