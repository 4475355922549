import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAtom, useSetAtom } from "jotai";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Header } from "semantic-ui-react";
import { ADMIN } from "../../constants/layout";
import { useGetGlobalPermission } from "../../hooks/useGetGlobalPermission";
import { globalSnmpTemplateActions, globalSnmpTemplateAtom } from "../../store/globalSnmpTemplate";
import { PermissionsGateV } from "../../layouts/PermissionGate/PermissionGateV";
import { useFetchSnmpTemplate } from "../../hooks/useFetchSnmpTemplate";
import NMService from "../../services/nm.service";
import { addGlobalMessageAtom } from "../../store/globalMessage";
import { useFormFields } from "../../hooks/useFormFields";


const SnmpFormSchema = yup.object().shape({
    template_name: yup.string()
        .required("Field is required."),
});
const defaultValues = { template_name: "", oid_list: "", include_templates: []}
const TemplateForm = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const hasPermission = useGetGlobalPermission(ADMIN);
    const [state, dispatch] = useAtom(globalSnmpTemplateAtom);
    const { AddTemplate, ClearShowType } = globalSnmpTemplateActions;

    const {
        register,
        setValue,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(SnmpFormSchema),
        defaultValues: defaultValues
    });;

    const { renderInput, renderDropdown } = useFormFields({ register, errors, setValue, watch });

    const {
        data: snmpTemplates = [],
        refetch: refetchSnmpTemplates
    } = useFetchSnmpTemplate({
        options: {
            refetchOnMount: 'always'
        },
        select: e => e.map(k => ({
                                    key: k.template_name,
                                    value: k.template_name,
                                    text: k.template_name
                                }))
    });

    const onSubmit = async (values) => {
        values = ({...values, oid_list: values.oid_list.split(",").map(e => e.trim()).filter(e => e)})

        state.showType === AddTemplate ?
            await NMService.addSnmpTemplate(values).then(r => 
                addGlobalMessage({
                    header: 'SNMP template successfully added',
                    content: 'SNMP template successfully added',
                    type: 'positive',
                })
            ).catch(e => null)
        :
            await NMService.updateSnmpTemplate(values).then(r => 
                addGlobalMessage({
                    header: 'SNMP template successfully updated',
                    content: 'SNMP template successfully updated',
                    type: 'positive',
                })
            ).catch(e => null)
        refetchSnmpTemplates()
        dispatch({ type: ClearShowType })
    };

    useEffect(() => {
        if (state.showType !== AddTemplate) {
            reset(({...state.selectedTemplate, oid_list: (state.selectedTemplate.oid_list || []).join(", ")}));
        } else {
            reset(defaultValues)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.showType, state.selectedTemplate]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Header as="h4" dividing>
                {(state.showType === AddTemplate ? 'Add new' : 'Edit') + ' template'}
            </Header>
            <Form.Group widths={4}>
                {renderInput("Name", "template_name")}
            </Form.Group>
            <Form.Group widths={2}>
                {renderInput("OID list", "oid_list", { notRequired: true, infoText: "Comma separated OIDs" })}
                {renderDropdown("Include templates", "include_templates", snmpTemplates, { notRequired: true, multiple: true})}
            </Form.Group>
            <Form.Group>
                <PermissionsGateV hasPermission={hasPermission}>
                    <Form.Button
                        type="submit"
                        size="small"
                        primary
                        content={state.showType === AddTemplate ? "Add" : "Update"}
                    />
                </PermissionsGateV>
                <Form.Button
                    type="button"
                    size="small"
                    content="Cancel"
                    onClick={() => dispatch({ type: ClearShowType })}
                />
            </Form.Group>
        </Form>
    )
}

export default TemplateForm;