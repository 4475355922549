import styled from 'styled-components';

const LicenseNumDiv = styled.div`
    background-color: inherit;
    color: #2185d0;
    font-size: 1rem;
    line-height: 1.4285em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`

const LicenseNum = ({ children }) => {
    return (
        <LicenseNumDiv>{children}</LicenseNumDiv>
    )
}

export default LicenseNum;