import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Icon, Button } from 'semantic-ui-react';
import NMService from '../../../services/nm.service';
import ActivePanelForm from './ActivePanelForm';
import { uuidv4 } from '../../../utils/uuidv4';
import { useSetAtom } from 'jotai';
import { addGlobalMessageAtom } from '../../../store/globalMessage';

function DashboardRow(props) {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [data, setData] = useState({});
    const [expanded, setExpand] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [panels, setPanels] = useState([]);

    const fetchNodeModuleActivePanels = useCallback(() => {
        NMService.getNodeModuleActivePanels(props.data.uid).then((response) => {
            setPanels(response.data)
        }).catch(e => null);
    }, [setPanels, props.data.uid]);

    const deleteNodeModuleActivePanel = (dashboardUid, panelId) => {
        NMService.deleteNodeModuleActivePanel(dashboardUid, panelId).then(() => {
            fetchNodeModuleActivePanels();
            addGlobalMessage({
                header: 'Active panel deleted!',
                content: `Active panel successfully deleted.`,
                type: 'positive',
            });
        }).catch(e => null)
    }

    const refreshPanel = () =>
        NMService.refreshGrafanaDashboard(data.uid).then(() =>
            addGlobalMessage({
                header: 'Dashboard refreshed!',
                content: 'Dashboard successfully refreshed.',
                type: 'positive',
            })).catch(() => null)

    useEffect(() => {
        if (props.data) {
            const { uid } = props.data;
            setData(props.data);
            fetchNodeModuleActivePanels(uid);
        }
    }, [props.data, fetchNodeModuleActivePanels]);

    return (
        <React.Fragment>
            {Object.keys(data).length > 0 && (
                <Grid.Row verticalAlign="middle" style={{ padding: '0' }} key={uuidv4()}>
                    <Grid.Column width={16} style={{ margin: '0 0' }}>
                        <Grid>
                            <Grid.Row verticalAlign="middle">
                                <Grid.Column
                                    width={3}
                                    textAlign="left"
                                    style={{ margin: '0.5rem 0' }}
                                >
                                    {data.title}
                                </Grid.Column>
                                <Grid.Column
                                    width={4}
                                    textAlign="left"
                                    style={{ margin: '0.5rem 0' }}
                                >
                                    {data.description}
                                </Grid.Column>
                                <Grid.Column
                                    width={3}
                                    textAlign="left"
                                    style={{ margin: '0.5rem 0' }}
                                >
                                    {data.templateId}
                                </Grid.Column>
                                <Grid.Column
                                    width={4}
                                    textAlign="left"
                                    style={{ margin: '0.5rem 0' }}
                                >
                                    {data.hasOwnProperty('url') && data.url && (
                                        <Link className="item" to={data.url} target="_blank">
                                            link to grafana
                                        </Link>
                                    )}
                                </Grid.Column>
                                <Grid.Column
                                    width={2}
                                    textAlign="left"
                                    style={{ margin: '0.5rem 0' }}
                                >
                                    <Button
                                        circular
                                        icon="refresh"
                                        title="Regenerate dashboard"
                                        onClick={refreshPanel}
                                    />
                                    <Button
                                        circular
                                        icon="trash"
                                        title="Delete dashboard"
                                        onClick={() => props.deleteDashboard(data.uid)}
                                    />
                                    <Icon
                                        name={`caret ${expanded ? 'up' : 'down'}`}
                                        size="large"
                                        link
                                        style={{ float: "right", marginRight: "0" }}
                                        onClick={() => setExpand(p => !p)}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {expanded && (
                                <Grid.Row style={{ marginBottom: '2rem' }} verticalAlign="middle">
                                    <Grid.Column width={10}>
                                        <Grid celled style={{ margin: '0 0' }}>
                                            <Grid.Row>
                                                <Grid.Column
                                                    style={{
                                                        fontWeight: 'bold',
                                                        padding: '0.5rem 1rem',
                                                    }}
                                                >
                                                    Active panels table
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column
                                                    width={5}
                                                    style={{
                                                        fontWeight: 'bold',
                                                        padding: '0.5rem 1rem',
                                                    }}
                                                >
                                                    Panel ID
                                                </Grid.Column>
                                                <Grid.Column
                                                    width={5}
                                                    style={{
                                                        fontWeight: 'bold',
                                                        padding: '0.5rem 1rem',
                                                    }}
                                                >
                                                    Template ID
                                                </Grid.Column>
                                                <Grid.Column
                                                    width={3}
                                                    style={{
                                                        fontWeight: 'bold',
                                                        padding: '0.5rem 1rem',
                                                    }}
                                                >
                                                    Width
                                                </Grid.Column>
                                                <Grid.Column
                                                    width={3}
                                                    style={{
                                                        fontWeight: 'bold',
                                                        padding: '0.5rem 1rem',
                                                    }}
                                                ></Grid.Column>
                                            </Grid.Row>
                                            {panels.map((panel) => (
                                                <Grid.Row
                                                    verticalAlign="middle"
                                                    key={panel.dashboardUid}
                                                >
                                                    <Grid.Column
                                                        width={5}
                                                        style={{ padding: '0.5rem 0.5rem' }}
                                                    >
                                                        {panel.panelId}
                                                    </Grid.Column>
                                                    <Grid.Column
                                                        width={5}
                                                        style={{ padding: '0.5rem 0.5rem' }}
                                                    >
                                                        {panel.templateId}
                                                    </Grid.Column>
                                                    <Grid.Column
                                                        width={3}
                                                        style={{ padding: '0.5rem 0.5rem' }}
                                                    >
                                                        {panel.width}
                                                    </Grid.Column>
                                                    <Grid.Column
                                                        width={3}
                                                        style={{ padding: '0.5rem 0.5rem' }}
                                                    >
                                                        <Button
                                                            circular
                                                            icon="trash"
                                                            onClick={() =>
                                                                deleteNodeModuleActivePanel(
                                                                    panel.dashboardUid,
                                                                    panel.panelId
                                                                )
                                                            }
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            ))}
                                            <Grid.Row>
                                                <Grid.Column
                                                    textAlign="right"
                                                    style={{ padding: '0.5rem 1rem' }}
                                                >
                                                    <Button
                                                        floated="right"
                                                        primary
                                                        size="small"
                                                        onClick={() => setShowForm(true)}
                                                        content='Add new panel'
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                            {expanded && showForm && (
                                <ActivePanelForm
                                    moduleId={props.moduleid}
                                    data={props.data}
                                    dismissForm={() => {
                                        setShowForm(false);
                                    }}
                                    updateActivePanelsTable={fetchNodeModuleActivePanels}
                                />
                            )}
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            )}
        </React.Fragment>
    );
}

export default DashboardRow;