const IPV4_REGEX = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
const IPV4_RANGE_REGEX =
    /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.)){3}(25[0-5]|(2[0-4]|1\d|[1-9]|)\d){1}-((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/;
const IPV4_PREFIX_REGEX =
    /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.)){3}(25[0-5]|(2[0-4]|1\d|[1-9]|)\d){1}[/](?:[0-9]|1[0-9]|2[0-9]|3[0-2])$/;
const NAMESPACE_REGEX = /^[a-zA-Z]+[a-zA-Z0-9-]*$/;

const INTF_NAME_REGEX = /^[a-z]+[a-z0-9-]{1,32}[a-z0-9]{1}(\.[0-9]{1,5}){0,1}$/;
const INTF_ALIAS_REGEX = /^[a-zA-z0-9]{4,40}$/;
const INTF_MAC_REGEX = /^[a-f0-9]{2}(:[a-f0-9]{2}){5}$/;
const INTF_MACPREFIX_REGEX = /^[a-f0-9]{2}(:[a-f0-9]{2}){0,5}$/;
const INTF_MACMASK_REGEX = /^[a-f0-9RX]{2}(:[a-f0-9RX]{2}){5}$/;
const INTF_DHCPCID_REGEX = /^(\s|\S){0,255}$/;
const INTF_DHCPVCI_REGEX = /^(\s|\S){0,255}$/;

const ROUTE_DEV_REGEX = /^[a-z]+[a-z0-9.]{1,32}$/;

export class InterfaceValidation {
    static validateName(value) {
        if (!value || !INTF_NAME_REGEX.test(value)) {
            return 'Empty or invalid name';
        }
    }

    static validateAlias(value) {
        if (!value || !INTF_ALIAS_REGEX.test(value)) {
            return 'Empty or invalid alias';
        }
    }

    static validateType(value) {
        if (!value) {
            return 'Empty type';
        }
    }

    static validateParent(value) {
        if (!value) {
            return 'Empty parent interface';
        }
    }

    static validateNamespace(value) {
        if (value) {
            if (!NAMESPACE_REGEX.test(value)) {
                return 'Invalid namespace';
            }
        }
    }

    static validateIPAddr(value) {
        if (!value || !IPV4_PREFIX_REGEX.test(value)) {
            return 'Empty or invalid IP address';
        }
    }

    static validatePeer(value) {
        if (!value || !IPV4_REGEX.test(value)) {
            return 'Empty or invalid peer IP address';
        }
    }

    static validateIPwPeer(value) {
        let d = value.split(":")
        try {
            if (parseInt(d[1]) < 0 || parseInt(d[1]) > 65535){
                return "Invalid peer IPV4 address"
            }
        } catch {
            return "Invalid peer IPV4 address"
        }

        if (!value || !IPV4_REGEX.test(d[0])) {
            return 'Empty or invalid peer IP address';
        }
    }

    static validateIPAddrRange(value) {
        if (!value || !IPV4_RANGE_REGEX.test(value)) {
            return 'Empty or invalid IP address range';
        }
    }

    static validateGateway(value) {
        if (value) {
            if (!IPV4_REGEX.test(value)) {
                return 'Invalid gateway address';
            }
        }
    }

    static validateMac(value) {
        if (value) {
            if (!INTF_MAC_REGEX.test(value)) {
                return 'Invalid MAC address';
            }
        }
    }

    static validateMacPrefix(value) {
        if (value) {
            if (!INTF_MACPREFIX_REGEX.test(value)) {
                return 'Invalid MAC prefix';
            }
        }
    }

    static validateMacMask(value) {
        if (value) {
            if (!INTF_MACMASK_REGEX.test(value)) {
                return 'Invalid MAC mask';
            }
        }
    }

    static validateIntDhcpVCI(value) {
        if (value) {
            if (!INTF_DHCPVCI_REGEX.test(value)) {
                return 'Invalid DHCP vendor class identifier';
            }
        }
    }

    static validateIntDhcpCI(value) {
        if (value) {
            if (!INTF_DHCPCID_REGEX.test(value)) {
                return 'Invalid DHCP client ID';
            }
        }
    }
}

export class NamespaceValidation {
    static validateName(value) {
        if (!value || !NAMESPACE_REGEX.test(value)) {
            return 'Empty or invalid name';
        }
    }

    static validateDns(value) {
        if (!value || !IPV4_REGEX.test(value)) {
            return 'Empty or invalid DNS address';
        }
    }
}

export class RouteValidation {
    static validateDestination(value) {
        if (!value || !IPV4_PREFIX_REGEX.test(value)) {
            return 'Empty or invalid destination';
        }
    }

    static validateVia(value, isDev) {
        if (!value && !isDev) {
            return 'Via or dev value must be set';
        }

        if (!isDev) {
            if (value && !IPV4_REGEX.test(value)) {
                return 'Invalid via value';
            }
        }
    }

    static validateDev(value, isVia) {
        if (!value && !isVia) {
            return 'Via or dev value must be set';
        }

        if (!isVia) {
            if (value && !ROUTE_DEV_REGEX.test(value)) {
                return 'Invalid dev value';
            }
        }
    }
}
