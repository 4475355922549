import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Message, Header } from 'semantic-ui-react';
import { TimestampThresholds } from '../../config';
import { useUserInfo } from '../../hooks/useAuth';
import { ADMIN, READ_ONLY } from '../../constants/layout';
import { useFetchAlarms } from '../../hooks/useFetchAlarms';
import { useFetchRegistry } from '../../hooks/useFetchRegistry';

const Overview = () => {
    const { userdata, multitenant } = useUserInfo();

    const {
        data: registryData,
        // isLoading: registryDataIsLoading,
        isFetching: registryDataIsFetching
    } = useFetchRegistry({
        select: (data) => {
            let activeProbes = [];
            let unreachableProbes = [];
            let unmanagedProbes = [];

            data.forEach((probe) => {
                if (!probe.inuse) {
                    unmanagedProbes.push(probe);
                } else {
                    const currentDate = new Date();
                    let timestamp = new Date(Date.parse(probe.ts));
                    for (let i = 0; i < TimestampThresholds.length; i++) {
                        if (TimestampThresholds[i].seconds < currentDate - timestamp) {
                            unreachableProbes.push(
                                {
                                    ...probe,
                                    statusType: TimestampThresholds[i].sclass === 'tsthreshold1' ? 'warning' : 'info'
                                })
                            break;
                        } else {
                            activeProbes.push(probe);
                            break;
                        }
                    }
                }
            });

            return {
                activeProbes: activeProbes || [],
                unmanagedProbes: unmanagedProbes || [],
                unreachableProbes: unreachableProbes || []
            }
        },
        options: {
            refetchOnMount: 'always',
            refetchInterval: 30000,
            refetchIntervalInBackground: true,
            retry: false
        }
    })

    const {
        data: alarmsData,
        // isLoading: alarmsDataIsLoading,
        isFetching: alarmsDataIsFetching,
    } = useFetchAlarms({
        options: {
            refetchOnMount: 'always',
            refetchInterval: 30000,
            refetchIntervalInBackground: true,
            retry: false
        }
    })

    const getIconByStatus = (status) => {
        let iconText = '';
        switch (status) {
            case 'negative':
                iconText = 'close'
                break;
            case 'warning':
                iconText = 'warning'
                break;
            case 'info':
                iconText = 'info'
                break;
            case 'positive':
                iconText = 'checkmark'
                break;
            default:
                return 'circle notched'
        }
        return iconText;
    }

    const getMessageTypeByStatus = (status) => {
        switch (status) {
            case 'negative':
                return { negative: true }
            case 'warning':
                return { warning: true }
            case 'info':
                return { info: true }
            case 'positive':
                return { positive: true }
            default:
                return
        }
    }

    const alarmStatus = useMemo(() => alarmsData.length ? 'warning' : 'positive', [alarmsData]);
    const probeStatus = useMemo(() => {
        const status = {
            activeProbes: registryData?.activeProbes?.length ? 'positive' : 'warning',
            unmanagedProbes: registryData?.unmanagedProbes?.length ? 'info' : 'positive',
            unreachableProbes: registryData?.unreachableProbes?.length ? 'warning' : 'positive'
        }
        if (registryData?.unreachableProbes?.some(e => e.statusType === 'warning')) {
            status.unreachableProbes = 'warning'
        }
        return status
    }, [registryData]);

    const restricted = (
        multitenant &&
        userdata.tenantid &&
        (
            userdata.role === READ_ONLY ||
            (userdata.role === ADMIN && !userdata.probeadd))
    )

    // if (registryDataIsLoading || alarmsDataIsLoading) {
    //     return null;
    // }

    return (
        <div style={{ marginTop: '2rem' }}>
            <Header as="h2" dividing textAlign="center">
                Overview
            </Header>
            <Message
                icon
                {...getMessageTypeByStatus(alarmStatus)}
            >
                <Icon
                    name={alarmsDataIsFetching ? 'circle notched' : getIconByStatus(alarmStatus)}
                    loading={alarmsDataIsFetching}
                    style={{ width: '4%' }} />
                <Message.Content>
                    <Message.Header style={{ paddingBottom: '0.5rem' }}>Alarms</Message.Header>
                    Total alarms: {alarmsData?.length} <br />
                    <Link to={"/alarms/all-alarms"}>See more</Link>
                </Message.Content>
            </Message>
            <Message
                icon
                {...getMessageTypeByStatus(probeStatus.activeProbes)}
            >
                <Icon
                    name={registryDataIsFetching ? 'circle notched' : getIconByStatus(probeStatus?.activeProbes)}
                    loading={registryDataIsFetching}
                    style={{ width: '4%' }} />
                <Message.Content>
                    <Message.Header style={{ paddingBottom: '0.5rem' }}>
                        Active nodes
                    </Message.Header>
                    Total active nodes: {registryData?.activeProbes?.length} <br />
                    {registryData?.activeProbes?.length > 0 &&
                        <Link to={"/nodes"} state={{ defaultTab: 0 }}>
                            See more
                        </Link>
                    }
                </Message.Content>
            </Message>
            {registryData?.unreachableProbes?.length > 0 && <Message
                icon
                {...getMessageTypeByStatus(probeStatus.unreachableProbes)}
            >
                <Icon
                    name={registryDataIsFetching ? 'circle notched' : getIconByStatus(probeStatus?.unreachableProbes)}
                    loading={registryDataIsFetching}
                    style={{ width: '4%' }} />
                <Message.Content>
                    <Message.Header style={{ paddingBottom: '0.5rem' }}>
                        Unreachable nodes
                    </Message.Header>
                    Total unreachable nodes: {registryData?.unreachableProbes?.length} <br />
                    {!restricted && <Link to={
                        {
                            pathname: "/nodes",
                            params: {
                                defaultTab: registryData?.unreachableProbes?.some(e => e.inuse) ? 0 : 1
                            }
                        }}
                    >
                        See more
                    </Link>}
                </Message.Content>
            </Message>}
            <Message
                icon
                {...getMessageTypeByStatus(probeStatus.unmanagedProbes)}
            >
                <Icon
                    name={registryDataIsFetching ? 'circle notched' : getIconByStatus(probeStatus?.unmanagedProbes)}
                    loading={registryDataIsFetching}
                    style={{ width: '4%' }} />
                <Message.Content>
                    <Message.Header style={{ paddingBottom: '0.5rem' }}>
                        Unmanaged nodes
                    </Message.Header>
                    Total unmanaged nodes: {registryData?.unmanagedProbes?.length} <br />
                    {!restricted && <Link to={{ pathname: "/nodes"}} state={{ defaultTab: 2 }}>
                        See more
                    </Link>}
                </Message.Content>
            </Message>
        </div>
    );

}

export default Overview;
