import React, { useEffect, useMemo } from 'react';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { Form, Header, Icon } from 'semantic-ui-react';
import NMService from '../../../../../services/nm.service';
import { InterfaceValidation } from '../../networkValidation';
import PeerWrapper from '../../PeerWrapper';
import { addGlobalMessageAtom } from '../../../../../store/globalMessage';
import { useSetAtom } from 'jotai';
import { useFormFields } from '../../../../../hooks/useFormFields';

const EditNamespaceInterfaceForm = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const namespace = useMemo(() => props.namespace, [props.namespace]);
    const methods = useForm();
    const { handleSubmit, register, setValue, watch, control, reset, formState: { errors } } = methods;
    const { renderInput, renderCheckbox } = useFormFields({ register, errors, setValue, watch });

    const { fields: addressFields, remove: removeAddress, append: appendAddress } = useFieldArray({ control, name: 'address' });
    const { fields: addressRangeFields, remove: removeAddressRange, append: appendAddressRange } = useFieldArray({ control, name: 'addressrange' });
    const { fields: dnsFields, remove: removeDns, append: appendDns } = useFieldArray({ control, name: 'dns' });

    const addressWatcher = watch('address');
    const addressRangeWatcher = watch('addressrange');
    const dhcpWatcher = watch('dhcp');

    function* yieldPeerArr(peerArr) {
        for (const i in peerArr) {
            yield peerArr[i] || undefined;
        }
    }

    const onSubmit = (values) => {
        const data = {
            ...values,
            ...(namespace && { namespace }),
            address: values.address.map((e) => e.var).filter((e) => e),
            peer: values.address.map((e) => e.peer).filter((e) => e),
            addressrange: values.addressrange.map((e) => e.var).filter((e) => e),
            dns: values.dns.map((e) => e.var).filter((e) => e),
        };

        if (watch('mac')) {
            delete data.macprefix;
            delete data.macmask;
        }
        if (watch('macprefix')) {
            delete data.macmask;
            delete data.mac;
        }
        if (watch('macmask')) {
            delete data.macprefix;
            delete data.mac;
        }
        if (!(watch('mac') || watch('macprefix') || watch('macmask'))) {
            delete data.macmask;
            delete data.macprefix;
            delete data.mac;
        }

        NMService.postInterfaceConfig(props.nodeId, data).then((r) =>
                addGlobalMessage({
                    header: `Updated interface configuration`,
                    content: `Interface successfully updated`,
                    type: 'positive'
                })
        ).then(() => {
            props.toggleForm();
            props.updateNamespacesConfig();
        }).catch(e => null)
    };

    useEffect(() => {
        if (props.data) {
            const peerArrayGen = yieldPeerArr(props.data.peer || []);
            const initValues = {
                ...props.data,
                address: props.data?.address?.map((e) => {
                    const prefix = e.split('/')[1];
                    let addressObj = { var: e };
                    if (prefix === '31' || prefix === '32') {
                        const peer = peerArrayGen.next().value
                        if (peer) {
                            addressObj['peer'] = peer
                        }
                    }
                    return addressObj;
                }),
                addressrange: props.data?.addressrange?.map((e) => ({ var: e })),
                dns: props.data?.dns?.map((e) => ({ var: e })),
            };
            reset(initValues);
        }
        return () => { reset({}) };
    }, [props.data]); //eslint-disable-line

    return (
        <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)} className='basic segment' style={{ padding: '1rem 1rem' }}>
                <Header dividing as='h4' style={{ margin: '0.5rem 0 1.5rem 0' }}>Edit interface {props.data.name}</Header>
                <Form.Group widths={4}>
                    {renderInput('Name', 'name', { readOnly: true })}
                    {renderInput('Alias', 'alias', { validate: (v) => InterfaceValidation.validateAlias(v) })}
                </Form.Group>
                <Form.Group style={{ paddingBottom: '0.5rem' }}>
                    <Form.Field>
                        Address
                        <Icon
                            name='plus'
                            link
                            title='Add new address'
                            style={{ marginLeft: '1rem', paddingTop: '1rem', color: '#4183c4' }}
                            onClick={() => appendAddress({ var: undefined })}
                        />
                    </Form.Field>
                </Form.Group>
                {addressFields.map((e, i) => (
                    <Form.Group style={{ marginLeft: '3rem' }} key={i}>
                        <Icon name='trash alternate' link onClick={() => removeAddress(i)} style={{ color: '#4183c4', position: 'relative', marginTop: '0.5rem' }}/>
                        {renderInput('IP Address', `address.${i}.var`, { width: 4, hideLabel: true, validate: (v) => InterfaceValidation.validateIPAddr(v) })}
                        <PeerWrapper
                            ipAddrValue={addressWatcher?.[i]?.var}
                            name={`address.${i}.peer`}
                            index={i}
                            width={4}
                        />
                    </Form.Group>
                ))}
                <Form.Group style={{ paddingBottom: '0.5rem' }}>
                    <Form.Field>
                        Address range
                        <Icon
                            name='plus'
                            link
                            style={{ paddingLeft: '1rem', color: '#4183c4' }}
                            onClick={() => appendAddressRange({ var: undefined })}
                        />
                    </Form.Field>
                </Form.Group>
                {addressRangeFields.map((e, i) => (
                    <Form.Group style={{ marginLeft: '3rem'}} key={i}>
                        <Icon name='trash alternate' link onClick={() => removeAddressRange(i)} style={{ color: '#4183c4', position: 'relative', marginTop: '0.5rem' }}/>
                        {renderInput('Address range', `addressrange.${i}.var`, { width: 4, hideLabel: true, validate: (v) => InterfaceValidation.validateIPAddrRange(v) })}
                    </Form.Group>
                ))}
                {addressRangeWatcher && addressRangeWatcher.map((e) => e.var).filter((e) => e).length > 0 && (
                    <Form.Group style={{ paddingTop: '1rem', paddingBottom: '0.5rem' }}>
                        {renderCheckbox('Interface range', 'interfacerange', { width: 3 })}
                        {renderInput('Mask', 'mask', { width: 2, notRequired: true, inputType: 'number', min: 0, max: 32 })}
                    </Form.Group>
                )}
                <Form.Group>
                    {renderCheckbox('Connect namespace', 'connectnamespace', { width: 3 })}
                    {renderInput('Gateway', 'gateway', { width: 4, notRequired: true, validate: (v) => InterfaceValidation.validateGateway(v)})}
                </Form.Group>
                <Form.Group style={{ paddingBottom: '0.5rem' }}>
                    <Form.Field>
                        DNS
                        <Icon
                            name='plus'
                            link
                            title='Add DNS'
                            style={{ paddingLeft: '1rem', color: '#4183c4' }}
                            onClick={() => appendDns({ var: undefined })}
                        />
                    </Form.Field>
                </Form.Group>
                {dnsFields.map((e, i) => (
                    <Form.Group style={{ marginLeft: '3rem'}} key={i}>
                        <Icon name='trash alternate' link onClick={() => removeDns(i)} style={{ color: '#4183c4', position: 'relative', marginTop: '0.5rem' }}/>
                        {renderInput('DNS', `dns.${i}.var`, { width: 4, hideLabel: true })}
                    </Form.Group>
                ))}
                <Form.Group widths={4}>
                    {renderInput('MAC', 'mac', { notRequired: true, disabled: !!(watch('macprefix') || watch('macmask')),validate: (v) => InterfaceValidation.validateMac(v) })}
                    {renderInput('MAC prefix', 'macprefix', { notRequired: true, disabled: !!(watch('mac') || watch('macmask')),validate: (v) => InterfaceValidation.validateMacPrefix(v) })}
                    {renderInput('MAC mask', 'macmask', { notRequired: true, disabled: !!(watch('mac') || watch('macprefix')),validate: (v) => InterfaceValidation.validateMacMask(v) })}
                </Form.Group>
                <Form.Group style={{ paddingBottom: '0.5rem' }}>
                    {renderCheckbox('DHCP', 'dhcp',{ width: 2 })}
                    {dhcpWatcher ?
                        <>
                            {renderInput('DHCP Vendor Class Identifier', 'dhcp-vendorclassidentifier', { width: 4, notRequired: true, validate: (v) => InterfaceValidation.validateIntDhcpVCI(v) })}
                            {renderInput('DHCP Client ID', 'dhcp-clientid', { width: 4, notRequired: true, validate: (v) => InterfaceValidation.validateIntDhcpCI(v) })}
                        </> : null}
                </Form.Group>
                <Form.Group>
                    {renderCheckbox('Disabled', 'disabled', { width: 2 })}
                </Form.Group>
                <Form.Group>
                    <Form.Button type='submit' size='small' primary content='Save'/>
                    <Form.Button type='button' size='small' content='Cancel' onClick={() => props.toggleForm()}/>
                </Form.Group>
            </Form>
        </FormProvider>
    );
}

export default EditNamespaceInterfaceForm;
