import { useRef, useState, Fragment } from 'react';
import { Grid, Form, Button, Segment, Header, Menu, Container, Message } from 'semantic-ui-react';
import { useAuth } from '../../hooks/useAuth';
import axios from 'axios';
import { BASE_URL_AUTH } from '../../config';
import { useSetAtom } from 'jotai';
import { removeGlobalAllMessagesAtom } from '../../store/globalMessage';

function Login() {
    const { login } = useAuth();
    const removeGlobalAllMessages = useSetAtom(removeGlobalAllMessagesAtom);
    const [isAuthFailed, setAuthFailed] = useState(false);
    const usernameInputRef = useRef();
    const passwordInputRef = useRef();

    const submitLogin = async (event) => {
        event.preventDefault();

        const username = usernameInputRef.current.value;
        const password = passwordInputRef.current.value;

        await axios.post(BASE_URL_AUTH + '/login', { username, password }).then(response => {
            const data = response.data;
            removeGlobalAllMessages();
            setAuthFailed(false)
            login(data.rv);
        }).catch(() => {
            setAuthFailed(true)
        })
    };

    return (
        <Fragment>
            <Menu inverted color='blue'></Menu>
            <Container style={{ width: 700 }}>
                <Segment textAlign='center'>
                    <Grid>
                        {isAuthFailed && <Grid.Row>
                            <Grid.Column>
                                <Message
                                    error
                                    header='Login failed!'
                                    content='Please check your username and password.'
                                    onDismiss={() => setAuthFailed(false)}
                                />
                            </Grid.Column>
                        </Grid.Row>}
                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column width={6} textAlign='center'>
                                <img alt="company's logo" style={{ height: '14em' }} src="/logo-text.svg" />
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <Segment color="blue">
                                    <Header as='h4'>
                                        <Header.Content>
                                            Welcome to 5x9 Node Manager
                                            <Header.Subheader>Please log in to continue</Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Segment>
                                <Form className="basic segment" onSubmit={submitLogin}>
                                    <Form.Field align="left">
                                        <label>Username:</label>
                                        <input
                                            name='user'
                                            placeholder="Username"
                                            type="text"
                                            required
                                            ref={usernameInputRef}
                                        />
                                    </Form.Field>
                                    <Form.Field align="left">
                                        <label>Password:</label>
                                        <input
                                            name='password'
                                            placeholder="Password"
                                            type="password"
                                            required
                                            ref={passwordInputRef}
                                        />
                                    </Form.Field>
                                    <Form.Field align="right">
                                        <Button type="submit" primary>
                                            Submit
                                        </Button>
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Container>
        </Fragment>
    );
}

export default Login;
