import { Fragment, useMemo } from 'react';
import { Breadcrumb, Button, Divider } from 'semantic-ui-react';
import NMService from '../../services/nm.service';
import LocationsForm from './LocationsForm';
import LocationInfo from './LocationInfo';
import { useFetchLocations } from '../../hooks/useFetchLocations';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import { locationsActions, locationsAtom } from '../../store/locations';
import { useAtom, useSetAtom } from 'jotai';
import { addGlobalMessageAtom } from '../../store/globalMessage';
import OpenStreetLocationLink from './OpenStreetLocationLink';
import CustomTable from '../../components/CustomTable';

const Location = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [state, dispatch] = useAtom(locationsAtom);

    const { 
        ADD_NEW_LOCATION, 
        EDIT_LOCATION, 
        SHOW_LOCATION_INFO,
        CLEAR_TYPE,
        DELETE_LOCATION
    } = locationsActions;

    const {
        data,
        isLoading,
        refetch
    } = useFetchLocations({
        options: {
            refetchOnMount: 'always'
        }
    })

    const onDeleteLocation = () => {
        NMService.deleteLocation(state.selectedLocation?.id).then(() =>
            addGlobalMessage({
                header: 'Location parameter is deleted!',
                content: `Location parameter ${state.selectedLocation?.id} is successfully deleted.`,
                type: 'positive'
            }))
        .then(() => dispatch({ type: locationsActions.CLEAR_TYPE }))
        .catch(e => null)
        .finally(() => refetch())
    }

    const obj2TextHelper = (obj) =>
        Object.entries(obj)
            .map((elem, i) => elem[0] + ": " + elem[1])
            .join("\n");

    const columns = useMemo(() => [
        {
            id: 'id',
            Header: 'ID',
            accessor: 'id',
        },
        {   
            id: 'name',
            Header: 'Name',
            accessor: 'name',
        },
        {
            id: 'description',
            Header: 'Description',
            accessor: 'description',
        },
        {
            id: 'coordinates',
            Header: 'Coordinates',
            accessor: d => <OpenStreetLocationLink latitude={d.latitude} longitude={d.longitude} zoom="13"/>,
        },
        {
            id: 'locationparameters',
            Header: 'Location parameters',
            accessor: d => obj2TextHelper(JSON.parse(d.data.rawjsondata)),
        },
    ], []);

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: 'actions',
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            circular
                            icon="settings"
                            onClick={() => dispatch({ type: locationsActions.EDIT_LOCATION, value: row.original })}
                        />
                        <Button
                            circular
                            icon="info"
                            onClick={() => dispatch({ type: locationsActions.SHOW_LOCATION_INFO, value: row.original })}
                        />
                        <Button
                            circular
                            icon="trash"
                            onClick={() => dispatch({ type: locationsActions.DELETE_LOCATION, value: row.original })}
                        />
                    </div>,
                width: 2,
                disableGlobalFilter: true,
                disableFilters: true,
            },
        ]);
    };

    return isLoading ? null : (
        <Fragment>
            <Breadcrumb style={{ marginTop: '1rem' }}>
                <Breadcrumb.Section>Locations</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section active>Location List</Breadcrumb.Section>
            </Breadcrumb>
            <Divider clearing />
            <CustomTable
                data={data}
                columns={columns}
                tableHooks={tableHooks}
                active={state.selectedLocation?.id}
                footerButton={() =>
                    <Button
                        primary
                        size="tiny"
                        floated='right'
                        content="Add new location"
                        onClick={() => dispatch({ type: locationsActions.ADD_NEW_LOCATION })}
                    />
                }
            />
            { [ ADD_NEW_LOCATION, EDIT_LOCATION ].includes(state.showType) && (
                <LocationsForm />
            )} 
            {state.showType === SHOW_LOCATION_INFO && (
                <LocationInfo />
            )}
            <ConfirmationModal
                open={state.showType === DELETE_LOCATION}
                header="Delete location"
                content="Are you sure you want to delete location?"
                onConfirm={() => onDeleteLocation()}
                onDismiss={() => dispatch({ type: CLEAR_TYPE })}
            />
        </Fragment>
    );
}

export default Location;
