import { Fragment } from "react";
import { useOutlet, useParams } from "react-router-dom";
import { ProtectedLicenseLayout } from "./ProtectedLicenseLayout";

export const ProtectedProbeLayout = () => {
    const outlet = useOutlet();
    const { type } = useParams();

    if (
        type === "newlymanaged" ||
        type === "unmanaged"
    ) {
        return (
            <ProtectedLicenseLayout>
                {outlet}
            </ProtectedLicenseLayout>
        )
    }

    return <Fragment>{outlet}</Fragment>;
};