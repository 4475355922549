import { atomWithReducer } from 'jotai/utils';

const INITIAL_STATE = {
    selectedNodeData: {},
    showModal: "",
}

const managedNodesReducer = (prevState, { type, value }) => {
    switch (type) {
        case "open-modal":
            return {
                ...prevState,
                selectedNodeData: value.nodeData,
                showModal: value.showModal,
            };
        case "close-modal":
            return { ...prevState, ...INITIAL_STATE };
        default:
            return INITIAL_STATE

    }
}

export const managedNodesAtom = atomWithReducer(INITIAL_STATE, managedNodesReducer);
