import { useAtomValue } from "jotai";
import { getGlobalMessagesAtom } from "../../store/globalMessage";
import GlobalMessage from "./GlobalMessage";


const GlobalMessages = () => {
    const messages = useAtomValue(getGlobalMessagesAtom);

    if (0 === messages.length) {
        return null;
    }

    return (
        <div style={{ zIndex: 10, position: "fixed", right: "20px", bottom: "20px" }}>
            {messages.map((message) =>
                <GlobalMessage
                    key={message.meta.id}
                    message={message}
                />
            )}
        </div>
    );
};

export default GlobalMessages;