import React, { useEffect, useMemo, useState } from 'react';
import NMService from '../../../services/nm.service';
import { Breadcrumb, Divider, Grid } from 'semantic-ui-react';
import SkippedMeasurementsForm from './SkippedMeasurementsForm';
import CustomTable from '../../../components/CustomTable';

const SkippedMeasurements = () => {
    const [dbContent, setDbContent] = useState([]);
    const [period, setPeriod] = useState({})

    const columns = useMemo(
        () => [
            {
                id: 'nodeid',
                Header: 'Node ID',
                accessor: 'nodeId'
            },
            {
                id: 'nodename',
                Header: 'Node Name',
                accessor: 'nodeName'
            },
            {
                id: 'moduleid',
                Header: 'Module ID',
                accessor: 'moduleId'
            },
            {
                id: 'instanceid',
                Header: 'Instance ID',
                accessor: 'instanceId'
            },
            {
                id: 'actualnumber',
                Header: 'Actual Number',
                accessor: 'actualNumber'
            },
            {
                id: 'expectednumber',
                Header: 'Expected Number',
                accessor: 'expectedNumber'
            },
            {
                id: 'timeperiod',
                Header: 'Time Period',
                accessor: 'timePeriod',
                Cell: ({ row }) => {
                    let period = row.original.timePeriod;
                    return (
                        <p>
                            {`${new Date(period.from).toLocaleString('hr-HR')} -\n${new Date(period.to).toLocaleString('hr-HR')}`}
                        </p>
                    )
                }
            },
        ],
        []
    );

    const getRowStatus = (row) => {
        const { actualNumber, expectedNumber } = row.original;
        return actualNumber !== expectedNumber;
    };

    const fetchSkippedMeasurements = () =>
        NMService.getSkippedMeasurements()
                .then((response) => {
                    if (response.data.length > 0 && response.data[0].timePeriod) {
                        let data = response.data.shift();
                        setPeriod(data.timePeriod);
                    }
                    setDbContent(response.data.sort((a, b) => a.nodeId - b.nodeId));
                }).catch(e => null)

    useEffect(() => {
        fetchSkippedMeasurements();
    }, []);

    return (
        <>
            <Breadcrumb style={{ marginTop: '1rem' }}>
                <Breadcrumb.Section>Alarms</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section active>Skipped Measurements</Breadcrumb.Section>
            </Breadcrumb>
            <Divider />
            {period.from && period.to &&
                <>
                    <Grid>
                        <Grid.Row columns={7} style={{ marginLeft: "1rem" }}>
                            <Grid.Column>
                                <span style={{ fontWeight: 'bold' }}>Current scan period: </span>
                            </Grid.Column>
                            <Grid.Column style={{ whiteSpace: "pre-wrap" }}>
                                {`${new Date(period.from).toLocaleString('hr-HR')} -\n${new Date(period.to).toLocaleString('hr-HR')}`}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider clearing style={{ marginTop: '0.5rem', marginBottom: '1rem' }} />
                </>
            }
            <SkippedMeasurementsForm refetch={fetchSkippedMeasurements}/>
            <CustomTable
                data={dbContent}
                columns={columns}
                fixed={true}
                hideSearch={true}
                warning={getRowStatus}
            />
        </>
    );
};

export default SkippedMeasurements;
