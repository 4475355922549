import { useSetAtom } from "jotai";
import React, { useMemo, useState } from "react";
import { Button } from "semantic-ui-react";
import { addGlobalMessageAtom } from "../../../store/globalMessage";
import { PermissionsGateV } from "../../../layouts/PermissionGate/PermissionGateV";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import { useGetGlobalPermission } from "../../../hooks/useGetGlobalPermission";
import { NODE_ADMIN } from "../../../constants/layout";
import VPNForm from "./VPNForm";
import NMService from "../../../services/nm.service";
import { useQueryClient } from "@tanstack/react-query";
import CustomTable from "../../../components/CustomTable";

const VPNTable = (props) => {
    const queryClient = useQueryClient();
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const hasPermission = useGetGlobalPermission(NODE_ADMIN);
    const data = useMemo(() => props.data || [], [props.data])
    const [selectedVPN, setSelectedVPN] = useState(undefined);
    const [form, setForm] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const postVpnConfig = (payload = undefined) => {
        let updatedData = [...data]
        if (payload !== undefined){
            if (payload?.id){
                updatedData[payload.id] = payload
                delete updatedData[payload.id].id
            } else {
                updatedData = [...updatedData, payload]
            }
        }
        NMService.updateNodeVpnConfig(props.id, {vpnlist: [...updatedData]}).then(r =>
            payload === undefined ?
                addGlobalMessage({
                    header: "Node VPN configuration updated",
                    content: "Node VPN configuration updated, deleted VPN: " + selectedVPN.name,
                    type: "positive",
                })
            :
                addGlobalMessage({
                    header: "Node VPN configuration updated",
                    content: "Node VPN configuration updated",
                    type: "positive",
                }))
                .catch(e => null)
                .finally(() => {
                    queryClient.invalidateQueries(['getNodeVPNConfig']);
                    props.refetch();
                    setSelectedVPN(undefined)
                    setForm(false)
                    setDeleteModal(false)
                })
    }

    const columns = useMemo(
        () => [
            {
                id: "name",
                Header: "Name",
                accessor: "name",
                width: 2,
            },
            {
                id: "peer",
                Header: "Peer",
                accessor: "peer",
                width: 2,
            },
            {
                id: "address",
                Header: "Address",
                accessor: "address",
                width: 2,
            },
            {
                id: "type",
                Header: "Type",
                accessor: "type",
                width: 2,
            },
            {
                id: "allowedIps",
                Header: "Allowed Ips",
                accessor: "allowedIps",
                width: 2,
            },
            {
                id: "peerPublicKey",
                Header: "Peer public key",
                accessor: "peerPublicKey",
                width: 2,
            },
        ],
        []
    );

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: "actions",
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <PermissionsGateV hasPermission={hasPermission}>
                            <Button
                                circular
                                icon="settings"
                                onClick={() => {
                                    setSelectedVPN({...row.original, id: row.id})
                                    setForm(true);
                                }}
                            />
                        </PermissionsGateV>
                        <PermissionsGateV hasPermission={hasPermission}>
                            <Button
                                circular
                                icon="trash"
                                onClick={() => {
                                    setSelectedVPN({ ...row.original, id: row.id });
                                    setDeleteModal(true);
                                }}
                            />
                        </PermissionsGateV>
                    </div>,
                disableGlobalFilter: true,
                disableFilters: true,
                width: 2,
            },
        ]);
    };

    return (
        <>
            <CustomTable
                data={data}
                columns={columns}
                tableHooks={tableHooks}
                footerButton={() =>
                    <PermissionsGateV hasPermission={hasPermission}>
                        <Button
                            floated="right"
                            primary
                            size="tiny"
                            type="button"
                            content="Add new VPN"
                            onClick={() => {
                                setSelectedVPN(undefined);
                                setForm("new");
                            }}
                        />
                    </PermissionsGateV>
                }
            />
            {deleteModal && (
                <ConfirmationModal
                    open={!!selectedVPN?.name}
                    header="Delete VPN?"
                    content={`Are you sure you want to delete VPN: ${selectedVPN?.name}`}
                    onConfirm={() =>{
                        data.splice(selectedVPN.id, 1)
                        postVpnConfig()
                    }}
                    onDismiss={() => setDeleteModal(false)}
                />
            )}
            {form && (
                <VPNForm
                    data={selectedVPN}
                    type={form}
                    post={postVpnConfig}
                    close={() => {
                        setForm(false);
                        setSelectedVPN(undefined);
                    }}
                />
            )}
        </>
    );
};
export default VPNTable;
