import { Fragment } from 'react';
import { useSetAtom } from 'jotai';
import { Form, Grid, Button, Header, Divider, Input } from 'semantic-ui-react';
import styled from 'styled-components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { addGlobalMessageAtom } from '../../store/globalMessage';
import NMService from '../../services/nm.service';
import { groupsActions, groupsAtom } from '../../store/groups';

const ErrorMessage = styled.span`
  font-size: 12px;
  color: red;
`;

const groupFormSchema = yup.object().shape({
    name: yup.string().trim()
        .required("Name is required.")
        .min(3, "Name length should be at least 3 characters.")
        .max(16, "Name cannot exceed more than 16 characters.")
        .matches(/^[a-zA-Z]{1}[a-zA-Z0-9.-_]{1,15}$/, 'Name is not valid.'),
    description: yup.string().trim()
        .required("Description is required.")
        .max(128, "Description cannot exceed more than 128 characters.")
})

const GroupForm = (props) => {
    const formOptions = { resolver: yupResolver(groupFormSchema) };
    const { register, setValue, handleSubmit, formState: { errors } } = useForm(formOptions);

    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const dispatch = useSetAtom(groupsAtom);

    const onSubmit = async (values) => {
        await NMService.addGroup(values).then(() => {
            addGlobalMessage({
                header: 'New group was added!',
                content: `New group was successfully added.`,
                type: 'positive',
            });
            props.refetch();
        }).catch(e => null)
    }

    return (
        <Fragment>
            <Header as="h4" style={{ marginTop: '2rem', marginBottom: '0' }}>
                Add new group
            </Header>
            <Divider clearing style={{ marginTop: '0.5rem', marginBottom: '1rem' }} />

            <Form className="basic segment" onSubmit={handleSubmit(onSubmit)}>
                <Grid>
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column width={4}>
                            <Form.Field>
                                <label>Group name</label>
                                <Input
                                    {...register('name')}
                                    onChange={(_, { name, value }) => {
                                        setValue(name, value);
                                    }}
                                />
                                {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                            </Form.Field>
                        </Grid.Column>


                    </Grid.Row>
                    <Grid.Row verticalAlign="middle" style={{ paddingTop: '0.5rem' }}>
                        <Grid.Column width={6}>
                            <Form.Field>
                                <label>Description</label>
                                <Input
                                    {...register('description')}
                                    onChange={(_, { name, value }) => {
                                        setValue(name, value);
                                    }}
                                />
                                {errors.description && <ErrorMessage>{errors.description.message}</ErrorMessage>}
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row verticalAlign="middle">
                        <Grid.Column width={6}>
                            <Form.Field align="left">
                                <Button
                                    size="small"
                                    primary
                                >
                                    Save
                                </Button>
                                <Button
                                    size="small"
                                    type="button"
                                    onClick={() => dispatch({ type: groupsActions.clearShowType })}
                                >
                                    Cancel
                                </Button>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </Fragment>
    );
}

export default GroupForm;
