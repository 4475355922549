import React from 'react';
import { useForm } from 'react-hook-form';
import { useSetAtom } from 'jotai';
import { Form, Header } from 'semantic-ui-react';
import NMService from '../../../services/nm.service';
import ModuleBoxLayout from '../../../layouts/ModuleBox/ModuleBox';
import NMFieldValidator from '../../../utils/NMFieldValidator';
import { addGlobalMessageAtom } from '../../../store/globalMessage';
import { useFormFields } from '../../../hooks/useFormFields';


const ModuleAsTemplate = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const tagsInfo = "Up to 10 comma separated tags matching: /^[a-zA-Z0-9\u00C0-\u017F.-]{1,20}$/"

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm({ defaultValues: { name: "", description: "", tags: "" }});

    const { renderInput } = useFormFields({ register, errors, setValue, watch });

    const onSubmit = (values) => {
        let tags = [];
        if (values.tags) {
            tags = values.tags.split(",").map((e) => e.trim()).filter((e) => e !== "");
            tags = Array.from(new Set(tags))
            if (!tags.every(e => /^[a-zA-Z0-9\u00C0-\u017F.-]{1,20}$/.test(e)) || tags.length > 10) {
                setError('tags', { type: 'validate', message: tagsInfo })
                return
            }
        }
        clearErrors('tags');

        const data = {
            nodeid: String(props.probeid),
            name: values.name,
            description: values.description.trim(),
            tags: tags
        }

        NMService.saveModulesAsTemplate(data).then(
            (r) => addGlobalMessage({
                        header: 'Module as template!',
                        content: 'All module configuration is saved as template.',
                        type: 'positive',
                    })
        ).then(r => props.closeForm()).catch(e => null)
    }

    return (
        <ModuleBoxLayout>
            <Header as="h4" style={{ margin: '2rem' }} content='Save modules as template'/>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ margin: "0 3rem 3rem 3rem" }}>
                    <Form.Group widths={3}>
                        {renderInput("Name", "name", { validate: (v) => NMFieldValidator.validateString(v, /^[a-zA-Z0-9!@#%^&*._-]{1,64}$/, 1, 64, true) })}
                        {renderInput("Description", "description", { notRequired: true })}
                        {renderInput("Tags", "tags", { notRequired: true, infoText: tagsInfo })}
                    </Form.Group>
                    <Form.Group>
                        <Form.Button type='submit' content='Save' primary />
                        <Form.Button type='button' content='Cancel' onClick={props.closeForm} />
                    </Form.Group>
                </div>
            </Form>
        </ModuleBoxLayout>
    );
}

export default ModuleAsTemplate;
