import { Fragment } from "react";
import { useAtom } from "jotai";
import { Header, Icon, Table } from "semantic-ui-react";
import { licenseMgmtActions, licenseMgmtAtom } from "../../store/licenseManagment";

const LicenseInfoSegment = () => {
    const [state, dispatch] = useAtom(licenseMgmtAtom);
    const c = { padding: "3px" };
    const h = { ...c, backgroundColor: "#f9fafb", fontWeight: "700" };

    const getName = (name) => {
        switch (name) {
            case "name":
                return "Name";
            case "description":
                return "Description";
            case "licenseType":
                return "License type";
            case "company":
                return "Company";
            case "quantity":
                return "Quantity";
            case "serialNumber":
                return "Serial number";
            case "licenseKey":
                return "License key";
            case "issueDate":
                return "Issue date";
            case "licenseCode":
                return "License code";
            case "features":
                return "Features";
            case "active":
                return "Active";
            case "sla":
                return "SLA";
            case "warranty":
                return "Warranty";
            case "updates":
                return "Updates";
            default:
                return name;
        }
    };

    return (
        <Fragment>
            <Header as="h4" dividing>
                &ensp;License info
                <span>
                    <Icon
                        link
                        name={"close"}
                        style={{ float: "right" }}
                        onClick={() => dispatch({ type: licenseMgmtActions.CLOSE_FORM })}
                    />
                </span>
            </Header>
            <Table celled structured compact size="small" attached="top">
                <Table.Body>
                    {Object.keys(state.selectedLicense).map((e, i) =>
                        e !== "uuid" && e !== "remaining" && state.selectedLicense[e] && (
                            <Table.Row key={i} textAlign="center">
                                <Table.Cell style={h} width={4}>
                                    {getName(e)}
                                </Table.Cell>
                                <Table.Cell style={c}>
                                    {e === "active"
                                        ? state.selectedLicense[e]
                                            ? "true"
                                            : "false"
                                        : state.selectedLicense[e]}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                </Table.Body>
            </Table>
        </Fragment>
    );
};

export default LicenseInfoSegment;
