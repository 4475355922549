import { atomWithReducer } from 'jotai/utils';

export const nodesTableActions = {
    UPDATE_FILTER: 'update-global-filter',
    REMOVE_FILTER: 'remove-global-filter',
    UPDATE_COLUMNS: 'update-columns-value',
    UPDATE_HIDDEN: 'update-hidden-columns-value-managed'
}

const INITIAL_STATE = {
    globalFilter: '',
    dbColumnsValue: '',
    hiddenColumnsManaged: ['modulelist', 'hwid', 'seenbefore', 'devicemodel', 'license']
}

const nodesTableReducer = (prevState, { type, value }) => {
    switch (type) {
        case 'update-global-filter':
            return {
                ...prevState,
                globalFilter: value
            }
        case 'remove-global-filter':
            return {
                ...prevState,
                globalFilter: ''
            }
        case 'update-columns-value':
            return {
                ...prevState,
                dbColumnsValue: value
            }
        case 'update-hidden-columns-value-managed':
            return {
                ...prevState,
                hiddenColumnsManaged: value
            }
        default:
            return INITIAL_STATE

    }
}

export const nodesTableAtom = atomWithReducer(INITIAL_STATE, nodesTableReducer);
