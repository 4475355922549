import { useQuery } from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchVisPanelTemplates = ({
    moduleId,
    select,
    options
} = []) => {
    const query = useQuery({
        queryKey: ['getSWModuleVisPanelTemplates'],
        queryFn: () =>
            NMService.getSWModuleVisPanelTemplates(moduleId)
                .then(r => r.data)
                .catch(e => []),
        placeholderData: (data) => data ?? [],
        staleTime: Infinity,
        enabled: !!moduleId,
        select,
        ...options
    })
    return { ...query, data: query.data ?? [] }
}