import { Fragment, useState } from 'react';
import { useAtomValue } from 'jotai';
import { Icon } from 'semantic-ui-react';
import { useFetchFullNetworkConfig } from '../../../hooks/useFetchFullNetworkConfig';
import AnchorStyleButton from '../../../layouts/AnchorStyleButton/AnchorStyleButton';
import { PermissionsGateV } from '../../../layouts/PermissionGate/PermissionGateV';
import Namespaces from './Namespaces/Namespaces';
import NetworkInterfaceForm from './NetworkInterfaceForm';
import NetworkNamespaceForm from './NetworkNamespaceForm';
import NetworkRouteForm from './NetworkRouteForm';
import { editProbeAtom } from '../../../store/editProbe';
import CustomHeader from '../../../components/CustomHeader';

export default function NetworkManagment(props) {
    const probeAtomState = useAtomValue(editProbeAtom);
    const [showSegment, setShowSegment] = useState(false);
    const [showForm, setShowForm] = useState(undefined);

    const toggleForm = (form = undefined) => {
        setShowForm(form);
    };

    const {
        data: networkConfig,
        refetch: fetchFullNetworkConfig
    } = useFetchFullNetworkConfig({ 
        probeid: props.id,
        options: {
            refetchOnMount: 'always',
        } 
    });

    return (
        <>
            <CustomHeader
                title='Node network management'
                toggle={() => setShowSegment(p => !p)}
                show={showSegment}
            />
            {showSegment && (
                <>
                    &emsp;
                    <PermissionsGateV hasPermission={probeAtomState?.permissions?.editbasicdata}>
                        <AnchorStyleButton onClick={() => setShowForm('namespaceForm')}>
                            <Icon name="plus" link />
                            Add new namespace
                        </AnchorStyleButton>
                    </PermissionsGateV>
                    &emsp;&emsp;
                    <PermissionsGateV hasPermission={probeAtomState?.permissions?.editbasicdata}>
                        <AnchorStyleButton onClick={() => setShowForm('interfaceForm')}>
                            <Icon name="plus" link />
                            Add new interface
                        </AnchorStyleButton>
                    </PermissionsGateV>
                    &emsp;&emsp;
                    <PermissionsGateV hasPermission={probeAtomState?.permissions?.editbasicdata}>
                        <AnchorStyleButton onClick={() => setShowForm('routeForm')}>
                            <Icon name="plus" link />
                            Add new route
                        </AnchorStyleButton>
                    </PermissionsGateV>
                    {showForm === 'namespaceForm' && (
                        <NetworkNamespaceForm
                            existing={networkConfig?.namespaces?.map(e => e.name) || []}
                            nodeId={props.id}
                            toggleForm={toggleForm}
                            updateNamespacesConfig={fetchFullNetworkConfig}
                        />
                    )}
                    {showForm === 'interfaceForm' && (
                        <NetworkInterfaceForm
                            existing={networkConfig?.interfaces?.map(e => e.name) || []}
                            nodeId={props.id}
                            toggleForm={toggleForm}
                            interfaces={networkConfig?.interfaces || []}
                            updateNamespacesConfig={fetchFullNetworkConfig}
                        />
                    )}
                    {showForm === 'routeForm' && (
                        <NetworkRouteForm
                            nodeId={props.id}
                            toggleForm={toggleForm}
                            updateNamespacesConfig={fetchFullNetworkConfig}
                        />
                    )}
                    <Namespaces
                        data={networkConfig}
                        nodeId={props.id}
                        interfaces={networkConfig?.interfaces || []}
                        updateNamespacesConfig={fetchFullNetworkConfig}
                    />
                </>
            )}
        </>
    );
}
