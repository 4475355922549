export const BASE_URL = "";

export const TimestampThresholds = [
    {
        seconds: 86400000,
        color: "#ff0000",
        sclass: "tsthreshold1",
    },
    {
        seconds: 3600000,
        color: "#dc7b41",
        sclass: "tsthreshold2",
    },
];
// **** **** **** **** **** **** **** //

// ****
//      do not touch other settings before talking to backend devs !
// ****
export const BASE_URL_AUTH = BASE_URL + "/api/v1.0/useridentity";

export const BASE_URL_NM = BASE_URL + "/api/v1.0";

export const HideFields = ["password", "secret"];

export const DEFAULT_ALARM_SEVERITY = 4;
