import React, { useCallback, useEffect, useState } from 'react';
import { Form, Grid, Button, TextArea } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash'
import NMService from '../../../services/nm.service';
import { PermissionsGateV } from '../../../layouts/PermissionGate/PermissionGateV';
import { useSetAtom } from 'jotai';
import { addGlobalMessageAtom } from '../../../store/globalMessage';

function RawModuleConfig(props) {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [initConfig, setInitConfig] = useState('');
    const {
        handleSubmit,
        control,
        reset,
    } = useForm();

    const fetchProbeModuleData = useCallback(() => {
        NMService.getNMTemplateModule(props.templateid, props.moduleid, props.instanceid).then(
            (response) => {
                const data = JSON.stringify(JSON.parse(response.data.config), null, 2);
                setInitConfig(data);
                reset({ config: data });
            }
        ).catch(e => null)
    }, [props.templateid, props.moduleid, props.instanceid, reset]);

    const required = (value) => {
        if (!value.trim() || value === undefined) {
            return 'Required';
        }
        return undefined;
    }

    const onSubmit = async (values) => {
        try {
            values.config = JSON.stringify(JSON.parse(values.config))
        } catch {
            addGlobalMessage({
                header: 'JSON not valid.',
                content: 'Module configuration not updated.',
                type: 'negative',
            })
            return
        }

        if (_.isEqual(JSON.parse(values.config), JSON.parse(initConfig))) {
            props.noConfigChangeMessage()
            return
        }
        const templatedata = {
            templateid: props.templateid,
            moduleid: props.moduleid,
            instanceid: props.instanceid,
            config: values.config,
        };

        await NMService.updateNMTemplateModuleConfig(templatedata).then(() => {
            props.formSubmitted();
            setInitConfig(values.config);
        }).catch(e => null)
    };

    useEffect(() => {
        fetchProbeModuleData();
    }, [props.templateid, props.moduleid, props.instanceid, fetchProbeModuleData]);

    return (
        <Form style={{ padding: '0 0 0 0' }} onSubmit={handleSubmit(onSubmit)}>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Controller
                                name="config"
                                control={control}
                                defaultValue={initConfig}
                                rules={{ validate: required }}
                                render={({ field: { ref, ...field } }) => (
                                    <TextArea
                                        {...field}
                                        ref={ref}
                                        required
                                        rows={15}
                                    />
                                )}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ paddingLeft: '1rem' }}>
                    <Grid.Column width={6}>
                        <Form.Field align="left">
                            <PermissionsGateV hasPermission={props.hasPermission}>
                                <Button size="small" type="submit" primary content="Update" />
                            </PermissionsGateV>
                            <PermissionsGateV hasPermission={props.hasPermission}>
                                <Button
                                    size="small"
                                    type="button"
                                    onClick={() => reset({ config: initConfig })}
                                    content="Reset"
                                />
                            </PermissionsGateV >
                            <PermissionsGateV hasPermission={props.hasPermission}>
                                <Button
                                    size="small"
                                    type="button"
                                    onClick={props.switchView}
                                    content='Switch view'
                                />
                            </PermissionsGateV>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form >
    );
}

export default RawModuleConfig;
