import { useState } from "react";
import { useFetchNodeVPNConfig } from "../../../hooks/useFetchNodeVPNConfig";
import VPNTable from "./VPNTable";
import CustomHeader from "../../../components/CustomHeader";

export default function VPN(props) {
    const [showSegment, setShowSegment] = useState(false);

    const {
        data,
        refetch
    } = useFetchNodeVPNConfig({
        probeid: props.id,
        select: (data) => data?.vpnlist ?? [],
        options: {
            refetchOnMount: "always",
        },
    });

    return (
        <>
            <CustomHeader
                title='Node VPN management'
                toggle={() => setShowSegment(p => !p)}
                show={showSegment}
            />
            {showSegment && <VPNTable id={props.id} data={data} refetch={refetch}/>}
        </>
    );
}
