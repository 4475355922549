import React, { useEffect, useReducer } from 'react';
import { useSetAtom } from 'jotai';
import { Breadcrumb, Button, Divider } from 'semantic-ui-react';
import DhcpOptionForm from './DhcpOptionForm';
import DhcpOptionTable from './DhcpOptionTable';
import { addGlobalMessageAtom } from '../../../../store/globalMessage';
import NMService from '../../../../services/nm.service';
import ConfirmationModal from '../../../../components/modal/ConfirmationModal';

const reducer = (state, action) => {
    switch (action.type) {
        case 'setState':
            return { ...state, ...action.payload };
        default:
            throw new Error();
    }
};

const DhcpOptions = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [state, dispatchLocal] = useReducer(reducer, {
        options: [],
        selectedOption: {},
        showForm: false,
        showDeleteModal: false,
    });

    useEffect(() => {
        fetchDhcpOptions();
    }, []);

    const fetchDhcpOptions = async () => {
        NMService.getDhcpOptions().then((response) => {
            if (response.data) {
                const optionList = response.data;
                dispatchLocal({
                    type: 'setState',
                    payload: {
                        options: optionList,
                        selectedOption: {},
                        showForm: false,
                        showDeleteModal: false,
                    },
                });
            }
        }).catch(e => null)
    };

    const updateState = (selectedOption = {}, showForm = false, showDeleteModal = false) => {
        dispatchLocal({
            type: 'setState',
            payload: {
                selectedOption: selectedOption,
                showForm: showForm,
                showDeleteModal: showDeleteModal,
            },
        });
    };

    const deleteOption = () => {
        NMService.deleteDhcpOption(state.selectedOption.name).then(() => {
            fetchDhcpOptions();
            addGlobalMessage({
                header: 'DHCP option data is deleted',
                content: 'DHCP option data is successfully deleted',
                type: 'positive',
            })
        }).catch(e => null)
    }

    return (
        <>
            <Breadcrumb style={{ marginTop: '1rem' }}>
                <Breadcrumb.Section>Configuration</Breadcrumb.Section>
                <Breadcrumb.Divider/>
                <Breadcrumb.Section>DHCP</Breadcrumb.Section>
                <Breadcrumb.Divider/>
                <Breadcrumb.Section active>Options</Breadcrumb.Section>
            </Breadcrumb>
            <Divider clearing style={{ marginBottom: '1.5rem' }} />
            <DhcpOptionTable
                data={state.options}
                toggle={updateState}
                addNew={() =>
                    <Button
                        type="button"
                        primary
                        size="tiny"
                        floated='right'
                        content='Add new option'
                        onClick={updateState.bind(null, {}, true, false)}
                    />
                }
            />
            {state.showForm && (<DhcpOptionForm options={state.options} toggle={updateState} updateData={fetchDhcpOptions} />)}
            <ConfirmationModal
                open={state.showDeleteModal}
                header="Delete DHCP option"
                content="Are you sure you want to delete option?"
                onConfirm={() => updateState()}
                onDismiss={() => deleteOption()}
            />
        </>
    );
};

export default DhcpOptions;
