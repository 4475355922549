import { useEffect } from "react";
import { useAtom, useSetAtom } from "jotai";
import { useForm } from "react-hook-form";
import { Header, Form, Divider } from "semantic-ui-react";
import { useFetchTenants } from "../../hooks/useFetchTenants";
import NMService from "../../services/nm.service";
import { addGlobalMessageAtom } from "../../store/globalMessage";
import { InstaceActions, multiTenantAtom } from "../../store/multiTenant";
import { useFormFields } from "../../hooks/useFormFields";
import NMFieldValidator from "../../utils/NMFieldValidator";

const MultiTenantForm = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [state, dispatch] = useAtom(multiTenantAtom);

    const {
        register,
        setValue,
        watch,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const { renderInput } = useFormFields({ register, errors, setValue, watch });

    const {
        data: tenantList = [],
        refetch: refetchTenantList,
    } = useFetchTenants({});

    const onSubmit = (values) =>
        NMService.addTenant(values).then(() =>
                addGlobalMessage({
                    header: !!state.selectedTenantUuid ? "Tenant details updated!" : "Tenant successfully added!",
                    content: !!state.selectedTenantUuid ? "Tenant details successfully updated." : "New tenant successfully added.",
                    type: "positive",
                }))
            .catch(e => null)
            .finally(() => refetchTenantList());

    useEffect(() => {
        const tenant = tenantList.find((e) => e.uuid === state.selectedTenantUuid) || {};
        reset({
            ...tenant,
            name: tenant?.name || "",
            domain: tenant?.domain || "",
            contactname: tenant?.contactname || "",
            contactemail: tenant?.contactemail || "",
            contactphonenumber: tenant?.contactphonenumber || "",
        });
    }, [state.selectedTenantUuid, tenantList, reset]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Header as="h4" style={{ margin: "1rem 0.5rem" }}>
                {state.selectedTenantUuid ? "Edit tenant" : "Add new tenant"}
            </Header>
            <Divider style={{ marginLeft: "0.5rem", marginTop: "0.5rem", marginBottom: "1.5rem" }} />
            <div style={{ padding: "1rem" }}>
                <Form.Group widths={3}>
                    {renderInput("Name", "name", { validate: v => NMFieldValidator.validateString(v, /^[a-zA-Z]+[a-zA-Z0-9_]*[a-zA-Z0-9]$/, 1, 64, true) , disabled: !!state.selectedTenantUuid })}
                    {renderInput("Domain", "domain")}
                </Form.Group>
                <Form.Group widths={3}>
                    {renderInput("Contact person", "contactname")}
                    {renderInput("Contact e-mail", "contactemail")}
                    {renderInput("Contact phone number", "contactphonenumber")}
                </Form.Group>
                <Form.Group>
                    <Form.Button size="small" type="submit" primary content="Save" />
                    <Form.Button
                        size="small"
                        type="button"
                        onClick={() => dispatch({ type: InstaceActions.DeselectTenant })}
                        content="Cancel"
                    />
                </Form.Group>
            </div>
        </Form>
    );
};

export default MultiTenantForm;
