import React, { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Divider, Form, Grid } from "semantic-ui-react";
import { useFormFields } from "../../../hooks/useFormFields";

const DestinationFilter = (props) => {
    const options = props.columns?.map((e) => ({ key: e.id, value: e.id, text: e.Header })) ?? []
    const columns = useMemo(
        () => [
            { id: "region", Header: "Region" },
            { id: "location", Header: "Location" },
            { id: "devicerole", Header: "Device Role" },
            { id: "devicegroup", Header: "Device Group" },
            { id: "name", Header: "Name" },
            { id: "address", Header: "Destination" },
        ], []);
    const { handleSubmit, register, setValue, getValues, reset, watch, formState: { errors } } = useForm();
    const { renderInput, renderDropdown } = useFormFields({ register, errors, setValue, watch });

    const onSubmit = () => {
        let filterStr = "";
        let ch = "?";
        for (const val in getValues()) {
            if (val !== "orderby") {
                if (getValues(val)) {
                    filterStr += ch + val + "=" + getValues(val);
                    ch = "&";
                }
            }
        }
        if (getValues("orderby")) {
            if (getValues("orderby")?.length !== 0) {
                filterStr += ch + "orderby=" + getValues("orderby").join(",");
            }
        }
        props.filter(filterStr);
    };

    const init = useCallback(() => {
        reset({ ...columns.map((e) => e.id).reduce((a, v) => ({ ...a, [v]: "" }), {}), orderby: [] });
    }, [reset, columns]);

    useEffect(() => {
        init();
    }, [init]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Divider />
            <Grid>
                {columns.map(({ id, Header }) => (
                    <Grid.Row key={id} verticalAlign="middle" style={{ padding: "0.5rem 0 0 0" }}>
                        <Grid.Column width={2}>
                            <label align="right">{Header}</label>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            {renderInput(Header, id, { hideLabel: true, notRequired: true })}
                        </Grid.Column>
                    </Grid.Row>
                ))}
                <Grid.Row verticalAlign="middle" style={{ padding: "0.5rem 0 0 0" }}>
                    <Grid.Column width={2}>
                        <label align="right">Chain order by</label>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        {renderDropdown("Order by", "orderby", options, { hideLabel: true, notRequired: true, multiple: true })}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2} />
                    <Grid.Column width={2}>
                        <Form.Button primary type="submit" content="Filter" />
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <Form.Button
                            type="reset"
                            content="Reset"
                            onClick={() => {
                                props.filter();
                                init();
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default DestinationFilter;
