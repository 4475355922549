import { useQuery } from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchAlarmRulesForInstance = ({
    moduleid,
    version,
    hwid,
    instanceid,
    select,
    options
} = {}) => {
    const query = useQuery({
        queryKey: ['getAlarmRulesForInstance', moduleid, version, hwid, instanceid],
        queryFn: () =>
            NMService.getAlarmRulesForInstance(moduleid, version, hwid, instanceid)
                .then(r => r.data)
                .catch(e => []),
        enabled: !!moduleid && !!version && !!hwid && !!instanceid,
        placeholderData: (data) => data ?? [],
        staleTime: Infinity,
        select,
        ...options
    })
    return { ...query, data: query.data ?? [] }
}