import { atom } from 'jotai';
import { atomWithReducer } from 'jotai/utils';

const INITIAL_STATE = {
    hasPermission: false,
    selectedModuleUuid: '',
    showModuleType: '',
    showType: '',
    modulesPermission: [],
    modulesDescendants: [],
    frameworks: {}
}

const usedInstancesReducer = (prevState, { type, value }) => {
    switch (type) {
        case "update-permissions":
            return {
                ...prevState,
                selectedModuleUuid: '',
                showModuleType: '',
                hasPermission: value.hasPermission,
                modulesPermission: value.modulesPermission,
                modulesDescendants: value.modulesDescendants,
                frameworks: value.frameworks
            };
        case "select-module":
            return {
                ...prevState,
                selectedModuleUuid: value.selectedModuleUuid,
                showModuleType: value.showModuleType,
            };
        case "update-show-type":
            return {
                ...prevState,
                showType: value
            };
        case "update-module-permissions":
            return {
                ...prevState,
                modulesPermission: [
                    ...prevState.modulesPermission,
                    value
                ]
            };
        default:
            return INITIAL_STATE

    }
}

export const usedInstancesAtom = atomWithReducer(INITIAL_STATE, usedInstancesReducer);

export const usedInstancesHasPermissionAtom = atom(
    (get) =>
        get(usedInstancesAtom).hasPermission
)

export const getUsedInstancePermissionByUuidAtom = (uuid) => atom(
    get => {
        const foundModule = get(usedInstancesAtom).modulesPermission.find(e => e.uuid === uuid);

        if (foundModule) {
            return foundModule.hasPermission
        }
    }
)

export const getUsedInstanceDescendantByUuidAtom = (uuid) => atom(
    get => {
        const foundModule = get(usedInstancesAtom).modulesDescendants.find(e => e.uuid === uuid);

        if (foundModule) {
            return Boolean(foundModule.subFrameworks || foundModule.submodules) 
        }
    }
)
