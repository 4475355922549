import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { Header, Divider, Icon } from 'semantic-ui-react';
import { atom, useAtom } from 'jotai';
import AssignedModule from './AssignedModule';
import AssignNewModule from './AssignNewModule';
import { PermissionsGateV } from '../../../layouts/PermissionGate/PermissionGateV';
import { getPermission } from '../../../helpers/userPermission';
import { useUserInfo } from '../../../hooks/useAuth';
import { useQueryClient } from '@tanstack/react-query';
import { useFetchTemplateDetails } from '../../../hooks/useFetchTemplateDetails';
import { useFetchSwModules } from '../../../hooks/useFetchSwModules';

export const selectedTemplateModuleAtom = atom(null);
export const templateModulesHasPermission = atom(false);

const AssignedModules = (props) => {
    const { userdata: { role: userRole, groups: userGroups } } = useUserInfo();
    const templateId = useMemo(() => props.templateId, [props.templateId]);

    const initState = {
        topFrameworks: [],
        renderAsRawJson: false,
        showAssignModuleForm: false,
        showDeleteModuleModal: false,
    };
    const [state, setState] = useState(initState);
    const [modulesHasPermission, setModulesPermission] = useAtom(templateModulesHasPermission);
    const queryClient = useQueryClient();

    const {
        data: swModuleList,
        isLoading: swModuleListIsLoading,
    } = useFetchSwModules({ options: { staleTime: Infinity } });

    const {
        data: templateDetails,
        isLoading: templateDetailsIsLoading
    } = useFetchTemplateDetails({ templateId, options: { staleTime: Infinity }})

    const dismissForm = () => {
        setState(prevState => ({
            ...prevState,
            showAssignModuleForm: false,
            showModuleSubForm: '',
        }));
    }

    useLayoutEffect(() => {
        if (!Array.isArray(templateDetails.modules) || swModuleListIsLoading) {
            return;
        };

        const topFrameworkArr = templateDetails.modules.filter(e => swModuleList.find(model => (model.id === e.moduleid && model.data.framework && model.data.toplevel)));
        setState((prevState) => ({
            ...prevState,
            topFrameworks: topFrameworkArr,
        }))

    }, [templateDetails, swModuleList, swModuleListIsLoading]);

    useEffect(() => {
        let haveModulesPermission = false;
        haveModulesPermission = getPermission(userRole, userGroups, 'nodeadmin', null);
        setModulesPermission(haveModulesPermission);
    }, [userRole, userGroups, setModulesPermission]);

    if (templateDetailsIsLoading) {
        return null;
    }

    return (
        <React.Fragment>
            <Header as="h4" style={{ marginTop: '2rem', marginBottom: '0' }}>
                <div>
                    Assigned modules
                    <PermissionsGateV hasPermission={modulesHasPermission}>
                        <Icon
                            name="plus"
                            color="blue"
                            link
                            onClick={() => {
                                setState(prevState => ({ ...prevState, showAssignModuleForm: true }))
                            }}
                            style={{ marginLeft: '1rem', marginRight: '0' }}
                        />
                    </PermissionsGateV>
                </div>
            </Header>
            <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />

            {state.showAssignModuleForm && (
                <AssignNewModule
                    templateId={templateId}
                    dismissForm={dismissForm}
                    refetchTemplateDetails={() => {
                        setState(initState);
                        queryClient.invalidateQueries(['getNMTemplates'])
                    }}
                />
            )}

            {state.topFrameworks.map(({ uuid, moduleid }) => (
                <AssignedModule
                    key={`${uuid}`}
                    top={moduleid}
                    level={1}
                    cantDeleteFramework={state.topFrameworks.length === 1}
                    templateId={templateId}
                    moduleUuid={uuid}
                />
            ))}
        </React.Fragment>
    );
}

export default AssignedModules;
