import { Form, Header, Icon } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import NMService from "../../services/nm.service";
import { useAtom, useSetAtom } from "jotai";
import { addGlobalMessageAtom } from "../../store/globalMessage";
import { useGetGlobalPermission } from "../../hooks/useGetGlobalPermission";
import { NODE_ADMIN } from "../../constants/layout";
import { PermissionsGateV } from "../../layouts/PermissionGate/PermissionGateV";
import { reportActions, reportsAtom } from "../../store/reports";
import { useFetchGrafanaDashboards } from "../../hooks/useFetchDashboards";
import { useFetchReports } from "../../hooks/useFetchReports";
import { useFormFields } from "../../hooks/useFormFields";
import Crontab from "../../components/crontab/Crontab";

const ReportForm = () => {
    const hasPermision = useGetGlobalPermission(NODE_ADMIN);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [, dispatch] = useAtom(reportsAtom);
    const defaultValues = {
        name: "",
        description: "",
        emails: "",
        all_dashboards: [],
        period: { minute: "0", hour: "8", dayOfMonth: "*", month: "*", dayOfWeek: "*" },
    }

    const {
        watch,
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: defaultValues });

    const { renderInput, renderDropdown } = useFormFields({ register, errors, setValue, watch });

    const { refetch: refetchReportList } = useFetchReports({});

    const {
        data: dashboardList = [],
    } = useFetchGrafanaDashboards({
        options: {
            refetchOnMount: "always",
        },
        select: e => e.map(e => ({
                            key: e.dashboard.uid,
                            value: e.dashboard.uid,
                            text: `[${e.meta.folderTitle}] - ${e.dashboard.title}`,
                        }))
    });

    const onSubmit = (values) => {
        let screenshotProperties = {
            dashboards: values.all_dashboards.reduce((e, k) => ({ ...e, [k]: values[k] || "" }), {}),
            theme: "light",
            timeRange: {
                from: "relative",
                to: "now",
            },
        }
        let payload = {
            name: values.name.trim(),
            description: values.description.trim(),
            data: {
                crontab: values.period,
                emails: values.emails.split(",").map(e => e.trim()).filter(e => e),
                screenshotProperties
            }
        }
        NMService.addReport(payload).then(() =>
            addGlobalMessage({
                header: `Report was created!`,
                content: `Report was successfully created.`,
                type: "positive",
            }))
        .then(() => {
            refetchReportList();
            dispatch({ type: reportActions.CLEAR_SHOW_TYPE });
        }).catch(e => null)
    };

    return (
        <>
            <Header as="h4" dividing>Add report</Header>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group widths={4}>
                    {renderInput("Name", "name")}
                </Form.Group>
                <Form.Group widths={2}>
                    {renderInput("Description", "description", { notRequired: true })}
                    {renderInput("Recipients", "emails", { notRequired: true, infoText: "Comma separated mailing list" })}
                </Form.Group>
                <Crontab
                    errors={errors}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    title={"Mailing schedule"}
                />
                <Form.Group>
                    {renderDropdown("Dashboards", "all_dashboards", dashboardList, { width: 8, multiple: true })}
                </Form.Group>
                {(getValues("all_dashboards") || []).map((e,i) =>
                    <Form.Group widths={4} key={e} style={{ marginLeft: "3rem" }}>
                        <Icon
                            link
                            name="trash alternate"
                            color="red"
                            style={{marginTop: "2rem", marginRight: "1rem"}}
                            onClick={() => setValue("all_dashboards", getValues("all_dashboards").filter(k => k !== e))}
                        />
                        {renderInput(dashboardList.find(k => k.key === e)?.text + " (description)", e, { notRequired: true })}
                    </Form.Group>
                )}
                <Form.Group>
                    <PermissionsGateV hasPermission={hasPermision}>
                        <Form.Button primary type="submit" content="Add report" />
                    </PermissionsGateV>
                    <Form.Button type="button" content="Cancel" onClick={() => dispatch({ type: reportActions.CLEAR_SHOW_TYPE })} />
                </Form.Group>
            </Form>
        </>
    );
};

export default ReportForm;
