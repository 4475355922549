import { useSetAtom } from "jotai";
import React, { useMemo, useState } from "react";
import { Button } from "semantic-ui-react";
import { addGlobalMessageAtom } from "../../../store/globalMessage";
import { PermissionsGateV } from "../../../layouts/PermissionGate/PermissionGateV";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import { useGetGlobalPermission } from "../../../hooks/useGetGlobalPermission";
import { NODE_ADMIN } from "../../../constants/layout";
import NotificationForm from "./NotificationForm";
import NMService from "../../../services/nm.service";
import CustomTable from "../../../components/CustomTable";

const NotificationTable = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const hasPermission = useGetGlobalPermission(NODE_ADMIN);
    const data = useMemo(() => props.data || [], [props.data]);
    const [selectedNotifChannel, setSelectedNotifChannel] = useState(undefined);
    const [form, setForm] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const channels = [
        // { key: "SNMP", value: 1, text: "SNMP trap" },
        { key: "email", value: 2, text: "E-mail" },
        { key: "SMS", value: 3, text: "SMS" },
    ]
    const unused = channels.filter((e) => !data.map((e) => e.channeltype.id).includes(e.value));

    const deleteNotificationConfig = () => {
        NMService.deleteNotificationsChannelById(selectedNotifChannel.id)
            .then((r) =>
                addGlobalMessage({
                    header: `Notification channel ${selectedNotifChannel.channeltype.name} deleted successfully`,
                    content: "Notification channel deleted successfully",
                    type: "positive",
                })
            )
            .catch(e => null)
            .finally(() => {
                props.refetch()
                setForm(false);
                setSelectedNotifChannel(undefined);
            });
    };

    const columns = useMemo(
        () => [
            {
                id: "name",
                Header: "Name",
                accessor: "name",
                width: 7
            },
            {
                id: "channeltype",
                Header: "Channel Type",
                accessor: d => d.channeltype?.name,
                width: 7,
            },
        ],
        []
    );

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: "actions",
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <PermissionsGateV hasPermission={hasPermission}>
                            <Button
                                circular
                                icon="settings"
                                type="button"
                                onClick={() => {
                                    setSelectedNotifChannel(row.original);
                                    setDeleteModal(false)
                                    setForm(true);
                                }}
                            />
                        </PermissionsGateV>
                        <PermissionsGateV hasPermission={hasPermission}>
                            <Button
                                circular
                                icon="trash"
                                type="button"
                                onClick={() => {
                                    setSelectedNotifChannel(row.original);
                                    setForm(false)
                                    setDeleteModal(true);
                                }}
                            />
                        </PermissionsGateV>
                    </div>,
                width: 2,
                disableGlobalFilter: true,
                disableFilters: true,
            },
        ]);
    };

    return (
        <>
            <CustomTable
                data={data}
                columns={columns}
                hideSearch={true}
                tableHooks={tableHooks}
                active={selectedNotifChannel?.id}
                footerButton={() =>
                    <PermissionsGateV hasPermission={hasPermission}>
                        <Button
                            floated="right"
                            primary
                            size="tiny"
                            type="button"
                            content="Add new notification channel"
                            disabled={unused.length === 0}
                            onClick={() => {
                                setSelectedNotifChannel(undefined);
                                setForm("new");
                            }}
                        />
                    </PermissionsGateV>
                }
            />
            {deleteModal && (
                <ConfirmationModal
                    open={!!selectedNotifChannel?.name}
                    header="Delete notification channel?"
                    content={`Are you sure you want to delete notification channel: ${selectedNotifChannel?.name}`}
                    onConfirm={() => {
                        data.splice(selectedNotifChannel.id, 1);
                        deleteNotificationConfig();
                    }}
                    onDismiss={() => setDeleteModal(false)}
                />
            )}

            {form && (
                <NotificationForm
                    key={`${form}-${selectedNotifChannel?.id || "new"}`}
                    data={selectedNotifChannel}
                    channels={channels}
                    unused={unused}
                    type={form}
                    close={() => {
                        props.refetch()
                        setDeleteModal(false)
                        setForm(false);
                        setSelectedNotifChannel(undefined);
                    }}
                />
            )}
        </>
    );
};
export default NotificationTable;
