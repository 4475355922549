import { useEffect } from 'react';
import axios from 'axios';
import { useSetAtom } from 'jotai';
import { addGlobalMessageAtom } from '../store/globalMessage';

/**
 * @description Hooks an axios error interceptor for your react application
 */
const useErrorHandler = (logout) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);

    const errorInterceptor = axios.interceptors.response.use(
        res => res,
        error => {
            if (error?.response?.status) {
                if (error.response.status === 401) {
                    logout();
                } else {
                    addGlobalMessage({
                        header: 'Error',
                        content: error?.response?.data?.message || "Something went wrong!",
                        type: 'negative',
                    })
                }
            }
            return Promise.reject(error)
        }
    );

    useEffect(() => {
        return () => {
            axios.interceptors.response.eject(errorInterceptor);
        };
    });
};

export default useErrorHandler;