import { useQuery } from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchUserRoles = ({ 
    select, 
    options 
} = []) => {
    const query = useQuery({
        queryKey: ['getUserRoleList'],
        queryFn: () =>
            NMService.getUserRoleList()
                .then(r => r.data.rv)
                .catch(e => []),
        placeholderData: (data) => data ?? [],
        staleTime: Infinity,
        select,
        ...options
    })
    return { ...query, data: query.data ?? [] }
}