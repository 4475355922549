import React, { useMemo } from 'react';
import { Table, Menu, Grid, Button, Dropdown } from 'semantic-ui-react';
import { useTable, usePagination, useRowSelect } from 'react-table';
import NMService from '../../services/nm.service';
import { addGlobalMessageAtom } from '../../store/globalMessage';
import { useAtom, useSetAtom } from 'jotai';
import { useGetGlobalPermission } from '../../hooks/useGetGlobalPermission';
import { NODE_ADMIN } from '../../constants/layout';
import { PermissionsGateV } from '../../layouts/PermissionGate/PermissionGateV';
import DateFormat from '../../components/dateFormat/DateFormat';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import { unmanagedProbesActions, unmanagedProbesAtom } from '../../store/unmanagedProbes';
import { useUserInfo } from '../../hooks/useAuth';

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <div><input type="checkbox" ref={resolvedRef} {...rest} /></div>
});


function UnManagedProbesTable(props) {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const hasPermission = useGetGlobalPermission(NODE_ADMIN);
    const [state, dispatch] = useAtom(unmanagedProbesAtom);
    const { userdata } = useUserInfo();

    const data = useMemo(() => props.data, [props.data]);
    const columns = useMemo(() => ([
        {
            id: 'hwid',
            Header: 'HW ID',
            accessor: 'hwid'
        },
        {
            id: 'devicemodel',
            Header: 'Device model',
            accessor: 'devicemodel'
        },
        {
            id: 'ts',
            Header: 'Last contact',
            accessor: d => <DateFormat timestamp={d.ts} threshold={true} />
        },
        {
            id: 'ip',
            Header: 'Mgmt IP',
            accessor: 'ip',
        }
    ]), []);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        visibleColumns,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
        },
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllPageRowsSelectedProps }) => <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />,
                    Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
                    style: { textAlign: 'center' },
                },
                ...columns,
                {
                    id: 'actions',
                    Header: () => null,
                    Cell: ({ row }) =>
                        <div style={{ textAlign: 'center' }}>
                            <PermissionsGateV hasPermission={hasPermission}>
                                <Button
                                    circular
                                    icon="settings"
                                    onClick={() => props.showProbeSettings(row.original)}
                                />
                            </PermissionsGateV>
                            {props.showUnassignProbes &&
                                <PermissionsGateV hasPermission={hasPermission}>
                                    <Button
                                        circular
                                        icon="trash"
                                        onClick={() => dispatch({ type: unmanagedProbesActions.DeleteProbe, value: row.original })}
                                    />
                                </PermissionsGateV>
                            }
                        </div>,
                    disableGlobalFilter: true,
                    disableFilters: true,
                },
            ]);
        }
    );

    const deleteUnmanagedNode = async () => {
        const hwid = state.selectedProbeData?.hwid;
        if (!hwid) {
            return;
        }

        await NMService.deleteUnmanagedNode(hwid).then(() => {
            props.refetch();
            dispatch({ type: unmanagedProbesActions.DeselectProbe })
            addGlobalMessage({
                header: 'Node deleted successfully!',
                content: `Node with HW ID "${hwid}" is deleted now.`,
                type: 'positive',
            })
        }).catch(e => null)
    }

    const deleteSelectedRows = async () => {
        if (selectedFlatRows.length > 0) {
            for (const probe of selectedFlatRows) {
                const hwid = probe.original.hwid;
                await NMService.deleteUnmanagedNode(hwid).then(() => {
                    addGlobalMessage({
                        header: 'Node deleted successfully!',
                        content: `Node with HW ID "${hwid}" is deleted now.`,
                        type: 'positive',
                    })
                }).catch(e => null)
            }
            props.refetch();
        }
    }

    const getSelectedRows = async () => {
        if (selectedFlatRows.length > 0) {
            for (const probe of selectedFlatRows) {
                const hwid = probe.original.hwid;

                await NMService.manageNode(hwid).then(
                    () => {
                        addGlobalMessage({
                            header: 'Node managed successfully!',
                            content: `Node with HW ID "${hwid}" is managed now.`,
                            type: 'positive',
                        })
                    }
                ).catch(e => null)
            }
            props.refetch();
        }
    }

    const assignToTenant = async () => {
        if (selectedFlatRows.length > 0) {
            const { tenantid } = userdata;
            const data = {
                tenantid: tenantid,
                hwid: selectedFlatRows.map(row => row.original.hwid)
            }

            await NMService.assignToTenant(data).then(
                () => {
                    addGlobalMessage({
                        header: 'Nodes were successfully assigned to tenant',
                        content: `Nodes were successfully assigned to tenant.`,
                        type: 'positive',
                    })
                }
            ).catch(e => null)
            if (props.showUnassignProbes) {
                props.refetchNoTenantProbes()
            } else {
                props.refetch()
            }
        }
    }

    return (
        <>
            <Table celled size="small" {...getTableProps()}>
                <Table.Header>
                    {headerGroups.map((headerGroup) => (
                        <Table.Row {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Table.HeaderCell {...column.getHeaderProps([{ style: column.style }])}>
                                    {column.render('Header')}
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    ))}
                </Table.Header>
                <Table.Body {...getTableBodyProps()}>
                    {page.length !== 0 ? 
                        page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <Table.Row
                                    active={!!props.selectedProbeId && props.selectedProbeId === row.original.nodeid ? true : false}
                                    {...row.getRowProps()}
                                >
                                    {row.cells.map((cell) =>
                                        <Table.Cell {...cell.getCellProps([{ style: cell.column.style }])}>
                                            {cell.render('Cell')}
                                        </Table.Cell>)}
                                </Table.Row>)})
                                :
                                <Table.Row>
                                    <Table.Cell colSpan={visibleColumns.length} textAlign="center">
                                        No data available
                                    </Table.Cell>
                                </Table.Row>}
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={visibleColumns.length}>
                            <Grid>
                                <Grid.Row verticalAlign="middle" columns={3}>
                                    <Grid.Column>
                                        <span style={{ float: 'left' }}>
                                            &ensp;Show&ensp;
                                            <Dropdown
                                                inline
                                                value={pageSize}
                                                onChange={(_, { value }) => setPageSize(+value)}
                                                options={[10, 20, 30, 40, 50].map((pageSize) => ({
                                                    key: pageSize,
                                                    value: pageSize,
                                                    text: pageSize,
                                                }))}
                                            />
                                            &ensp;entries
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column textAlign="center">
                                        <Menu size="mini" pagination>
                                            <Menu.Item
                                                as="a"
                                                icon="angle double left"
                                                onClick={() => gotoPage(0)}
                                                disabled={!canPreviousPage}
                                            />
                                            <Menu.Item
                                                as="a"
                                                icon="angle left"
                                                onClick={() => previousPage()}
                                                disabled={!canPreviousPage}
                                            />
                                            <Menu.Item
                                                as="a"
                                                content={`${pageCount !== 0 ? (pageIndex + 1) : 0}/${pageCount}`}
                                            />
                                            <Menu.Item
                                                as="a"
                                                icon="angle right"
                                                onClick={() => nextPage()}
                                                disabled={!canNextPage}
                                            />
                                            <Menu.Item
                                                as="a"
                                                icon="angle double right"
                                                onClick={() => gotoPage(pageCount - 1)}
                                                disabled={!canNextPage}
                                            />
                                        </Menu>
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        {props.showUnassignProbes ?
                                            <PermissionsGateV hasPermission={hasPermission}>
                                                <Button
                                                    primary
                                                    size="tiny"
                                                    content='Assign to this tenant'
                                                    onClick={assignToTenant}
                                                />
                                            </PermissionsGateV>
                                            :
                                            <>
                                                <PermissionsGateV hasPermission={hasPermission}>
                                                    <Button
                                                        primary
                                                        size="tiny"
                                                        content='Manage'
                                                        onClick={getSelectedRows}
                                                    />
                                                </PermissionsGateV>
                                                <PermissionsGateV hasPermission={hasPermission}>
                                                    <Button
                                                        color="red"
                                                        size="tiny"
                                                        content='Delete'
                                                        onClick={deleteSelectedRows}
                                                    />
                                                </PermissionsGateV>
                                            </>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>

            <ConfirmationModal
                open={state.showType === unmanagedProbesActions.DeleteProbe}
                header="Delete node"
                content={`Are you sure you want to delete node with HWID="${state.selectedProbeData?.hwid}"?`}
                onConfirm={deleteUnmanagedNode}
                onDismiss={() => dispatch({ type: unmanagedProbesActions.DeselectProbe })}
            />
        </>
    );
}

export default UnManagedProbesTable;