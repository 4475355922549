import "./App.css";
import { router } from './routes';
import { setAuthToken } from './helpers/setAuthToken';
import { RouterProvider } from "react-router-dom";


function App() {
    const token = localStorage.getItem("token");
    if (token) {
        setAuthToken(token);
    }

    return (
        <RouterProvider router={router} />
    );
}

export default App;
