import { atomWithReducer } from 'jotai/utils';

export const reportActions = {
    ADD_NEW_REPORT: 'add-new-report',
    DELETE_REPORT: 'delete-report',
    SHOW_REPORT_INFO: 'show-report-info',
    CLEAR_SHOW_TYPE: 'clear-showing-type'
}

const INITIAL_STATE = {
    selectedItem: {},
    showType: ''
}

const reportsReducer = (prevState, { type, value }) => {
    switch (type) {
        case reportActions.ADD_NEW_REPORT:
            return {
                ...prevState,
                selectedItem: {},
                showType: reportActions.ADD_NEW_REPORT
            };
        case reportActions.DELETE_REPORT:
            return {
                ...prevState,
                selectedItem: value,
                showType: reportActions.DELETE_REPORT
            };
        case reportActions.SHOW_REPORT_INFO:
            return {
                ...prevState,
                selectedItem: value,
                showType: reportActions.SHOW_REPORT_INFO
            };
        case reportActions.CLEAR_SHOW_TYPE:
            return INITIAL_STATE;
        default:
            return INITIAL_STATE

    }
}

export const reportsAtom = atomWithReducer(INITIAL_STATE, reportsReducer);
