import { createContext, useContext, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import NMService from "../services/nm.service";
import { useQueryClient } from "@tanstack/react-query";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const queryClient = useQueryClient()
    const navigate = useNavigate();
    const user = JSON.parse(window.localStorage.getItem("user"));
    const cookienameRef = useRef("");

    const setCookie = (cName, cValue, expDays) => {
        let date = new Date();
        date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
        const expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
    };

    const deleteCookie = (name) => {
        document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    };

    const login = (data) => {
        const { userdata, token, multitenant, cookiename, cookievalue, expires } = data;
        cookienameRef.current = cookiename;
        setCookie(cookiename, cookievalue, expires);
        window.localStorage.setItem("user", JSON.stringify({ multitenant, userdata }));
        window.localStorage.setItem("token", token);

        if (multitenant && !userdata.tenantid) {
            navigate("/multitenant", { replace: true });
        } else {
            navigate("/", { replace: true });
        }
    };

    const logout = () => {
        NMService.logout().catch(e => null);
        deleteCookie(cookienameRef.current);
        cookienameRef.current = "";
        window.localStorage.setItem("user", null);
        window.localStorage.setItem("token", null);
        navigate("/login", { replace: true });
        queryClient.clear();
    };

    const hidrateUserData = (data) => {
        window.localStorage.setItem("user", JSON.stringify({ ...user, userdata: { ...data } }));
    };

    const value = useMemo(
        () => ({
            user,
            login,
            logout,
            hidrateUserData,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export const useUserInfo = () => {
    const { user } = useContext(AuthContext);
    const { userdata, multitenant } = user;
    return { userdata, multitenant };
};
