import { atom } from 'jotai';
import { atomWithReducer } from 'jotai/utils';
import { editProbeAtom } from './editProbe';

const INITIAL_STATE = {
    showType: '',
    hasPermission: false,
    usedLicenseNum: 0,
    remainLicenseNum: 0,
    totalLicenseNum: 0,
}

const installedModulesReducer = (prevState, { type, value }) => {
    switch (type) {
        case "set-license-info":
            return {
                ...prevState,
                showType: '',
                ...value
            };
        case "set-showType":
            return {
                ...prevState,
                showType: value
            };
        default:
            return INITIAL_STATE
    }
}

export const primitiveInstalledModulesAtom = atomWithReducer(
    INITIAL_STATE,
    installedModulesReducer
);

export const installedModulesAtom = atom(
    (get) => (
        {
            ...get(primitiveInstalledModulesAtom),
            license: { ...get(editProbeAtom).license },
            hasPermission: Boolean(get(editProbeAtom).permissions.assignmodules),
        }),
    (_, set, update) => {
        set(primitiveInstalledModulesAtom, update);
    }
);