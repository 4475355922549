import { atom } from "jotai";

export const swModuleAtom = atom({
    hasPermission: false
});

export const addSwModulePermissionAtom = atom(
    null,
    (get, set, { hasPermission }) => {
        set(swModuleAtom, { ...get(swModuleAtom), hasPermission })
    },
);