import { useForm } from "react-hook-form";
import { useFormFields } from "../../../../hooks/useFormFields";
import { Divider, Form, Header } from "semantic-ui-react";
import NMFieldValidator from "../../../../utils/NMFieldValidator";
import { useEffect } from "react";
import NMService from "../../../../services/nm.service";
import { addGlobalMessageAtom } from "../../../../store/globalMessage";
import { useSetAtom } from "jotai";

const EventCollectorSourceForm = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);

    const {
        register,
        setValue,
        watch,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const { renderInput, renderDropdown } = useFormFields({ register, errors, setValue, watch });

    const onSubmit = (values) => {
        for (const a of ["sourceaddress", 'sourceid', 'description'])
            if (typeof values[a] === 'string' && values[a].trim() === '')
                delete values[a]
        values.uuid !== undefined ?
        NMService.putECSource(values).then((r) =>
            addGlobalMessage({
                header: "Source updated successfully",
                content: "Source added successfully",
                type: "positive",
            })
        ).catch(e => null)
        .finally(() => props.close()) :
        NMService.postECSource(values).then((r) =>
            addGlobalMessage({
                header: "Source added successfully",
                content: "Source added successfully",
                type: "positive",
            })
        ).catch(e => null)
        .finally(() => props.close())
    }

    useEffect(() => {
        props.data?.uuid
        ? reset(props.data)
        : reset({ name: "", description: "", sourceid: "", sourceaddress: "", protocol: undefined, tsformat: undefined })
    }, [props.data, reset])

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Header as="h4" style={{ margin: "1rem 0.5rem" }}>
                {props.data?.uuid ? "Edit source" : "Add new source"}
            </Header>
            <Divider style={{ marginLeft: "0.5rem", marginTop: "0.5rem", marginBottom: "1.5rem" }} />
            <div style={{ padding: "1rem" }}>
                <Form.Group widths={4}>
                    {renderInput("Name", "name", { validate: v => NMFieldValidator.validateString(v, /^[a-zA-Z0-9._-]{1,32}$/, 1, 32, true) })}
                    {renderInput("Description", "description", { validate: v => NMFieldValidator.validateString(v, /^[a-zA-Z0-9._ -]{1,255}$/, 1, 255, true) })}
                </Form.Group>
                <Form.Group widths={4}>
                    {renderInput("Source ID", "sourceid", { validate: v => NMFieldValidator.validateString(v, /^[a-zA-Z0-9._-]{1,64}$/, 1, 64), notRequired: !!watch('sourceaddress')?.trim() })}
                    {renderInput("Source address", "sourceaddress", { validate: v => NMFieldValidator.validateString(v, /^[a-zA-Z0-9._-]{1,64}$/, 1, 64), notRequired: !!watch('sourceid')?.trim() })}
                    {renderDropdown('Protocol', 'protocol', [{ key: "syslog", text: "syslog", value: "syslog"}] )}
                    {renderDropdown('Timestamp format', 'tsformat', ["detect", "bsd", "iso"].map(e => ({ key: e, text: e, value: e })) )}
                </Form.Group>
                <Form.Group>
                    <Form.Button size="small" type="submit" primary content="Save" />
                    <Form.Button
                        size="small"
                        type="button"
                        onClick={() => props.close()}
                        content="Cancel"
                    />
                </Form.Group>
            </div>
        </Form>
    );
}

export default EventCollectorSourceForm;