import React from 'react';
import ReactDOM from "react-dom/client";
import 'semantic-ui-css/semantic.min.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient({
    defaultOptions: { 
        queries: { 
            refetchOnWindowFocus: false,
            retry: false
        } 
    },
});

root.render(
    <QueryClientProvider client={queryClient}>
        <App />
    </QueryClientProvider>
);
