import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Dropdown, Form, Grid, Header, Icon, Label } from "semantic-ui-react";

const ObjectDataAddForm = (props) => {
    const {
        handleSubmit,
        register,
        reset,
        setValue,
        getValues,
        watch,
        formState: { errors },
    } = useForm();

    const objectgroupList = ["string", "int", "keyvalue", "object", "list"];
    const subtypes = ["string", "keyvalue", "int"];

    const formRow = (id, Header, condition, inputType, trash = null) =>
        (condition || props.data.type === "edit") && (
            <Grid.Row key={id} verticalAlign="middle" style={{ padding: "0.5rem 0 0 0" }}>
                {trash !== null && <Grid.Column>{trash}</Grid.Column>}
                <Grid.Column width={2}>
                    <label align="right">{Header}</label>
                </Grid.Column>
                <Grid.Column width={5}>
                    <Form.Field error={Boolean(errors[id])}>
                        {inputType !== "textarea" ? (
                            <input
                                {...register(id, {
                                    validate: (value) => validateContent(id, value),
                                })}
                                onChange={(e) => setValue(id, e.target.value)}
                                placeholder={Header}
                                type={inputType}
                            />
                        ) : (
                            <textarea
                                {...register(id, {
                                    validate: (value) => validateContent(id, value),
                                })}
                                onChange={(e) => setValue(id, e.target.value)}
                                placeholder={Header}
                            />
                        )}
                    </Form.Field>
                </Grid.Column>
                <Grid.Column width={5}>
                    {errors[id] && errors[id].type === "validate" && (
                        <Label basic color="red" pointing="left" content={errors[id].message} />
                    )}
                </Grid.Column>
            </Grid.Row>
        );

    const keyvalueListEntry = (id, Header, condition, trash = null) =>
        (condition || props.data.type === "edit") && (
            <>
                {(watch(id) === undefined || watch(id)?.length === 0) && (
                    <Grid.Row verticalAlign="middle" style={{ padding: "0.5rem 0 0 0" }}>
                        {trash !== null && <Grid.Column>{trash}</Grid.Column>}
                        <Grid.Column width={2}>
                            <label align="right">
                                {Header}
                                <Icon
                                    name="plus"
                                    color="blue"
                                    link
                                    style={{ marginLeft: "1rem" }}
                                    onClick={() =>
                                        setValue(id, [...(getValues(id) || []), ["", ""]])
                                    }
                                />
                            </label>
                        </Grid.Column>
                    </Grid.Row>
                )}
                {watch(id)?.length !== undefined &&
                    watch(id)?.map((elem, i) => (
                        <Grid.Row key={i} verticalAlign="middle" style={{ padding: "0.5rem 0 0 0" }}>
                            {trash !== null && <Grid.Column>{i === 0 && trash}</Grid.Column>}
                            <Grid.Column width={2}>
                                {i === 0 && (
                                    <label align="right">
                                        {Header}
                                        <Icon
                                            name="plus"
                                            color="blue"
                                            link
                                            style={{ marginLeft: "1rem" }}
                                            onClick={() =>
                                                setValue(id, [...(getValues(id) || []), ["", ""]])
                                            }
                                        />
                                    </label>
                                )}
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Form.Field>
                                    <input
                                        {...register(id + "[" + i + "][0]", {
                                            validate: (value) =>
                                                validateContent(id + "[" + i + "][0]", value),
                                        })}
                                        onChange={(e) =>
                                            setValue(id + "[" + i + "][0]", e.target.value)
                                        }
                                        placeholder="Key"
                                        type="text"
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Form.Field>
                                    <input
                                        {...register(id + "[" + i + "][1]", {
                                            validate: (value) =>
                                                validateContent(id + "[" + i + "][1]", value),
                                        })}
                                        onChange={(e) =>
                                            setValue(id + "[" + i + "][1]", e.target.value)
                                        }
                                        placeholder="Value"
                                        type={
                                            Number(getValues(id + "[" + i + "][1]")) ===
                                            getValues(id + "[" + i + "][1]")
                                                ? getValues(id + "[" + i + "][1]") % 1 === 0
                                                    ? "number"
                                                    : "text"
                                                : "text"
                                        }
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <Icon
                                    name="trash alternate"
                                    link
                                    color="red"
                                    onClick={() =>
                                        setValue(
                                            id,
                                            getValues(id).filter((e, idx) => idx !== i)
                                        )
                                    }
                                />
                            </Grid.Column>
                        </Grid.Row>
                    ))}
            </>
        );

    const dropdownRow = (id, Header, condition, options) =>
        (condition || props.data.type === "edit") && (
            <Grid.Row verticalAlign="middle" style={{ padding: "0.5rem 0 0 0" }}>
                <Grid.Column width={2}>
                    <label align="right">{Header}</label>
                </Grid.Column>
                <Grid.Column width={5}>
                    <Form.Field error={Boolean(errors[id])}>
                        <Dropdown
                            {...register(id, {
                                validate: (value) => validateContent(id, value),
                            })}
                            onChange={(e, { value }) => setValue(id, value)}
                            placeholder="Select from dropdown..."
                            selection
                            selectOnBlur={false}
                            value={watch(id)}
                            options={options.map((e) => ({
                                key: e,
                                value: e,
                                text: e,
                            }))}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column width={5}>
                    {errors[id] && errors[id].type === "validate" && (
                        <Label basic color="red" pointing="left" content={errors[id].message} />
                    )}
                </Grid.Column>
            </Grid.Row>
        );

    const listValue = (id, Header) => (
        <>
            {dropdownRow("dropdown" + id, Header + " type", true, subtypes)}
            {watch("dropdown" + id) && (
                <Grid.Row style={{ padding: "0.5rem 0 0 0" }}>
                    <Grid.Column width={2} style={{ paddingTop: "0.5rem" }}>
                        <label align="right">{Header}</label>
                        <Icon
                            name="plus"
                            color="blue"
                            link
                            style={{ marginLeft: "1rem" }}
                            onClick={() => setValue(id, [...(getValues(id) || []), ""])}
                        />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        {watch(id)?.map((e, i) => (
                            <React.Fragment key={i}>
                                <Grid style={{ padding: "0.5rem 0 0.5rem 0" }}>
                                    {valueForm(
                                        id + "[" + i + "]",
                                        `${Header} ${i + 1}`,
                                        watch("dropdown" + id),
                                        <Icon
                                            key={i}
                                            link
                                            name="trash alternate"
                                            color="red"
                                            onClick={() => {
                                                setValue(
                                                    id,
                                                    getValues(id).filter((e, idx) => idx !== i)
                                                );
                                                setValue(
                                                    watch("dropdown" + id) + id,
                                                    getValues(watch("dropdown" + id) + id).filter(
                                                        (e, idx) => idx !== i
                                                    )
                                                );
                                            }}
                                        />
                                    )}
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid.Column>
                </Grid.Row>
            )}
        </>
    );

    const valueForm = (id, Header, type = "", trash = null) => {
        switch (type) {
            case "string":
                return formRow(type + id, Header, true, "text", trash);
            case "int":
                return formRow(type + id, Header, true, "number", trash);
            case "keyvalue":
                if (!getValues(type + id)) {
                    setValue(type + id, [["", ""]]);
                }
                return keyvalueListEntry(type + id, Header, true, trash);
            case "object":
                return formRow(type + id, Header, true, "textarea", trash);
            case "list":
                return listValue(type + id, Header);
            default:
                return null;
        }
    };

    const validateContent = (id, value) => {
        switch (id) {
            case "objectgroup":
                if (value.trim() === "") {
                    return "Please enter value";
                }
                break;
            case "objecttype":
                if (!value) {
                    return "Please select value";
                }
                break;
            case "dropdownlistvalue":
                if (!value && getValues("objecttype") === "list") {
                    return "Please select value";
                }
                break;
            case "objectvalue":
                try {
                    JSON.parse(value);
                } catch (e) {
                    return "Please enter valid JSON structure! (keys within quotes)";
                }
                break;
            default:
                return undefined;
        }
        return undefined;
    };

    const cleanUpObj = (id) => {
        setValue(
            id,
            (getValues(id) || [])
                .filter(
                    (e) =>
                        e[0].trim() !== "" &&
                        (typeof e[1] === "number" ? e[1] !== "" : e[1].trim() !== "")
                )
                .map((e) => [e[0].trim(), isNaN(Number(e[1])) ? e[1].trim() : Number(e[1])])
        );
    };

    const onSubmit = () => {
        let final = {};
        setValue("name", (watch("name") || "").trim());
        setValue("objectgroup", (watch("objectgroup") || "").trim());
        cleanUpObj("properties");
        if (typeof watch("tags") !== "object") {
            setValue(
                "tags",
                (watch("tags") || " ")
                    .split(",")
                    .map((e) => e.trim())
                    .filter((e) => e !== "")
            );
        }
        final = {
            name: getValues("name"),
            objectgroup: getValues("objectgroup"),
            objecttype: getValues("objecttype"),
            properties: Object.fromEntries(getValues("properties")),
            tags: getValues("tags"),
        };
        if (getValues("uuid")) {
            final = { ...final, uuid: getValues("uuid") };
        }
        if (getValues("name") === "") {
            delete final.name;
        }
        switch (getValues("objecttype")) {
            case "string":
                setValue("stringvalue", (getValues("stringvalue") || "").trim());
                final = { ...final, value: getValues("stringvalue") };
                break;
            case "int":
                setValue("intvalue", (getValues("intvalue") || "").trim());
                final = { ...final, value: getValues("intvalue") };
                break;
            case "object":
                setValue("objectvalue", (getValues("objectvalue") || "").trim());
                final = { ...final, value: getValues("objectvalue") };
                break;
            case "keyvalue":
                cleanUpObj("keyvaluevalue");
                final = {
                    ...final,
                    value: JSON.stringify(Object.fromEntries(getValues("keyvaluevalue"))),
                };
                break;
            case "list":
                const type = getValues("dropdownlistvalue");
                if (type === "keyvalue") {
                    for (let i = 0; i <= getValues("listvalue")?.length || 0; i++) {
                        cleanUpObj(type + "listvalue[" + i + "]");
                    }
                }
                for (let i = 0; i <= getValues("listvalue")?.length || 0; i++) {
                    if (getValues(type + "listvalue[" + i + "]")?.length === 0) {
                        setValue(
                            "listvalue",
                            getValues("listvalue").filter((e, idx) => idx !== i)
                        );
                        setValue(
                            type + "listvalue",
                            getValues(type + "listvalue").filter((e, idx) => idx !== i)
                        );
                        i -= 1;
                    }
                }
                if (type === "keyvalue") {
                    let temp = [];
                    for (const value of getValues(type + "listvalue") || []) {
                        temp.push(Object.fromEntries(value));
                    }
                    final = { ...final, value: JSON.stringify(temp) };
                } else if (type === "int") {
                    final = {
                        ...final,
                        value: JSON.stringify(getValues(type + "listvalue")?.map((e) => +e) || []),
                    };
                } else {
                    final = {
                        ...final,
                        value: JSON.stringify(getValues(type + "listvalue") || []),
                    };
                }
                break;
            default:
                final = { ...final, value: "" };
        }
        if (getValues("objecttype") !== "list") {
            if (getValues(getValues("objecttype") + "value") === "") {
                setValue("noValue", true);
                return;
            }
        } else if (!(getValues((getValues("dropdownlistvalue") || "") + "listvalue")?.length > 0)) {
            setValue("noValue", true);
            return;
        }
        setValue("noValue", false);
        props.add(final);
    };

    useEffect(() => {
        if (props.data.type === "edit") {
            let data = {
                uuid: props.data.data.uuid,
                name: props.data.data.name,
                objectgroup: props.data.data.objectgroup,
                objecttype: props.data.data.objecttype,
                tags: props.data.data.tags.join(", "),
                properties: Object.entries(props.data.data.properties),
            };
            let value;
            let key;
            let subvalue;
            if (data.objecttype === "keyvalue") {
                key = data.objecttype + "value";
                value = Object.entries(JSON.parse(props.data.data.value));
            } else if (data.objecttype === "list") {
                let listData = JSON.parse(props.data.data.value);
                if (listData.length !== 0) {
                    if (listData.every((e) => typeof e === "object")) {
                        key = "keyvaluelistvalue";
                        subvalue = "keyvalue";
                        value = listData.map((e) => Object.entries(e));
                    } else {
                        let subtype = typeof listData[0] === "string" ? "string" : "int";
                        key = subtype + "listvalue";
                        subvalue = subtype;
                        value = listData;
                    }
                } else {
                    key = "listvalue";
                    subvalue = "string";
                    value = [];
                }
                data = {
                    ...data,
                    dropdownlistvalue: subvalue,
                    listvalue: value.map((e) => ""),
                };
            } else {
                key = data.objecttype + "value";
                value = props.data.data.value;
            }
            data = { ...data, [key]: value };
            reset(data);
        } else {
            reset({ name: "", objectgroup: "" });
        }
    }, [reset, props.data]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Header as="h4" dividing content={props.data.type === "add" ? "Add new entry" : "Edit entry"} style={{ margin: "1.5rem 0" }} />
            <Grid style={{ marginLeft: "2rem" }}>
                {formRow("name", "Friendly name", true)}
                {formRow("objectgroup", "Object group", true)}
                {formRow("tags", "Tags", watch("objectgroup"))}
                {keyvalueListEntry("properties", "Properties", watch("objectgroup"))}
                {dropdownRow("objecttype", "Object type", watch("objectgroup"), objectgroupList)}
                {valueForm("value", "Value", watch("objecttype"))}

                <Grid.Row>
                    <Grid.Column width={2}>
                        {watch("noValue") && (
                            <Label basic color="red" content="Value must be entered" />
                        )}
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <Form.Button
                            primary
                            type="submit"
                            disabled={false}
                            content={props.data.type === "add" ? "Add" : "Update"}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <Form.Button type="button" content="Close" onClick={props.close} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default ObjectDataAddForm;
