import React, { useMemo } from 'react';
import { Button } from 'semantic-ui-react';
import { NODE_ADMIN } from '../../constants/layout';
import { useGetGlobalPermission } from '../../hooks/useGetGlobalPermission';
import { PermissionsGateV } from '../../layouts/PermissionGate/PermissionGateV';
import { strftime } from '../../utils/strftime';
import CustomTable from '../../components/CustomTable';

const TemplatesTable = (props) => {
    const hasPermission = useGetGlobalPermission(NODE_ADMIN);
    const data = useMemo(() => props.data, [props.data]);
    const columns = useMemo(() => [
        {
            id: 'id',
            Header: 'ID',
            accessor: 'id'
        },
        {
            id: 'name',
            Header: 'Name',
            accessor: 'name'
        },
        {
            id: 'description',
            Header: 'Description',
            accessor: 'description'
        },
        {
            id: 'tags',
            Header: 'Tags',
            accessor: d => d.tags ? d.tags.join(', ') : ''
        },
        {
            id: 'created',
            Header: 'Created',
            accessor: d => d.created ? strftime('%Y-%m-%d %H:%M:%S', new Date(Date.parse(d.created))) : ''
        },
        {
            id: 'updated',
            Header: 'Updated',
            accessor: d => d.updated ? strftime('%Y-%m-%d %H:%M:%S', new Date(Date.parse(d.updated))) : ''
        }
    ], []);

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: 'actions',
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <PermissionsGateV hasPermission={hasPermission}>
                            <Button
                                circular
                                icon="settings"
                                onClick={() => {
                                    props.setSelected(row.original.id)
                                    props.setShow('form')
                                }}
                            />
                        </PermissionsGateV>
                        <PermissionsGateV hasPermission={hasPermission}>
                            <Button
                                circular
                                icon="clone"
                                onClick={() => {
                                    props.setSelected(row.original.id)
                                    props.setShow('clone')
                                }}
                            />
                        </PermissionsGateV>
                        <PermissionsGateV hasPermission={hasPermission}>
                            <Button
                                circular
                                icon="trash"
                                onClick={() => {
                                    props.setSelected(row.original.id)
                                    props.setShow('delete')
                                }}
                            />
                        </PermissionsGateV>
                    </div>,
                width: 2,
                disableGlobalFilter: true,
                disableFilters: true,
            },
        ]);
    };

    return (
        <CustomTable
            data={data}
            columns={columns}
            tableHooks={tableHooks}
        />
    );
}

export default TemplatesTable;
