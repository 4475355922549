import React, { useMemo, useReducer } from 'react';
import { Divider, Header } from 'semantic-ui-react';
import AddNamespaceInterfaceForm from './AddNamespaceInterfaceForm';
import EditNamespaceInterfaceForm from './EditNamespaceInterfaceForm';
import NamespaceInterfaceTable from './NamespaceInterfaceTable';
import NMService from '../../../../../services/nm.service';
import { useSetAtom } from 'jotai';
import { addGlobalMessageAtom } from '../../../../../store/globalMessage';
import ConfirmationModal from '../../../../../components/modal/ConfirmationModal';

const reducer = (state, action) => {
    switch (action.type) {
        case 'update':
            return { ...state, ...action.payload };
        default:
            throw new Error();
    }
};

function NamespaceInterfaces(props) {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const data = useMemo(() => props.data, [props.data]);
    const [state, dispatch] = useReducer(reducer, {
        selectedIntf: {},
        showFormType: undefined,
        showDeleteModal: false,
    });

    const toggleForm = (formType = undefined, intf = {}) => dispatch({ type: 'update', payload: { selectedIntf: intf, showFormType: formType } })

    const toggleDeleteModal = (showDelete = false, intf = {}) => dispatch({ type: 'update', payload: { selectedIntf: intf, showDeleteModal: showDelete } })

    const deleteInterface = () => {
        NMService.deleteInterfaceConfig(props.nodeId, state.selectedIntf.name).then((r) =>
                addGlobalMessage({
                    header: 'Deleted interface configuration',
                    content: 'Interface successfully deleted',
                    type: 'positive'
                })
        ).then(() => {
            toggleDeleteModal();
            props.updateNamespacesConfig();
        }).catch(e => null)
    };

    return (
        <>
            <Header as="h5" style={{ marginTop: '1rem', marginBottom: '0' }}>
                Interface table
            </Header>
            <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
            <NamespaceInterfaceTable
                all={props.all}
                data={data.interfaces}
                nodeId={props.nodeId}
                toggleForm={toggleForm}
                toggleDeleteModal={toggleDeleteModal}
                deleteInterface={deleteInterface}
                updateNamespacesConfig={props.updateNamespacesConfig}
            />
            {state.showFormType === 'edit' && (
                <EditNamespaceInterfaceForm
                    key={`edit_namespace-${state.selectedIntf.name}`}
                    namespace={props.data.name}
                    data={state.selectedIntf}
                    nodeId={props.nodeId}
                    toggleForm={toggleForm}
                    updateNamespacesConfig={props.updateNamespacesConfig}
                />
            )}
            {state.showFormType === 'add' && (
                <AddNamespaceInterfaceForm
                    existing={props.existing}
                    nodeId={props.nodeId}
                    namespace={props.data.name}
                    interfaces={props.interfaces}
                    toggleForm={toggleForm}
                    updateNamespacesConfig={props.updateNamespacesConfig}
                />
            )}

            <ConfirmationModal
                open={state.showDeleteModal}
                header="Delete interface"
                content={`Are you sure you want to delete namespace ${state.selectedIntf.name}`}
                onConfirm={() => deleteInterface()}
                onDismiss={() => toggleDeleteModal()}
            />
        </>
    );
}

export default NamespaceInterfaces;
