import { useMemo, useState } from "react";
import { Segment } from "semantic-ui-react";
import CustomHeader from "../../components/CustomHeader";

const ResultModel = (props) => {
    const [showSegment, setShowSegment] = useState(false)
    const model = useMemo(() => props.model, [props.model]);

    const renderRawModel = () => {
        let pretty = !model ? {} : model;

        try {
            pretty = JSON.parse(pretty);
        } catch {} finally {
            pretty = JSON.stringify(pretty, null, 2)
        }

        return (
            <Segment secondary style={{ overflow: 'auto', height: '500px' }}>
                <pre>{pretty}</pre>
            </Segment>
        );
    }

    return (
        <>
            <CustomHeader
                title={props.headerText}
                toggle={() => setShowSegment(p => !p)}
                show={showSegment}
            />
            {showSegment && renderRawModel()}
        </>
    );
};

export default ResultModel;
