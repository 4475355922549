import React, { useState } from 'react';
import { Divider } from 'semantic-ui-react';
import NMService from '../../services/nm.service';
import TemplatesTable from './TemplatesTable';
import TemplateClone from './TemplateClone';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import TemplateForm from './TemplateForm';
import { useFetchSwModules } from '../../hooks/useFetchSwModules';
import { useFetchTemplates } from '../../hooks/useFetchTemplates';
import { useSetAtom } from 'jotai';
import { addGlobalMessageAtom } from '../../store/globalMessage';

function Templates() {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [show, setShow] = useState(undefined)
    const [selected, setSelected] = useState(undefined)

    const {
        data,
        isLoading: templateListIsLoading,
        refetch: templateListRefetch
    } = useFetchTemplates({});

    const {
        isLoading: moduleModelListIsLoading,
    } = useFetchSwModules();

    const deleteTemplate = () => NMService.deleteNMTemplate(selected).then(() =>
                                        addGlobalMessage({
                                            header: 'Template was deleted',
                                            content: 'Template was deleted',
                                            type: 'positive',
                                        }))
                                    .then(() => templateListRefetch())
                                    .catch(e => null)
                                    .finally(() => {
                                        setShow(undefined)
                                        setSelected(undefined)
                                    })

    const cloneTemplate = (name) => {
        const template = data.find(e => e.id === selected)
        const payload = {
            name: name.trim(),
            templateid: template.id,
            description: template.description,
            tags: template.tags
        }
        NMService.cloneNMtemplate(payload).then(() =>
            addGlobalMessage({
                header: 'Template was cloned',
                content: 'Template was cloned successfully',
                type: 'positive',
            }))
        .then(() => templateListRefetch())
        .catch(e => null)
        .finally(() => {
            setShow(undefined)
            setSelected(undefined)
        })
    };

    return (templateListIsLoading || moduleModelListIsLoading) ? null : (
        <>
            <TemplatesTable
                data={data}
                setShow={setShow}
                setSelected={setSelected}
            />
            {show === 'form' && (
                <TemplateForm
                    key={`template_${show}`}
                    templateId={selected}
                    onDismiss={() => {
                        setShow(undefined)
                        setSelected(undefined)
                    }}
                />
            )}
            {show === 'clone' &&
                <TemplateClone
                    onConfirm={cloneTemplate}
                    onDismiss={() => {
                        setShow(undefined)
                        setSelected(undefined)
                    }}
                />
            }
            <ConfirmationModal
                open={show === 'delete'}
                header="Delete template"
                content="Are you sure you want to delete template?"
                onConfirm={deleteTemplate}
                onDismiss={() => {
                    setShow(undefined)
                    setSelected(undefined)
                }}
            />
            <Divider hidden style={{ marginTop: '2rem', marginBottom: '1rem' }} />
        </>
    );
}

export default Templates;