import React, {useMemo } from "react";
import { Dropdown } from "semantic-ui-react";

const MultiSelectColumnFilter = ({ column }) => {
    const { filterValue, setFilter, preFilteredRows, Header, id } = column;

    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            if (Array.isArray(row.original[id])) {
                row.original[id].forEach((_, index) => {
                    return options.add(row.original[id][index]);
                })
            } else {
                return options.add(row.values[id]);
            }
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    const dropdonwOptions = [...new Set([].concat.apply([], options).filter(e => e))].map((option) => ({
        key: option,
        text: option,
        value: option,
    }));

    return (
        <React.Fragment>
            <label>{Header}</label>
            <Dropdown
                multiple
                fluid
                search
                selection
                style={{
                    width: 'fit-content',
                    minWidth: '300px',
                    fontSize: '0.9em',
                    marginBottom: '0.5rem'
                }}
                value={filterValue || []}
                onChange={(e, { value }) => {
                    const allValues = value;
                    setFilter(allValues && allValues.length ? allValues : undefined);
                }}
                options={dropdonwOptions}
            ></Dropdown>
        </React.Fragment>
    );
};

export default MultiSelectColumnFilter;
