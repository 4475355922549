export const customDhcpV4Options = [
    { name: "time-offset", code: 2 },
    { name: "routers", code: 3 },
    { name: "time-servers", code: 4 },
    { name: "name-servers", code: 5 },
    { name: "domain-name-servers", code: 6 },
    { name: "log-servers", code: 7 },
    { name: "cookie-servers", code: 8 },
    { name: "lpr-servers", code: 9 },
    { name: "impress-servers", code: 10 },
    { name: "resource-location-servers", code: 11 },
    { name: "boot-size", code: 13 },
    { name: "merit-dump", code: 14 },
    { name: "domain-name", code: 15 },
    { name: "swap-server", code: 16 },
    { name: "root-path", code: 17 },
    { name: "extensions-path", code: 18 },
    { name: "ip-forwarding", code: 19 },
    { name: "non-local-source-routing", code: 20 },
    { name: "policy-filter", code: 21 },
    { name: "max-dgram-reassembly", code: 22 },
    { name: "default-ip-ttl", code: 23 },
    { name: "path-mtu-aging-timeout", code: 24 },
    { name: "path-mtu-plateau-table", code: 25 },
    { name: "interface-mtu", code: 26 },
    { name: "all-subnets-local", code: 27 },
    { name: "broadcast-address", code: 28 },
    { name: "perform-mask-discovery", code: 29 },
    { name: "mask-supplier", code: 30 },
    { name: "router-discovery", code: 31 },
    { name: "router-solicitation-address", code: 32 },
    { name: "static-routes", code: 33 },
    { name: "trailer-encapsulation", code: 34 },
    { name: "arp-cache-timeout", code: 35 },
    { name: "ieee802-3-encapsulation", code: 36 },
    { name: "default-tcp-ttl", code: 37 },
    { name: "tcp-keepalive-interval", code: 38 },
    { name: "tcp-keepalive-garbage", code: 39 },
    { name: "nis-domain", code: 40 },
    { name: "nis-servers", code: 41 },
    { name: "ntp-servers", code: 42 },
    { name: "vendor-encapsulated-options", code: 43 },
    { name: "netbios-name-servers", code: 44 },
    { name: "netbios-dd-server", code: 45 },
    { name: "netbios-node-type", code: 46 },
    { name: "netbios-scope", code: 47 },
    { name: "font-servers", code: 48 },
    { name: "x-display-manager", code: 49 },
    { name: "dhcp-option-overload", code: 52 },
    { name: "dhcp-server-identifier", code: 54 },
    { name: "dhcp-message", code: 56 },
    { name: "dhcp-max-message-size", code: 57 },
    { name: "vendor-class-identifier", code: 60 },
    { name: "nwip-domain-name", code: 62 },
    { name: "nwip-suboptions", code: 63 },
    { name: "nisplus-domain-name", code: 64 },
    { name: "nisplus-servers", code: 65 },
    { name: "tftp-server-name", code: 66 },
    { name: "boot-file-name", code: 67 },
    { name: "mobile-ip-home-agent", code: 68 },
    { name: "smtp-server", code: 69 },
    { name: "pop-server", code: 70 },
    { name: "nntp-server", code: 71 },
    { name: "www-server", code: 72 },
    { name: "finger-server", code: 73 },
    { name: "irc-server", code: 74 },
    { name: "streettalk-server", code: 75 },
    { name: "streettalk-directory-assistance-server", code: 76 },
    { name: "user-class", code: 77 },
    { name: "slp-directory-agent", code: 78 },
    { name: "slp-service-scope", code: 79 },
    { name: "nds-server", code: 85 },
    { name: "nds-tree-name", code: 86 },
    { name: "nds-context", code: 87 },
    { name: "bcms-controller-names", code: 88 },
    { name: "bcms-controller-address", code: 89 },
    { name: "client-system", code: 93 },
    { name: "client-ndi", code: 94 },
    { name: "uuid-guid", code: 97 },
    { name: "uap-servers", code: 98 },
    { name: "geoconf-civic", code: 99 },
    { name: "pcode", code: 100 },
    { name: "tcode", code: 101 },
    { name: "netinfo-server-address", code: 112 },
    { name: "netinfo-server-tag", code: 113 },
    { name: "default-url", code: 114 },
    { name: "auto-config", code: 116 },
    { name: "name-service-search", code: 117 },
    { name: "subnet-selection", code: 118 },
    { name: "domain-search", code: 119 },
    { name: "sip-servers", code: 120 },
    { name: "classless-static-routes-ht", code: 121 },
    { name: "vivco-suboptions", code: 124 },
    { name: "vivso-suboptions", code: 125 },
    { name: "pana-agent", code: 136 },
    { name: "v4-lost", code: 137 },
    { name: "capwap-ac-v4", code: 138 },
    { name: "sip-ua-cs-domains", code: 141 },
    { name: "rdnss-selection", code: 146 },
    { name: "v4-portparams", code: 159 },
    { name: "v4-captive-portal", code: 160 },
    { name: "option-6rd", code: 212 },
    { name: "v4-access-domain", code: 213 },
];