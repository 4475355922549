import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Grid, Header, Label } from "semantic-ui-react";

import NMService from "../../services/nm.service";

const AssignLicenseForm = (props) => {
    const [licenses, setLicenses] = useState([]);
    const [selectedLicense, setSelectedLicense] = useState(undefined);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        fetchAvailableLicenses();
    }, []);

    const fetchAvailableLicenses = () => {
        NMService.getAvailableLicenses().then((response) => {
            let data = [];
            response.data["v1lpmn"] && data.push({
                key: "v1lpmn",
                value: "v1lpmn",
                text: "5x9 Lightweight Node - Small (" + response.data["v1lpmn"] + ")",
                title: "v1lpmn",
            });
            response.data["v1lps"] && data.push({
                key: "v1lps",
                value: "v1lps",
                text: "5x9 Lightweight Node - Small (" + response.data["v1lps"] + ")",
                title: "v1lps",
            });
            response.data["v1lpm"] && data.push({
                key: "v1lpm",
                value: "v1lpm",
                text: "5x9 Lightweight Node - Medium (" + response.data["v1lpm"] + ")",
                title: "v1lpm",
            });
            response.data["v1lpl"] && data.push({
                key: "v1lpl",
                value: "v1lpl",
                text: "5x9 Lightweight Node - Large (" + response.data["v1lpl"] + ")",
                title: "v1lpl",
            });
            setLicenses(data);
        })
        .catch(e => setLicenses([]));
    };

    const submitForm = () => {
        let error = undefined;

        if (!selectedLicense) {
            error = "Please select license";
        }

        setError(error);
        if (error) {
            return;
        }

        props.submitForm(selectedLicense);
    };

    return (
        <Form style={{ padding: "0" }} onSubmit={(e) => e.preventDefault()}>
            <Grid className={"moduleGrid"} padded verticalAlign="middle">
                <Header as="h4" style={{ margin: "1rem 0" }}>
                    Assign license to nodes
                </Header>
                <Grid.Row style={{ paddingTop: 5 }}>
                    <Grid.Column width={2} textAlign="right">
                        <label>License to assign</label>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Dropdown
                            fluid
                            selection
                            placeholder="Please select..."
                            options={licenses}
                            onChange={(e, { value }) => {
                                setSelectedLicense(value);
                                setError(undefined);
                            }}
                        />
                    </Grid.Column>
                    {error && (
                        <Grid.Column width={5}>
                            <Label basic color="red" pointing="left">
                                {error}
                            </Label>
                        </Grid.Column>
                    )}
                </Grid.Row>

                <Grid.Row style={{ paddingLeft: "1rem" }}>
                    <Grid.Column width={6}>
                        <Form.Field align="left">
                            <Button size="small" primary onClick={submitForm}>
                                Apply
                            </Button>
                            <Button size="small" type="button" onClick={props.dismissForm}>
                                Cancel
                            </Button>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default AssignLicenseForm;
