import { Fragment } from "react"
import { useAtom } from "jotai"
import { Header, Icon, Table, TableHeader } from "semantic-ui-react"
import { reportActions, reportsAtom } from "../../store/reports"
import { useFetchGrafanaDashboards } from "../../hooks/useFetchDashboards"


const ReportInfo = () => {
    const [state, dispatch] = useAtom(reportsAtom);
    const { selectedItem } = state;
    const c = { padding: "3px" }
    const h = { ...c, backgroundColor: "#f9fafb", fontWeight: "700" }

    const {
        data: dashboardTitles,
        isLoading: dashboardTitlesIsLoading,
    } = useFetchGrafanaDashboards({
        select: (data) => {
            let titles = [];
            const dashboards = selectedItem?.data?.screenshotProperties?.dashboards || {};
            Object.keys(dashboards).forEach(e => 
                {
                    const foundItem = data.find(i => e === i.dashboard.uid);
                    if (foundItem) {
                        const title = foundItem.dashboard.title;
                        titles.push(title);
                    }
                })

            return titles.join('\n');
        },
        options: {
            refetchOnMount: 'always',
            enabled: !!selectedItem?.data?.screenshotProperties?.dashboards
        }
    })

    if (dashboardTitlesIsLoading) {
        return null;
    }

    return (
        <Fragment>
            <Header as="h4" dividing>
                &ensp;Report info
                <Icon
                    link
                    name={'close'}
                    style={{ float: "right" }}
                    onClick={() => dispatch({ type: reportActions.CLEAR_SHOW_TYPE })}
                />
            </Header>
            <Table celled structured size="small" compact>
                <TableHeader>
                    <Table.Row textAlign="center">
                        <Table.HeaderCell style={c}>Name</Table.HeaderCell>
                        <Table.HeaderCell style={c}>Description</Table.HeaderCell>
                        <Table.HeaderCell style={c}>Recipients</Table.HeaderCell>
                        <Table.HeaderCell style={c}>Dashboards</Table.HeaderCell>
                    </Table.Row>
                </TableHeader>
                <Table.Body>
                    <Table.Row textAlign="center">
                        <Table.Cell style={c}>{selectedItem.name}</Table.Cell>
                        <Table.Cell style={c}>{selectedItem.description}</Table.Cell>
                        <Table.Cell style={{...c, whiteSpace: "pre-wrap" }}>{selectedItem.data?.emails.join('\n')}</Table.Cell>
                        <Table.Cell style={{...c, whiteSpace: "pre-wrap" }}>{dashboardTitles}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <Table celled structured compact size="small" attached="top">
                <Table.Body>
                    <Table.Row textAlign="center">
                        <Table.Cell style={h} width={6} rowSpan={2}>Mailing schedule(UTC crontab format):</Table.Cell>
                        {Object.keys(selectedItem.data.crontab).map(e =>
                            <Table.Cell key={e} style={h} width={2}>{e}</Table.Cell>
                        )}
                    </Table.Row>
                    <Table.Row textAlign="center">
                        {Object.entries(selectedItem.data.crontab).map((e) => 
                            <Table.Cell key={e[0]} style={c}>{e[1]}</Table.Cell>)}
                    </Table.Row>
                </Table.Body>
            </Table>
        </Fragment>
    )
}

export default ReportInfo;