import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import {
    Form,
    Grid,
    Label,
    Input,
    Button,
    Header,
    Divider,
    Icon,
    Dropdown,
} from 'semantic-ui-react';
import NMService from '../../../services/nm.service';
import { useSetAtom } from 'jotai';
import { addGlobalMessageAtom } from '../../../store/globalMessage';

function ActivePanelForm(props) {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const {
        register,
        reset,
        handleSubmit,
        watch,
        control,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'variableValues',
    });

    const templateIdWatch = watch('templateId');
    const [variables, setVariables] = useState({});
    const [enabledPanelTemplates, setEnabledPanelTemplates] = useState([]);

    useEffect(() => {
        NMService.getNodeModuleEnabledPanels(props.moduleId).then((response) => {
            setEnabledPanelTemplates(response.data);
        }).catch(e => null)
        reset({
            width: '24',
            variableValues: [],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.moduleId]);

    useEffect(() => {
        if (templateIdWatch) {
            const variables = enabledPanelTemplates.find((e) => e.templateid === templateIdWatch).variables;
            setVariables(variables);
        }
    }, [templateIdWatch, enabledPanelTemplates]);

    /* const required = (value) => {
        if (!value) {
            return 'Field is mandatory';
        }
        return null;
    }; */

    const validateVariable = () => {
        return null;
    };

    const getEnabledPanelTemplateOptions = () => {
        return enabledPanelTemplates.map(e => (
            {
                key: e.templateid,
                value: e.templateid,
                text: `${e.templateid} (${e.templatename})`
            }
        ))
    }

    const getVariablesOptions = () => {
        return Object.keys(variables).map((e) => {
            const variable = e.substring(e.indexOf('{') + 1, e.lastIndexOf('}'));
            return {
                key: variable,
                value: variable,
                text: variable,
            };
        });
    };

    const onSubmit = async (values) => {
        const { variableValues, templateId, width } = values;
        let data = {
            dashboardUid: props.data.uid,
            templateId,
            moduleId: props.moduleId,
            width: +width
        };

        if (Array.isArray(variableValues)) {
            data.variableValues = {};
            variableValues.forEach((e) => {
                const key = '${' + e.variable.trim() + '}';
                const value = e.value.trim();
                data.variableValues[key] = value;
            });
        }

        await NMService.saveNodeModuleActivePanel(data).then((response) => {
            props.updateActivePanelsTable();
            addGlobalMessage({
                header: 'Active panel was added!',
                content: `Active panel was successfully added.`,
                type: 'positive',
            });
        }).catch(e => null)
    };

    const onClose = () => {
        props.dismissForm();
    };

    return (
        <Form style={{ maring: '0 0 0 1.5rem' }} onSubmit={handleSubmit(onSubmit)}>
            <Grid>
                <Grid.Row style={{ padding: '0' }}>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={4}>
                        <Header as="h5">Add new panel</Header>
                    </Grid.Column>
                    <Divider />
                </Grid.Row>
                <Grid.Row verticalAlign="middle" style={{ paddingBottom: '0' }}>
                    <Grid.Column width={2}>
                        <label>Template ID</label>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Form.Field error={Boolean(errors.templateId)}>
                            <Controller
                                name="templateId"
                                control={control}
                                render={({ field, ref }) => (
                                    <Dropdown
                                        {...field}
                                        ref={ref}
                                        fluid
                                        selection
                                        placeholder="Select template"
                                        onChange={(e, { value }) => field.onChange(value)}
                                        options={getEnabledPanelTemplateOptions()}
                                    />
                                )}
                            />
                        </Form.Field>
                    </Grid.Column>
                    {errors.templateId && errors.templateId.type === 'validate' && (
                        <Grid.Column width={4}>
                            <Label pointing="left" basic color="red">
                                {errors.templateId.message}
                            </Label>
                        </Grid.Column>
                    )}
                </Grid.Row>
                <Grid.Row verticalAlign="middle" style={{ paddingBottom: '0' }}>
                    <Grid.Column width={2}>
                        <label>Width</label>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Form.Field error={Boolean(errors.width)}>
                            <Input
                                {...register('width', {
                                    validate: (value) => {
                                        if (+value < 6 || +value > 24) {
                                            return 'Value must be integer in range from 6 to 24';
                                        }
                                        return null;
                                    },
                                })}
                                type="number"
                                placeholder="Width"
                                defaultValue={getValues('width')}
                                onChange={(e, { name, value }) => {
                                    setValue(name, value);
                                }}
                            />
                        </Form.Field>
                    </Grid.Column>
                    {errors.width && errors.width.type === 'validate' && (
                        <Grid.Column width={4}>
                            <Label pointing="left" basic color="red">
                                {errors.width.message}
                            </Label>
                        </Grid.Column>
                    )}
                </Grid.Row>
                <Grid.Row verticalAlign="middle" style={{ paddingBottom: '0' }}>
                    <Grid.Column width={3} textAlign="left">
                        <label>Variable values</label>
                        <Icon
                            name="plus"
                            link
                            style={{ color: '#4183C4', marginLeft: '1rem' }}
                            onClick={() => append({ variable: '', value: '' })}
                        ></Icon>
                    </Grid.Column>
                    <Grid.Column width={4}></Grid.Column>
                </Grid.Row>
                {fields.map((field, index) => (
                    <Grid.Row
                        key={field.id}
                        verticalAlign="middle"
                        style={{ paddingBottom: '0' }}
                    >
                        <Grid.Column width={2} textAlign="right">
                            <Form.Field>
                                <Icon
                                    link
                                    name="trash alternate"
                                    style={{ color: '#4183C4', marginLeft: '1rem' }}
                                    onClick={() => remove(index)}
                                ></Icon>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Form.Field error={Boolean(errors.variableValues?.[index]?.variable)}>
                                <Dropdown
                                    {...register(`variableValues.${index}.variable`, {
                                        validate: (value) => validateVariable(value),
                                    })}
                                    fluid
                                    selection
                                    placeholder="Select variable"
                                    onChange={(e, { name, value }) => {
                                        setValue(name, value);
                                    }}
                                    defaultValue={getValues(`variableValues.${index}.variable`)}
                                    options={getVariablesOptions()}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Form.Field>
                                <Input
                                    {...register(`variableValues.${index}.value`)}
                                    disabled={Boolean(props.selectedTemplateId)}
                                    placeholder="Value"
                                    onChange={(e, { name, value }) => {
                                        setValue(name, value);
                                    }}
                                    defaultValue={getValues(`variableValues.${index}.value`)}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                ))}

                <Grid.Row style={{ marginBottom: '1.5rem' }}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={6}>
                        <Form.Field align="left">
                            <Button size="small" type="submit" primary>
                                Create
                            </Button>
                            <Button
                                size="small"
                                type="button"
                                onClick={() => {
                                    reset();
                                    onClose();
                                }}
                            >
                                Cancel
                            </Button>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
}


export default ActivePanelForm;