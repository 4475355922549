export const adjustModel = (model) => {
    let updatedmodel = { ...model };

    Object.keys(model).forEach((e) => {
        if (!model[e].hasOwnProperty('type') || !model[e].hasOwnProperty('render')) {
            return;
        }

        let modelElem = { ...model[e] };
        const type = modelElem.type;
        const intArr = ['uint8', 'uint16', 'uint32', 'int'];
        const floatArray = ['ufloat', 'float']
        const strArr = ['string', 'ipaddress', 'password'];
        const boolArray = ['boolean'];

        if (intArr.includes(type)) {
            modelElem.render.type = 'input';
            modelElem.render.subtype = 'number';
        } else if (floatArray.includes(type)){
            modelElem.render.type = 'input';
            modelElem.render.subtype = 'float';
        } else if (strArr.includes(type)) {
            if (modelElem.hasOwnProperty('values')) {
                modelElem.render.type = 'select';
                modelElem.render.options = modelElem.values.map((e) => ({
                    key: e.value,
                    value: e.value,
                    text: e.name,
                }));
            } else if (type === 'password') {
                modelElem.render.type = 'input';
                modelElem.render.subtype = 'password';
            } else {
                modelElem.render.type = 'input';
                modelElem.render.subtype = 'text';
            }
        } else if (boolArray.includes(type)) {
            modelElem.render.type = 'checkbox';
        } else if (type === 'list') {
            modelElem.render.type = 'list';
            if (modelElem.subtype === 'single') {
                modelElem.render.subtype = 'single';
            } else if (modelElem.subtype === 'object') {
                modelElem.render.subtype = 'object';
            }
            modelElem.schema = adjustModel(modelElem.schema);
        } else if (type === 'object') {
            modelElem.render.type = 'object';
            modelElem.schema = adjustModel(modelElem.schema);
        }
        updatedmodel[e] = modelElem;
    });
    return updatedmodel;
};