import { atomWithReducer } from 'jotai/utils';

export const globalSnmpTemplateActions = {
    AddTemplate: 'add-template',
    EditTemplate: 'edit-template',
    DeleteTemplate: 'delete-template',
    ClearShowType: 'close-template'
}

const INITIAL_STATE = {
    selectedTemplate: {},
    showType: '',
}

const reducer = (prevState, { type, value }) => {
    switch (type) {
        case globalSnmpTemplateActions.AddTemplate:
            return {
                ...prevState,
                selectedTemplate: {},
                showType: globalSnmpTemplateActions.AddTemplate,
            };
        case globalSnmpTemplateActions.EditTemplate:
            return {
                ...prevState,
                selectedTemplate: value,
                showType: globalSnmpTemplateActions.EditTemplate,
            };
        case globalSnmpTemplateActions.DeleteTemplate:
            return {
                ...prevState,
                selectedTemplate: value,
                showType: globalSnmpTemplateActions.DeleteTemplate,
            };
        case globalSnmpTemplateActions.ClearShowType:
            return INITIAL_STATE;
        default:
            return INITIAL_STATE;
    }
}

export const globalSnmpTemplateAtom = atomWithReducer(INITIAL_STATE, reducer);
