import { atomWithReducer } from 'jotai/utils';

export const snmpActions = {
    AddHost: 'add-host',
    EditHost: 'edit-host',
    DeleteHost: 'delete-host',
    ClearShowType: 'close-all'
}

const INITIAL_STATE = {
    selectedHost: {},
    showType: '',
}

const reducer = (prevState, { type, value }) => {
    switch (type) {
        case snmpActions.AddHost:
            return {
                ...prevState,
                selectedHost: {},
                showType: snmpActions.AddHost,
            };
        case snmpActions.EditHost:
            return {
                ...prevState,
                selectedHost: value,
                showType: snmpActions.EditHost,
            };
        case snmpActions.DeleteHost:
            return {
                ...prevState,
                selectedHost: value,
                showType: snmpActions.DeleteHost,
            };
        case snmpActions.ClearShowType:
            return INITIAL_STATE;
        default:
            return INITIAL_STATE;
    }
}

export const snmpAtom = atomWithReducer(INITIAL_STATE, reducer);
