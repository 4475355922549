import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form, Grid, Button, Dropdown, Input, Label } from 'semantic-ui-react';
import NMFieldValidator from '../../../utils/NMFieldValidator';
import NMService from '../../../services/nm.service';
import { useFetchSwModules } from '../../../hooks/useFetchSwModules';
import { useFetchTemplateDetails } from '../../../hooks/useFetchTemplateDetails';
import { useSetAtom } from 'jotai';
import { addGlobalMessageAtom } from '../../../store/globalMessage';


const AssignNewModule = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const {
        register,
        setValue,
        handleSubmit,
        watch,
        control,
        formState: { errors },
    } = useForm();

    const moduleWatch = watch('module');
    const templateId = useMemo(() => props.templateId, [props.templateId]);

    const {
        data: swModuleList,
        isLoading: swModuleListIsLoading,
    } = useFetchSwModules({ options: { staleTime: Infinity }});

    const {
        data: templateDetails,
        refetch: templateDetailsRefetch
    } = useFetchTemplateDetails({ templateId, options: { staleTime: Infinity }})

    const getSWModuleOptions = () => {
        return swModuleList.map((swmodule) => ({
            key: `${swmodule.id}_${swmodule.version}`,
            value: `${swmodule.id}_${swmodule.version}`,
            text: `${swmodule.id} ${swmodule.version}  -  ${swmodule.description}`,
        }
        ));
    }

    const isModuleFramework = () => {
        const module = moduleWatch;
        if (!module) return true;

        const [moduleid] = module.split('_');
        const isFramework = swModuleList.find(e => e.id === moduleid).data.framework;
        return Boolean(isFramework)
    }

    const getSWModuleParentOptions = () => {
        const options = [];
        if (!moduleWatch) return [];

        const { modules } = templateDetails;
        modules.forEach(({ moduleid, version, uuid }) => {
            const isFramework = swModuleList.find(e => e.id === moduleid).data.framework;
            if (isFramework) {
                options.push({
                    key: `${uuid}`,
                    value: `${uuid}`,
                    text: `${moduleid} ${version}`,
                })
            }

        });
        return options;
    }

    const onSubmit = async (values) => {
        if (!templateId) return;
        const [moduleid, version] = values.module.split('_');
        const parentUuid = values.parentUuid;
        let data = {
            templateid: templateId,
            moduleid,
            version,
            parent: parentUuid ? parentUuid : "",
            instanceid: !values.instanceid ? null : values.instanceid,
        };

        await NMService.addNMTemplateModule(data).then(() => {
                addGlobalMessage({
                    type: 'positive',
                    header: 'New template module successfully assigned',
                    content: 'New template module successfully assigned',
                })
                templateDetailsRefetch()
                props.dismissForm()
            }).catch(e => null)
    }

    if (swModuleListIsLoading) {
        return null
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Grid className={'moduleGrid'} padded verticalAlign="middle">
                <h4 style={{ paddingTop: '1rem' }}>Assign module to template</h4>
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column width={2}>
                        <label align="right">Module</label>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Form.Field align="left">
                            <Controller
                                name="module"
                                control={control}
                                rules={{
                                    validate: (value) => {
                                        if (!value) return "Field is mandatory"
                                    }
                                }}
                                render={({ field, ref }) => (
                                    <Dropdown
                                        {...field}
                                        ref={ref}
                                        fluid
                                        search
                                        selection
                                        placeholder="Select module"
                                        onChange={(_, { value }) => field.onChange(value)}
                                        options={getSWModuleOptions()}
                                    />
                                )}
                            />
                        </Form.Field>
                    </Grid.Column>
                    {errors.module?.type && (
                        <Grid.Column width={5}>
                            <Label basic color="red" pointing="left">
                                {errors.module.message}
                            </Label>
                        </Grid.Column>
                    )}
                </Grid.Row>

                {!isModuleFramework() && (
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column width={2}>
                            <label align="right">Parent</label>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Form.Field align="left">
                                <Controller
                                    name="parentUuid"
                                    control={control}
                                    rules={{
                                        validate: (value) => {
                                            if (!value) return "Field is mandatory"
                                        }
                                    }}
                                    render={({ field, ref }) => (
                                        <Dropdown
                                            {...field}
                                            ref={ref}
                                            fluid
                                            selection
                                            placeholder="Select parent module"
                                            onChange={(_, { value }) => field.onChange(value)}
                                            options={getSWModuleParentOptions()}
                                        />
                                    )}
                                />
                            </Form.Field>
                        </Grid.Column>
                        {errors.module?.type && (
                            <Grid.Column width={5}>
                                <Label basic color="red" pointing="left">
                                    {errors.module.message}
                                </Label>
                            </Grid.Column>
                        )}
                    </Grid.Row>
                )}

                <Grid.Row style={{ paddingTop: 5 }} verticalAlign='middle'>
                    <Grid.Column width={2}>
                        <label align="right">Instance name</label>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Form.Field >
                            <Input
                                {...register('instanceid', {
                                    validate: (value) => NMFieldValidator.validateString(
                                        value,
                                        '^[a-zA-Z0-9_-]{1,16}$',
                                        1,
                                        16,
                                        false,
                                        'Instance ID can contain only ASCII letters, numbers, _ and -'
                                    )
                                })}
                                placeholder="Autogenerate"
                                onChange={(_, { name, value }) => {
                                    setValue(name, value.trim());
                                }}
                            />
                        </Form.Field>
                    </Grid.Column>
                    {errors.instanceid?.type && (
                        <Grid.Column width={5}>
                            <Label basic color="red" pointing="left">
                                {errors.instanceid.message}
                            </Label>
                        </Grid.Column>
                    )}
                </Grid.Row>
                <Grid.Row style={{ paddingLeft: '1rem' }}>
                    <Grid.Column width={6}>
                        <Form.Field align="left">
                            <Button
                                size="small"
                                type="submit"
                                primary
                            >
                                Assign new module
                            </Button>
                            <Button
                                size="small"
                                type="button"
                                onClick={() => props.dismissForm()}
                            >
                                Cancel
                            </Button>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
}

export default AssignNewModule;
