import React, { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Form, Header, Icon } from 'semantic-ui-react';
import NMService from '../../../services/nm.service';
import { NamespaceValidation } from './networkValidation';
import { useAtomValue, useSetAtom } from 'jotai';
import { PermissionsGateV } from '../../../layouts/PermissionGate/PermissionGateV';
import { addGlobalMessageAtom } from '../../../store/globalMessage';
import { editProbeAtom } from '../../../store/editProbe';
import { useFormFields } from '../../../hooks/useFormFields';

function NetworkNamespaceForm(props) {
    const probeAtomState = useAtomValue(editProbeAtom);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const { handleSubmit, register, setValue, control, reset, watch, formState: { errors } } = useForm();
    const { renderInput, renderCheckbox } = useFormFields({ register, errors, setValue, watch });
    const { fields: dnsFields, remove: removeDns, append: appendDns } = useFieldArray({ control, name: 'dns' });

    const onSubmit = (values) => {
        const namespaceConfig = {
            name: values.name,
            etc: values.etc ?? false,
            dns: values.dns.map((e) => e.var).filter((e) => e),
        };

        NMService.postNamespaceConfig(props.nodeId, namespaceConfig).then((r) =>
                addGlobalMessage({
                    header: `${props.data?.name !== undefined ? 'Update' : 'Add new' } namespace configuration`,
                    content: `Namespace successfully ${props.data?.name !== undefined ? 'updated' : 'added' }`,
                    type: 'positive',
                })
        ).then(() => {
            props.toggleForm();
            props.updateNamespacesConfig();
        }).catch(e => null)
    };

    useEffect(() => {
        if (props.data) { reset({ ...props.data, dns: props.data.dns.map((e) => ({ var: e })).filter((e) => e) }) }
    }, [props.data]); // eslint-disable-line

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="basic segment" style={{ padding: '1rem 1rem' }}>
            {props.data?.name !== undefined ? <Header as="h5" style={{ marginTop: '0', marginBottom: '1rem' }} dividing>Edit namespace</Header> : null}
            <Form.Group widths={4}>
                {renderInput('Name', 'name', { validate: (v) => props.existing?.includes(v) ? "Namespace already exists!" : NamespaceValidation.validateName(v), readOnly: props.data?.name !== undefined })}
                {renderCheckbox('Etc', 'etc')}
            </Form.Group>
            <Form.Group>
                <Form.Field style={{ marginLeft: '0.5rem' }}>
                    DNS
                    <Icon
                        name="plus"
                        link
                        onClick={() => appendDns({ var: undefined })}
                        style={{ paddingLeft: '1rem', color: '#4183c4' }}/>
                </Form.Field>
            </Form.Group>
            {dnsFields.map((e, i) => (
                <Form.Group key={i} style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem', marginLeft: '3rem' }}>
                    <Icon name="trash alternate" link onClick={() => removeDns(i)} style={{ color: '#4183c4', position: 'relative', marginTop: '0.5rem' }}/>
                    {renderInput('DNS', `dns.${i}.var`, { hideLabel: true, width: 4, validate: (v) => NamespaceValidation.validateDns(v) })}
                </Form.Group>
            ))}
            <Form.Group>
                <PermissionsGateV hasPermission={probeAtomState?.permissions?.editbasicdata}>
                    <Form.Button type="submit" size="small" primary content='Save'/>
                </PermissionsGateV>
                <PermissionsGateV hasPermission={probeAtomState?.permissions?.editbasicdata}>
                    <Form.Button type="button" size="small" content='Cancel' onClick={() => props.toggleForm()}/>
                </PermissionsGateV>
            </Form.Group>
        </Form>
    );
}

export default NetworkNamespaceForm;
