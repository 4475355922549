import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import {Menu, Icon, Dropdown, Popup} from "semantic-ui-react";
import {NODE_ADMIN, READ_ONLY, USER} from "../constants/layout";
import { useAuth } from "../hooks/useAuth";
import { useFetchTenantByID } from "../hooks/useFetchTenantByID";
import {useGetGlobalPermission} from "../hooks/useGetGlobalPermission";
import {useFetchSuspendedMeasurements} from "../hooks/useFetchSuspendedMeasurements";
import DateFormat from "../components/dateFormat/DateFormat";

const MainHeader = () => {
    const { user, logout } = useAuth();
    const hasPermission = useGetGlobalPermission(NODE_ADMIN);
    const dropdownTrigger = (name, text) => (
        <span>
            <Icon name={name} />
            {text}
        </span>
    );

    const { data: tenantData } = useFetchTenantByID({
        tenantID: user?.userdata?.tenantid || "",
        options: {
            refetchOnMount: "always",
        },
    });

    const { data: suspendMeasurements } = useFetchSuspendedMeasurements({
        params: {
            unit: 'TENANT'
        },
        options: {
            refetchOnMount: 'always',
        }
    });

    const adminTenantRestriction = !(
        user.multitenant &&
        user.userdata?.tenantid &&
        (
            user.userdata?.role === READ_ONLY
            || user.userdata?.role === USER
            // || (userdata?.role === ADMIN && !userdata?.probeadd)
            )
    )

    // const adminTenantRestriction = !(
    //     user.multitenant &&
    //     user.userdata?.role === ADMIN &&
    //     user.userdata?.tenantid
    // )

    // const adminTenantRestrictionNoFlag = !(
    //     user.multitenant &&
    //     user.userdata?.role === ADMIN &&
    //     user.userdata?.tenantid &&
    //     !user.userdata?.probeadd
    // )

    return (
        <Fragment>
            <Menu color="blue" inverted borderless>
                <Menu.Item as={Link} to="/" icon="sidebar" content="Home" />
                <Menu.Item content={tenantData?.name || ""}/>
                <Menu.Menu position="right">
                    {suspendMeasurements?.suspend &&
                        <Menu.Item>
                            <Popup
                                on={'hover'}
                                wide
                                position="bottom"
                                style={{textAlign: "center"}}
                                trigger={<div style={{ cursor: 'pointer', paddingLeft: '0.5rem' }}><Icon name='pause' color='red'/></div>}
                            >
                                <>
                                    measurements suspended <br/>
                                    {suspendMeasurements?.expireTime === 'indefinitely' ? suspendMeasurements.expireTime : (<>until <DateFormat timestamp={suspendMeasurements.expireTime}/></>)}
                                    <br/>
                                    by {suspendMeasurements?.unit} admin
                                </>
                            </Popup>
                        </Menu.Item>
                    }
                    {user.userdata.role === 'tenantadmin' &&
                        <Menu.Item
                            position="right"
                            as={Link}
                            to="/multitenant"
                            content="Tenant administration" />
                    }
                    <Dropdown icon={null} item trigger={dropdownTrigger("user", user?.userdata?.username || "")} >
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/localuser" text="Local users" />
                            <Dropdown.Item as={Link} to="/groups" text="Groups" />
                            {/* {adminTenantRestriction &&
                                <Dropdown.Item as={Link} to="/manage" text="Auth backends" />
                            } */}
                            <Dropdown.Item
                                onClick={() => logout()}
                                text="Logout"
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Menu>
                <Menu.Item link icon="question circle outline" href="/documentation" target="_blank" title="Help" />
            </Menu>
            <Menu>
                <Menu.Item as={Link} to="/nodes" icon="sitemap" content="Nodes" />
                <Menu.Item as={Link} to="/templates" icon="copy" content="Templates" />
                <Dropdown icon={null} pointing item trigger={dropdownTrigger("alarm", "Alarms")}>
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={"/alarms/all-alarms"} content="Alarms" />
                        <Dropdown.Item as={Link} to={"/alarms/machine-learning"} content="ML alarming" />
                        <Dropdown.Item as={Link} to={"/alarms/notifications"} content="Notifications" />
                        {hasPermission &&
                            <Dropdown.Item as={Link} to={"/alarms/skipped-measurements"} content="Skipped Measurements" />
                        }
                    </Dropdown.Menu>
                </Dropdown>
                <Menu.Item as={Link} to="/module" icon="th" content="Modules" />
                <Dropdown icon={null} pointing item trigger={dropdownTrigger("globe", "Locations")} >
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/locations/list" text="Location list" />
                        <Dropdown.Item as={Link} to="/locations/params" text="Location parameters" />
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown icon={null} pointing item trigger={dropdownTrigger("settings", "Configuration")}>
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={"/configuration/"} content="Global configuration" />
                        <Dropdown.Item as={Link} to={"/snmp/"} content="SNMP" />
                        {adminTenantRestriction &&
                            // style da bude clickable na cijelom D.Item, inace pola ne otvara dropdown
                            <>
                                <Dropdown.Item style={{ position: "relative", height: "36px", width: "100%" }}>
                                    <Dropdown
                                        text="DHCP"
                                        style={{ height: "100%", width: "100%", position: "absolute", right: 0, top: 0, padding: "0.8em 1em 1em 1.125em" }}
                                    >
                                        <Dropdown.Menu>
                                            <Dropdown.Item as={Link} to={"/services/dhcp/global"} >
                                                Global configuration
                                            </Dropdown.Item>
                                            <Dropdown.Item as={Link} to={'/services/dhcp/options'}>
                                                Options
                                            </Dropdown.Item>
                                            <Dropdown.Item as={Link} to={'/services/dhcp/subnets'}>
                                                Subnets
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Dropdown.Item>
                                <Dropdown.Item style={{ position: "relative", height: "36px", width: "100%" }}>
                                    <Dropdown
                                        text="Event collector"
                                        style={{ height: "100%", width: "100%", position: "absolute", right: 0, top: 0, padding: "0.8em 1em 1em 1.125em" }}
                                    >
                                        <Dropdown.Menu>
                                            <Dropdown.Item as={Link} to={"/services/eventcollector/templates"} >
                                                Templates
                                            </Dropdown.Item>
                                            <Dropdown.Item as={Link} to={'/services/eventcollector/sourcegroups'}>
                                                Source groups
                                            </Dropdown.Item>
                                            <Dropdown.Item as={Link} to={'/services/eventcollector/sources'}>
                                                Sources
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Dropdown.Item>
                            </>
                        }
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown icon={null} pointing item trigger={dropdownTrigger("random", "Object service")} >
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={"/objserv/destinations"} content="Destinations" />
                        <Dropdown.Item as={Link} to={"/objserv/objdata"} content="Object data" />
                    </Dropdown.Menu>
                </Dropdown>
                
                <Menu.Item as={Link} to="/reporting" icon="file pdf" content="Reporting" />
                <Menu.Item as={Link} to="/grafana/" target="_blank" icon="chart line" content="Monitoring" />
                {adminTenantRestriction &&
                    <Menu.Item as={Link} to={"/license"} position="right" content="License" icon="key" />
                }
            </Menu>
        </Fragment>
    );
}

export default MainHeader;