const OpenStreetLocationLink = (props) => (
        props.longitude !== undefined &&
        props.latitude !== undefined && (
            <a href={`https://www.openstreetmap.org/?mlat=${props.latitude}&mlon=${props.longitude}#map=${props.zoom ?? 13}/${props.latitude}/${props.longitude}`} rel="noreferrer" target="_blank">
                {props.latitude}/{props.longitude}
            </a>
        )
    );

export default OpenStreetLocationLink;
