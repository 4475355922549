import React, { useEffect, useMemo, useState } from "react";
import { useSetAtom } from "jotai";
import { Button, Grid, Header } from "semantic-ui-react";
import NMService from "../../../services/nm.service";
import { addGlobalMessageAtom } from "../../../store/globalMessage";
// import MLConfigAdd from "./MLConfigAdd";
import CustomTable from "../../../components/CustomTable";

const MLConfigTable = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    // const [showForm, setShowForm] = useState(false)
    const data = useMemo(() => props.table, [props.table]);
    const [distinctModels, setDistinctModels] = useState([]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                Header: "Status (error)",
                accessor: (d) => `${d.status} (${d.errordesc})`,
            },
            {
                id: "datatoread",
                Header: "Data to read",
                accessor: (d) => d.config?.datatoread.join(", "),
            },
            {
                id: "hwidinstanceid",
                Header: "HWID / Instance ID",
                accessor: (d) => d.config?.hwid + " / " + d.config?.instanceid,
            },
            {
                id: "datafilters",
                Header: "Data filters",
                accessor: (d) => Object.entries(d.config?.datafilters).map((e) => e[0] + ": " + e[1] + "\n"),
            },
            {
                id: "algorithm",
                Header: "Algorithm",
                accessor: d => d.config?.algorithm?.name,
            },
        ],
        []
    );

    const activateModel = (modelID) =>
        NMService.MLActivateModel(modelID).then((response) => {
            if (response.data.hasOwnProperty("error")) {
                addGlobalMessage({
                    header: "Error occurred",
                    content: response.data.error.message || "",
                    type: "warning",
                });
            } else if (response.data.rv === "OK") {
                addGlobalMessage({
                    header: "Successfully activated ML model",
                    content: "Successfully activated ML model",
                    type: "positive",
                });
            }
        })
        .catch(e => null)
        .finally(r => props.fetch())

    const deactivateModel = (modelID) =>
        NMService.MLDeactivateModel(modelID).then((response) => {
            if (response.data.hasOwnProperty("error")) {
                addGlobalMessage({
                    header: "Error occurred",
                    content: response.data.error.message || "",
                    type: "warning",
                });
            } else if (response.data.rv === "OK") {
                addGlobalMessage({
                    header: "Successfully deactivated ML model",
                    content: "Successfully deactivated ML model",
                    type: "positive",
                });
            }
        })
        .catch(e => null)
        .finally(r => props.fetch())

    const removeModel = (modelID) =>
        NMService.MLRemoveModel(modelID).then((response) => {
            if (response.data.hasOwnProperty("error")) {
                addGlobalMessage({
                    header: "Error occurred",
                    content: response.data.error.message || "",
                    type: "warning",
                });
            } else if (response.data.rv === "OK") {
                addGlobalMessage({
                    header: "Successfully removed ML model",
                    content: "Successfully removed ML model",
                    type: "positive",
                });
            }
        })
        .catch(e => null)
        .finally(r => props.fetch())

    const tableHooks = (hooks) =>
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: "Actions",
                id: "actions",
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            disabled={row.original.status !== "learned"}
                            circular
                            icon="check"
                            size="small"
                            title='Activate model'
                            onClick={() => activateModel(row.original.id)}
                        />
                        <Button
                            disabled={row.original.status !== "active"}
                            circular
                            icon="close"
                            size="small"
                            title='Deactivate model'
                            onClick={() => deactivateModel(row.original.id)}
                        />
                        <Button
                            disabled={row.original.status === "learning"}
                            circular
                            icon="trash"
                            size="small"
                            title='Remove model'
                            onClick={() => removeModel(row.original.id)}
                        />
                    </div>,
                disableFilters: true,
                disableGlobalFilters: true,
            },
        ])

    useEffect(() => {
        NMService
            .getDistinctMLFilters(props.data.hwid, props.data.moduleid, props.data.version, props.data.instanceid)
            .then((r) => setDistinctModels(r.data))
            .catch(e => null);
    }, [props.data]);

    return data.length !== 0 && distinctModels.length !== 0 ? (
        <>
            <Header as="h4" content="Machine learning alarms" dividing />
            <CustomTable
                data={data}
                columns={columns}
                tableHooks={tableHooks}
                // footerButton={() =>
                //     <Button
                //         floated='right'
                //         size="tiny"
                //         primary={!showForm}
                //         type="button"
                //         content={showForm ? "Close form" : "Add new model"}
                //         onClick={() => showForm ? setShowForm(false) : setShowForm(true)}
                //     />
                // }
            />
            {distinctModels.length !== 0 && (
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={13}></Grid.Column>
                        <Grid.Column textAlign="right" width={3}>
                            <Button
                                primary
                                size="tiny"
                                type="button"
                                content="Activate default models"
                                onClick={() =>
                                    distinctModels.forEach((e) => props.post(e))
                                }
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
            {/* {showForm && (
                <MLConfigAdd
                    submit={props.parse}
                    moduleid={props.data.moduleid}
                    version={props.data.version}
                />
            )} */}
        </>
    ) : null;
};

export default MLConfigTable;
