import React, { useEffect, useState } from 'react';
import { useFormContext } from "react-hook-form";
import { Form, Input } from 'semantic-ui-react';
import { InterfaceValidation } from './networkValidation';

export default function PeerWrapper(props) {
    const [isValidIp, setIsValidIp] = useState(false);
    const { setValue, register, unregister, getValues, formState: { errors } } = useFormContext();

    useEffect(() => {
        const timer = setTimeout(() => {
            const ipAddr = props.ipAddrValue;
            setIsValidIp(false);
            if (ipAddr) {
                const ipAddrErrorMessage = InterfaceValidation.validateIPAddr(ipAddr);
                if (!ipAddrErrorMessage) {
                    const prefix = ipAddr.split('/')[1];
                    if (prefix === '31' || prefix === '32') {
                        setIsValidIp(true);
                    }
                }
            }
        }, 500);
        return () => {
            clearTimeout(timer);
            unregister(props.name, { keepValue: false, keepError: false });
        };
    }, [props.ipAddrValue, props.name, unregister]);

    return (
        <>
            {isValidIp ? (
                <Form.Field width={props.width ?? 4} error={Boolean(errors.address?.[props.index]?.peer)}>
                    <Input
                        {...register(props.name, {
                            validate: (value) => InterfaceValidation.validatePeer(value),
                        })}
                        placeholder="Peer"
                        defaultValue={getValues(props.name)}
                        onChange={(_, { name, value }) => {
                            setValue(name, value);
                        }}
                    />
                </Form.Field>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>&emsp;----&emsp;</div>
            )}
        </>
    );
}
