import { useQuery } from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchProbeLicense = ({ 
    probeid, 
    select, 
    options 
} = {}) => {
    const query = useQuery({
        queryKey: ['getLicenseById', String(probeid)],
        queryFn: () =>
            NMService.getLicenseById(probeid)
                .then(r => r.data)
                .catch(e => {}),
        staleTime: Infinity,
        enabled: !!probeid,
        retry: false,
        select,
        ...options
    })
    return { ...query, data: query.data ?? {} }
}