import { atom } from 'jotai';
import { atomWithReducer } from 'jotai/utils';

const INITIAL_STATE = {
    hasPermission: false,
    selectedModuleUuid: '',
    showModuleType: '',
    modulesPermission: [],
    modelData: []
}

const unassignedModulesReducer = (prevState, { type, value }) => {
    switch (type) {
        case "update-permissions":
            return {
                ...prevState,
                selectedModuleUuid: '',
                showModuleType: '',
                hasPermission: value.hasPermission,
                modulesPermission: value.modulesPermission,
                modelData: value.modelData

            };
        case "select-module":
            return {
                ...prevState,
                selectedModuleUuid: value.selectedModuleUuid,
                showModuleType: value.showModuleType,
            };
        case "update-module-permissions":
            return {
                ...prevState,
                modulesPermission: [
                    ...prevState.modulesPermission,
                    value
                ]
            };
        default:
            return INITIAL_STATE

    }
}

export const unassignedModulesAtom = atomWithReducer(INITIAL_STATE, unassignedModulesReducer);

export const unassignedModulesHasPermissionAtom = atom(
    (get) =>
        get(unassignedModulesAtom).hasPermission
)

export const getUnassignedModulePersmissionByUuid = (uuid) => atom(
    get => {
        const foundModule = get(unassignedModulesAtom).modulesPermission
            .find(e => e.uuid === uuid);

        if (foundModule) {
            return foundModule.hasPermission
        }
    }
)

export const getModelDataByUuid = (uuid) => atom(
    get => {
        const foundModule = get(unassignedModulesAtom).modelData
            .find(e => e.uuid === uuid);

        if (foundModule) {
            return foundModule
        }
    }
)