import { Breadcrumb, Divider } from "semantic-ui-react";
import { useFetchNotificationChannels } from "../../../hooks/useFetchNotificationChannels.js";
import NotificationTable from "./NotificationTable";

const NotificationSettings = () => {
    const { data, refetch } = useFetchNotificationChannels({
        options: {
            refetchOnMount: "always",
        },
    });

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Section>Alarms</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section active>Notification settings</Breadcrumb.Section>
            </Breadcrumb>
            <Divider clearing />

            <NotificationTable data={data} refetch={refetch} />
        </>
    );
};

export default NotificationSettings;
