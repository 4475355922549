import React, { useEffect, useState } from 'react';
import { Grid, Form, Button, Header, Dropdown, Label, Radio } from 'semantic-ui-react';

import NMService from '../../services/nm.service';

function AssignTemplateForm(props) {
    const [templateid, setTemplateid] = useState(undefined);
    const [howtoapply, setHowtoapply] = useState('replace');
    const [hardlink, setHardlink] = useState('copy')
    const [templateList, setTemplateList] = useState([]);
    const [errors, setErrors] = useState({
        templateid: undefined,
        howtoapply: undefined,
    });

    useEffect(() => {
        fetchTemplates();
    }, []);

    const fetchTemplates = () => {
        NMService.getNMTemplates().then((response) => {
            setTemplateList(response.data);
        })
        .catch(e => setTemplateList([]));
    };

    const getTemplateOptions = () => {
        return templateList.map((template) => {
            let text = `${template.id} ${template.name}`;
            if (template.description) {
                text += `  -  ${template.description}`;
            }

            return {
                key: `${template.id}_${template.name}`,
                value: `${template.id}`,
                text: text,
            };
        });
    };

    const applyMethodOptions = () => ([
        { key: 'replace', value: 'replace', text: 'delete existing modules' },
        {
            key: 'mergekeep',
            value: 'mergekeep',
            text: 'merge and keep config of existing modules',
        },
        {
            key: 'mergeoverwrite',
            value: 'mergeoverwrite',
            text: 'merge and overwrite config of existing modules',
        },
    ]);

    const submitForm = () => {
        let data = {
            templateid : templateid,
            howtoapply: howtoapply,
            hardlink: hardlink,
        }

        let errors = { templateid: '', howtoapply: '' }
        if (!data.templateid) {
            errors.templateid = 'Please select template'
        }

        if (!data.howtoapply) {
            errors.howtoapply = 'Please select apply method'
        }

        setErrors(errors)
        if (errors.templateid || errors.howtoapply) {
            return
        }

        props.submitForm(data);
    }

    return (
        <Form style={{ padding: '1rem 0 0 0' }} onSubmit={(e) => e.preventDefault()}>
            <Grid className={'moduleGrid'} padded verticalAlign="middle">
                <Header as="h4" style={{ margin: '1rem 0' }}>
                    Assign template to nodes
                </Header>
                <Grid.Row style={{ paddingTop: 5 }}>
                    <Grid.Column width={3} textAlign="right">
                        <label>Template to assign</label>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Dropdown
                            fluid
                            selection
                            placeholder="Please select..."
                            value={templateid}
                            onChange={(e, { value }) => {
                                setTemplateid(value);
                            }}
                            options={getTemplateOptions()}
                        />
                    </Grid.Column>
                    {errors.templateid && (
                        <Grid.Column width={5}>
                            <Label basic color="red" pointing="left">
                                {errors.templateid}
                            </Label>
                        </Grid.Column>
                    )}
                </Grid.Row>

                <Grid.Row style={{ paddingTop: 5 }}>
                    <Grid.Column width={3} textAlign="right">
                        <label align="right">Existing modules</label>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Dropdown
                            fluid
                            selection
                            placeholder="Please select..."
                            value={howtoapply}
                            onChange={(e, { value }) => {
                                setHowtoapply(value);
                            }}
                            options={applyMethodOptions()}
                        />
                    </Grid.Column>
                    {errors.howtoapply && (
                        <Grid.Column width={5}>
                            <Label basic color="red" pointing="left">
                                {errors.howtoapply}
                            </Label>
                        </Grid.Column>
                    )}
                </Grid.Row>
                <Grid.Row style={{ paddingTop: 5 }}>
                    <Grid.Column width={3} textAlign="right">
                        <label>Choose mode:</label>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Radio
                            name='hardlink'
                            label='Copy template to node'
                            value='copy'
                            checked={hardlink === 'copy'}
                            onChange={() => setHardlink("copy")}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Radio
                            name='hardlink'
                            label='Hard link template to node'
                            value='link'
                            checked={hardlink === 'link'}
                            onChange={() => setHardlink("link")}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ paddingLeft: '1rem' }}>
                    <Grid.Column width={6}>
                        <Form.Field align="left">
                            <Button size="small" primary onClick={submitForm}>
                                Save
                            </Button>
                            <Button size="small" type="button" onClick={props.dismissForm}>
                                Cancel
                            </Button>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
}

export default AssignTemplateForm;
