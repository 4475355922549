import { atomWithReducer } from 'jotai/utils';

export const autoProvisionActions = {
    ToggleSegment: 'toggle-segment',
    AddNewKeyword: 'add-new-keyword',
    DeleteKeyword: 'delete-keyword',
    UpdateData: 'update-data',
    CloseForm: 'close-form'
}

const INITIAL_STATE = {
    selectedKeyword: '',
    showType: '',
}

const autoProvisionReducer = (prevState, { type, value }) => {
    switch (type) {
        case autoProvisionActions.AddNewKeyword:
            return {
                ...prevState,
                selectedKeyword: '',
                showType: autoProvisionActions.AddNewKeyword,
            };
        case autoProvisionActions.DeleteKeyword:
            return {
                ...prevState,
                selectedKeyword: value,
                showType: autoProvisionActions.DeleteKeyword,
            };
        case autoProvisionActions.ToggleSegment:
            return {
                ...prevState,
                selectedKeyword: '',
                showType: prevState.showType ? '' : autoProvisionActions.ToggleSegment,
            };
        case autoProvisionActions.UpdateData:
            return {
                ...prevState,
                selectedKeyword: '',
                showType: prevState.showType ? autoProvisionActions.ToggleSegment : '',
            };
        case autoProvisionActions.CloseForm:
            return {
                ...prevState,
                selectedKeyword: '',
                showType: autoProvisionActions.ToggleSegment,
            };
        default:
            return INITIAL_STATE

    }
}

export const autoProvisionAtom = atomWithReducer(INITIAL_STATE, autoProvisionReducer);