import { useEffect, useState, Fragment } from "react";
import { useSetAtom } from "jotai";
import { Controller, useForm } from "react-hook-form";
import { Form, Table } from "semantic-ui-react";
import { useFetchGlobalConfigValue } from "../../hooks/useFetchGlobalConfigValue";
import { addGlobalMessageAtom } from "../../store/globalMessage";
import NMService from "../../services/nm.service";
import CustomHeader from "../../components/CustomHeader";

const DefaultColumnsConfig = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [showSegment, toggleSegment] = useState(false);

    const {
        handleSubmit,
        control,
        reset,
    } = useForm();

    const {
        data: defaultColumns,
        isLoading: defaultColumnsIsLoading,
        isFetching: defaultColumnsIsFetching
    } = useFetchGlobalConfigValue({
        type: "nodemanager.nodetable.defaultcolumns",
        select: e => e.filter(x => x.isEditable)
    })

    const onSubmit = async (values) => {
        const formattedValues = defaultColumns.map(e => {
            if (e.isEditable) {
                e.isChecked = values[e.id];
            }
            return e 
        })

        const defaultColumnsData = {
            name: "nodemanager.nodetable.defaultcolumns",
            objValue: JSON.stringify(formattedValues),
        };
        await NMService.updateGlobalConfigValue(defaultColumnsData).then(() =>
            addGlobalMessage({
                header: "Default columns data is updated",
                content: "Default columns data is successfully updated",
                type: "positive",
            })
        ).catch(e => null);
    }

    useEffect(() => {
        if (!defaultColumnsIsLoading && !defaultColumnsIsFetching) {
            const formattedDefaultColumns = {};
            defaultColumns.forEach(e => {
                                    formattedDefaultColumns[e.id] = e.isChecked;
                                });
            reset(formattedDefaultColumns)
        }
    }, [
        reset,
        defaultColumns,
        defaultColumnsIsLoading,
        defaultColumnsIsFetching
    ]);

    if (defaultColumnsIsLoading) {
        return null;
    }

    return (
        <>
            <CustomHeader
                title='Nodes table default columns'
                toggle={() => toggleSegment(p => !p)}
                show={showSegment}
            />
            <Form
                className="basic segment"
                hidden={!showSegment}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Table celled compact>
                    <Table.Body>
                        <Table.Row>
                            {defaultColumns.map(({ id, title }) =>
                                <Table.Cell key={id}>
                                    <Controller
                                        name={id}
                                        control={control}
                                        defaultValue={false}
                                        render={({ field }) => (
                                            <Form.Checkbox
                                                label={title}
                                                onChange={(_, { checked }) => field.onChange(checked)}
                                                checked={field.value}
                                            />
                                        )}
                                    />
                                </Table.Cell>
                            )}
                        </Table.Row>
                    </Table.Body>
                </Table>
                <Form.Button content="Update" size="small" primary />
            </Form>
        </>
    );
}

export default DefaultColumnsConfig;
