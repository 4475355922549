import React, { useEffect, useMemo, useReducer } from 'react';
import { Header } from 'semantic-ui-react';
import Namespace from './Namespace';

const reducer = (state, action) => {
    switch (action.type) {
        case 'update':
            return { ...state, ...action.payload };
        default:
            throw new Error();
    }
};

export default function Namespaces(props) {
    const data = useMemo(() => Object.keys(props.data || {}).length === 0 ? {interfaces: [], routes: [], namespaces: []} : props.data, [props.data]);
    const [state, dispatch] = useReducer(reducer, {
        explicitNamespaces: [],
        implicitNamespaces: [],
        defaultNamespace: {},
    });

    useEffect(() => {
        if (data) {
            let explicitNamespaces = data?.namespaces?.map((e) => ({
                ...e,
                ...(e.name && e.name !== 'default' && { name: e.name }),
                type: 'explicit',
                interfaces: data.interfaces.filter((intf) => intf.namespace === e.name),
                routes: data.routes.filter((route) => route.namespace === e.name),
            }));

            let implicitIntfNamespaces = data.interfaces.reduce((acc, current) => {
                if (
                    current.hasOwnProperty('namespace') &&
                    !data.namespaces.find((e) => e.name === current.namespace)
                ) {
                    if (current.namespace !== "default") {
                        let index = acc.map((e) => e.name).indexOf(current.namespace);
                        if (index === -1) {
                            acc.push({ name: current.namespace, interfaces: [current] });
                        } else {
                            acc[index].interfaces.push(current);
                        }
                        return acc;
                    }
                }
                return acc;
            }, []);

            let implicitRouteNamespaces = data.routes.reduce((acc, current) => {
                if (
                    current.hasOwnProperty('namespace') &&
                    current.namespace !== 'default' &&
                    !data.namespaces.find((e) => e.name === current.namespace)
                ) {
                    let index = acc.map((e) => e.name).indexOf(current.namespace);
                    if (index === -1) {
                        acc.push({ name: current.namespace, routes: [current] });
                    } else {
                        acc[index].routes.push(current);
                    }
                    return acc;
                }
                return acc;
            }, []);

            const implicitNamespaces = []
                .concat(implicitIntfNamespaces, implicitRouteNamespaces)
                .reduce((acc, current) => {
                    let index = acc.map((e) => e.name).indexOf(current.name);
                    if (index === -1) {
                        acc.push({ ...current, type: 'implicit' });
                    } else {
                        acc[index] = { ...acc[index], ...current, type: 'implicit' };
                    }
                    return acc.filter(e => e.name !== "");
                }, []);

            const defaultNamespace = {
                name: 'default',
                type: 'default',
                interfaces: data.interfaces.filter(
                    (e) => !e.hasOwnProperty('name') || !e.namespace || e.namespace === 'default'
                ),
                routes: data.routes.filter(
                    (e) => !e.hasOwnProperty('namespace') || !e.namespace || e.namespace === 'default'
                ),
            };

            dispatch({ type: 'update', payload: { explicitNamespaces, implicitNamespaces, defaultNamespace } });
        }
    }, [data]);

    return (
        <React.Fragment>
            <Header as="h5" style={{ marginTop: '1rem' }}>Namespaces:</Header>
            {state.defaultNamespace && (
                <div key={`default_namespace`} className={'moduleGridDiv'}>
                    <Namespace
                        existingIntf={props.data.interfaces?.map(e => e.name) || []}
                        all={state.explicitNamespaces}
                        data={state.defaultNamespace}
                        nodeId={props.nodeId}
                        interfaces={props.interfaces}
                        updateNamespacesConfig={props.updateNamespacesConfig}
                    />
                </div>
            )}

            {state.explicitNamespaces
                .sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1
                                    : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
                )
                .map((e, index) => (
                    <div key={`explicit_defined_namespace_${e.name}_${index}`} className={'moduleGridDiv'}>
                        <Namespace
                            all={state.explicitNamespaces}
                            data={e}
                            nodeId={props.nodeId}
                            interfaces={props.interfaces}
                            updateNamespacesConfig={props.updateNamespacesConfig}
                        />
                    </div>
                ))}

            {state.implicitNamespaces
                .sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1
                                    : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
                )
                .map((e, index) => (
                    <div key={`implicit_defined_namespace_${e.name}_${index}`} className={'moduleGridDiv'}>
                        <Namespace
                            all={state.explicitNamespaces}
                            data={e}
                            nodeId={props.nodeId}
                            interfaces={props.interfaces}
                            updateNamespacesConfig={props.updateNamespacesConfig}
                        />
                    </div>
                ))}
        </React.Fragment>
    );
}
