import { Suspense } from "react";
import { useLoaderData, useOutlet, Await } from "react-router-dom";
import { Loader, Dimmer } from 'semantic-ui-react';
import { AuthProvider } from "../hooks/useAuth";

export const AuthLayout = () => {
    const outlet = useOutlet();
    const { userPromise } = useLoaderData();

    return (
        <Suspense
            fallback={
                <Dimmer active inverted>
                    <Loader>Loading...</Loader>
                </Dimmer>
            }
        >
            <Await
                resolve={userPromise}
                children={(user) => (
                    <AuthProvider userData={user}>{outlet}</AuthProvider>
                )}
            />
        </Suspense>
    );
};
