import React, { useEffect, useMemo, useState } from "react";
import DestinationTable from "./DestinationTable";
import nmService from "../../../services/nm.service";
import FileUpload from "./FileUpload";
import { Breadcrumb, Divider, Loader, Menu, Segment } from "semantic-ui-react";
import { addGlobalMessageAtom } from "../../../store/globalMessage";
import { useSetAtom } from "jotai";
import NMFieldValidator from "../../../utils/NMFieldValidator";

const Destinations = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false)
    const [dbContent, setDbContent] = useState([]);
    const [fileContent, setFileContent] = useState([]);
    const [error, setError] = useState("");
    const [errorRows, setErrorRows] = useState([]);

    const destinationValidation = {
        "region": (v) => NMFieldValidator.validateString(v, /^[a-z][a-z0-9_-]{1,7}$/, 1, 8, false),
        "devicetype": (v) => NMFieldValidator.validateString(v, /^[a-z][a-z0-9_-]{1,31}$/, 1, 32, false),
        "devicerole": (v) => NMFieldValidator.validateString(v, /^[a-z][a-z0-9_-]{1,31}$/, 1, 32, false),
        "devicegroup": (v) => NMFieldValidator.validateString(v, /^[a-z][a-z0-9_-]{1,31}$/, 1, 32, false),
        "name": (v) => NMFieldValidator.validateString(v, /^[a-z][a-z0-9_-]{1,63}$/, 1, 64, true),
        "protocol": (v) => NMFieldValidator.validateString(v, /^[a-z][a-z0-9_-]{1,15}$/, 1, 16, false),
        "address": (v) => NMFieldValidator.validateString(v, /^[a-zA-Z0-9][A-Za-z0-9.:_-]{1,127}$/, 1, 128, true),
        "port": (v) => !!!v ? null : NMFieldValidator.validateInt(v, 1, 65535)
    }

    const columns = useMemo(
        () => [
            {
                id: "region",
                Header: "Region",
                accessor: d => d.region || "",
            },
            {
                id: "location",
                Header: "Location",
                accessor: d => d.location || "",
            },
            {
                id: "devicetype",
                Header: "Device Type",
                accessor: d => d.devicetype || "",
            },
            {
                id: "devicerole",
                Header: "Device Role",
                accessor: d => d.devicerole || "",
            },
            {
                id: "devicegroup",
                Header: "Device Group",
                accessor: d => d.devicegroup || "",
            },
            {
                id: "name",
                Header: "Name",
                accessor: "name",
            },
            {
                id: "protocol",
                Header: "Protocol",
                accessor: d => d.protocol || "",
            },
            {
                id: "address",
                Header: "Destination",
                accessor: "address",
            },
            {
                id: "port",
                Header: "Port",
                accessor: d => d.port || "",
            },
        ],
        []
    );

    const fetchDestinations = (filter = "") =>
        nmService
            .getObjectServiceDestinations(filter)
            .then((response) => setDbContent(response.data))
            .catch(e => []);

    const importToDatabase = () => {
        setLoading(true)
        nmService.postObjectServiceDestinationsList(
                fileContent.map((e) => {
                    delete e.uuid;
                    for (const key in e) {
                        if (!e[key]) {
                            delete e[key];
                        }
                    }
                    return e;
                })
            ).then((r) =>
                addGlobalMessage({
                    header: "Successfully imported destinations",
                    content: "Successfully imported destinations",
                    type: "positive",
                }))
            .then(r => {
                fetchDestinations();
                setFileContent([]);
            })
            .catch(e => null)
            .finally((r) => setLoading(false));
    };

    const addEntry = (entry, type) => {
        if (type === "db") {
            nmService.addObjectServiceDestination(entry).then((r) => 
                    addGlobalMessage({
                        header: `Successfully ${entry.uuid ? "updated" : "added new"} destination`,
                        content: `Successfully ${entry.uuid ? "updated" : "added new"} destination`,
                        type: "positive",
                    }))
                    .catch(e => null)
                    .finally(() => fetchDestinations());
        } else {
            let newData = [...fileContent]
            newData[fileContent.findIndex(e => e.uuid === entry.uuid)] = entry;
            setValidatedFileContent(newData)
        }
    }

    const deleteEntry = (uuid, type) => {
        if (type === "db") {
            nmService.deleteObjectServiceDestination(uuid).then((r) => 
                    addGlobalMessage({
                        header: "Successfully deleted destination",
                        content: "Successfully deleted destination",
                        type: "positive",
                    }))
                .catch(e => null)
                .finally(() => fetchDestinations());
        } else {
            setValidatedFileContent(fileContent.filter((e) => e.uuid !== uuid))
        }
    };

    const setValidatedFileContent = (content) => {
        let error = ""
        let errRows = []
        content.forEach((e,k) => {
            const fns = Object.keys(destinationValidation);
            let found = false;
            for (const key of fns) {
                const v = destinationValidation[key](e[key])
                if (v) {
                    if (!found) {
                        error += `Value at index ${k+1} is invalid:\n`
                        found = true
                    }
                    errRows.push(k)
                    error += `\t${e[key]} - ${v}\n`;
                }
            }
            if (found) error += "\n"
        })
        setError(error)
        setErrorRows(errRows)
        setFileContent(content)
    }

    useEffect(() => {
        fetchDestinations();
    }, []);

    return (
        <>
            <Breadcrumb style={{ marginTop: "1rem" }}>
                <Breadcrumb.Section>Object service</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section active>Destinations</Breadcrumb.Section>
            </Breadcrumb>
            <Divider />
            <Menu pointing secondary widths={2}>
                <Menu.Item
                    link
                    active={open}
                    content="Database content"
                    onClick={() => setOpen(true)}
                />
                <Menu.Item
                    link
                    active={!open}
                    content="Destinations upload"
                    onClick={() => setOpen(false)}
                />
            </Menu>
            {open ? (
                <DestinationTable
                    data={dbContent}
                    type="db"
                    columns={columns}
                    delete={deleteEntry}
                    addEntry={addEntry}
                    fetch={fetchDestinations}
                    validation={destinationValidation}
                />
            ) : (
                <div style={loading ? { pointerEvents: 'none', position: 'relative' } : {}}>
                    <Loader active={loading} size='big' inline='centered' style={{ position: 'absolute', top: '50%', left: '50%' }}/>
                    <FileUpload setFileContent={setValidatedFileContent} columns={columns} />
                    {!!error ? <Segment vertical style={{ color: 'red', whiteSpace: 'pre', maxHeight: '500px', overflow: 'auto', marginBottom: '1rem' }}>{error}</Segment> : null}
                    {fileContent.length !== 0 && (
                        <DestinationTable
                            key={fileContent.length}
                            data={fileContent}
                            type="file"
                            columns={columns}
                            addEntry={addEntry}
                            delete={deleteEntry}
                            import={importToDatabase}
                            validation={destinationValidation}
                            error={!!error}
                            errorRows={errorRows}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default Destinations;
