import React, { useEffect, useMemo, useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import NMService from "../../services/nm.service";
import SWModuleUpload from "./SWModuleUpload";
import { addGlobalMessageAtom } from "../../store/globalMessage";
import { useSetAtom } from "jotai";
import { useUserInfo } from "../../hooks/useAuth";
import { addSwModulePermissionAtom } from "../../store/swModule";
import { getPermission } from "../../helpers/userPermission";
import { PermissionsGateV } from "../../layouts/PermissionGate/PermissionGateV";
import { useFetchSwModules } from "../../hooks/useFetchSwModules";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import { useGetGlobalPermission } from "../../hooks/useGetGlobalPermission";
import { ADMIN } from "../../constants/layout";
import CustomTable from "../../components/CustomTable";

const SWModule = () => {
    const hasPermission = useGetGlobalPermission(ADMIN);
    const { userdata: { role: userRole } } = useUserInfo();
    const setSwModulePermission = useSetAtom(addSwModulePermissionAtom);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [deleteModal, setDeleteModal] = useState(undefined);
    const [showFavourites, setShowFavourites] = useState(false);
    const [filterData, setFilterData] = useState([])

    const {
        data,
        isLoading,
        isFetching,
        refetch
    } = useFetchSwModules({
        options: {
            refetchOnMount: 'always',
        }
    });

    const columns = useMemo(
        () => [
            {
                id: "id",
                Header: "ID",
                accessor: (d) => <Link to={"/module/" + d.id + "/" + d.version}>{d.id}</Link>,
                width: 3,
            },
            {
                id: "id_hidden",
                Header: "ID_hidden",
                accessor: "id",
            },
            {
                id: "version",
                Header: "Version",
                accessor: "version",
                width: 2,
            },
            {
                id: "description",
                Header: "Description",
                accessor: "description",
                width: 9,
            },
        ],
        []
    );

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: "favourites",
                Cell: ({ row }) =>
                    <div style={{ float: 'right' }}>
                        <PermissionsGateV hasPermission={hasPermission}>
                            <Icon
                                style={{ color: row.original.favourite ? '#4183C4' : 'gray', marginRight: '1rem' }}
                                name={`star${row.original.favourite ? '' : ' outline'}`}
                                size="big"
                                link
                                bordered={false}
                                onClick={() => 
                                    NMService.setModuleFavouite({
                                        "moduleid" : row.original.id,
                                        "version" : row.original.version,
                                        "favourite" : !row.original.favourite
                                    })
                                    .catch(e => null)
                                    .finally(() => refetch())
                                }
                            />
                        </PermissionsGateV>
                    </div>,
                setFilter: () => null,
                width: 1,
            },
            {
                Header: () => null,
                id: "actions",
                Cell: ({ row }) => (
                    <PermissionsGateV hasPermission={hasPermission}>
                        <Button
                            circular
                            type="button"
                            icon="trash"
                            onClick={() => setDeleteModal(row.original)}
                        />
                    </PermissionsGateV>
                ),
                disableGlobalFilter: true,
                disableFilters: true,
                width: 1,
            },
        ]);
    }

    const deleteSWModule = () => NMService.deleteSWModule(deleteModal.id, deleteModal.version).then(() => 
                                    addGlobalMessage({
                                        header: "Software module deleted successfully",
                                        content: `Software module ${deleteModal.id} v${deleteModal.version} deleted successfully`,
                                        type: "positive",
                                    }))
                                .catch(e => null)
                                .finally(() => {
                                    refetch();
                                    setDeleteModal(undefined);
                                })

    useEffect(() => {
        const hasPermission = getPermission({ userRole, requiredRole: 'nodeadmin' });
        setSwModulePermission({ hasPermission });
    }, [setSwModulePermission, userRole]);

    useEffect(() => {
        if (!isFetching && !isLoading)
            setFilterData(showFavourites ? data.filter(e => e.favourite) : data)
    }, [showFavourites, isFetching, isLoading]) // eslint-disable-line

    return isLoading ? null : (
        <>
            <CustomTable
                data={filterData}
                columns={columns}
                hiddenColumns={["id_hidden"]}
                notCelled={true}
                additional={{
                    toggle: true,
                    label: 'Only show favourites',
                    checked: showFavourites,
                    onClick: () => setShowFavourites(p => !p)}
                }
                tableHooks={tableHooks}
                footerButton={() =>
                    <SWModuleUpload refresh={refetch} />
                }
            />
            <ConfirmationModal
                open={deleteModal}
                header="Delete software module"
                content={`Are you sure you want to delete software module: ${deleteModal?.id} ${deleteModal?.version}?`}
                onConfirm={() => deleteSWModule()}
                onDismiss={() => setDeleteModal(undefined)}
            />
        </>
    );
};
export default SWModule;
