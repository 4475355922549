import { useSetAtom } from 'jotai';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Header } from 'semantic-ui-react';
import NMService from '../../services/nm.service';
import { addGlobalMessageAtom } from '../../store/globalMessage';
import { useForm } from 'react-hook-form';
import { Fragment } from 'react';
import { useFormFields } from '../../hooks/useFormFields';

const snapshotFormSchema = yup.object().shape({
    name: yup.string().trim()
        .required("Name is required.")
        .min(2, "Name length should be at least 2 characters.")
        .max(128, "Name cannot exceed more than 128 characters."),
    description: yup.string().trim()
        .required("Description is required.")
        .min(2, "Description length should be at least 2 characters.")
        .max(255, "Description cannot exceed more than 255 characters.")
})

function CreateSnapshot(props) {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);

    const {
        handleSubmit,
        register,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(snapshotFormSchema),
        defaultValues: {
            name: "",
            description: "",
        }
    });
    const { renderInput } = useFormFields({ register, errors, setValue, watch });

    const onSubmit = async (values) => {
        await NMService.saveNodeSnapshot(values).then(() => {
            props.updateData();
            addGlobalMessage({
                header: 'Snapshot created successfully!',
                content: `Snapshot created successfully.`,
                type: 'positive',
            })
        }).catch(e => null);
    };

    return (
        <Fragment>
            <Header dividing as="h4">Create new snapshot</Header>
            <Form className='basic segment' onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    {renderInput('Name', 'name')}
                    {renderInput('Description', 'description', { width: 8 })}
                </Form.Group>
                <Form.Group>
                    <Form.Button primary size="tiny" content="Create"/>
                    <Form.Button size="tiny" type="button" content="Cancel" onClick={() => props.hideForm()}/>
                </Form.Group>
            </Form>
        </Fragment>
    );
}

export default CreateSnapshot;