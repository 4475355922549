import React, {useMemo } from "react";
import { Dropdown } from "semantic-ui-react";

const MultiSelectColumnFilter = ({ column }) => {
    const { filterValue, setFilter, preFilteredRows, Header, id } = column;

    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => options.add(row.values[id]));
        return [...options.values()].sort();
    }, [id, preFilteredRows]);

    const dropdonwOptions = [].concat.apply([], options).map((option) => ({
        key: option,
        text: option,
        value: String(option),
    }));

    return (
        <>
            <label>{Header}</label>
            <Dropdown
                id={`filter-by-${id}`}
                multiple
                fluid
                search
                clearable
                selection
                style={{ width: 'fit-content', minWidth: '300px', fontSize: '0.9em' }}
                value={filterValue || []}
                onChange={(_, { value }) => setFilter(value && value.length ? value : undefined)}
                options={dropdonwOptions}
            />
        </>
    );
};

export default MultiSelectColumnFilter;
