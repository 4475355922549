import { useQuery } from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchLocalUser = ({
    user,
    select,
    options
} = {}) => {
    const query = useQuery({
        queryKey: ['getLocalUser', user],
        queryFn: () => 
            NMService.getLocalUser(user)
                .then(r => r.data)
                .catch(e => {}),
        enabled: !!user,
        staleTime: Infinity,
        select,
        ...options
    })
    return { ...query, data: query.data ?? {} }
}