import { useQuery } from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchTotalLicenses = ({ 
    select, 
    options 
} = {}) => {
    const query = useQuery({
        queryKey: ['getTotalLicenses'],
        queryFn: () =>
            NMService.getTotalLicenses()
                .then(r => r.data)
                .catch(e => {}),
        placeholderData: (data) => data ?? {},
        staleTime: Infinity,
        select,
        ...options
    })
    return { ...query, data: query.data ?? {} }
}