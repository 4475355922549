import { useForm } from "react-hook-form";
import { useFormFields } from "../../../../hooks/useFormFields";
import { Form, FormGroup } from "semantic-ui-react";
import { useEffect, useMemo, useState } from "react";
import CustomHeader from "../../../../components/CustomHeader";

export const EmailNotificationForm = (props) => {
    const severityList = useMemo(() => props.severityList || [], [props.severityList])
    const [showTemplate, setShowTemplate] = useState(false)
    const templateKeys = [
        [["alarm", "Measurements alarm trigger body"], ["clear", "Measurements alarm clear body"]],
        [["alarm-subject", "Alarm trigger subject"], ["clear-subject", "Alarm clear subject"]],
        [["alarmprobe", "Probe alarm trigger body"], ["clearprobe", "Probe alarm clear body"]],
        [["alarmprobe-subject", "Probe alarm trigger subject"], ["clearprobe-subject", "Probe alarm clear subject"]],
        [["alarmsystem", "System alarm trigger body"], ["clearsystem", "System alarm clear body"]],
        [["alarmsystem-subject", "System alarm trigger subject"], ["clearsystem-subject", "System alarm clear subject"]],
    ];
    const sslOptions = [
        { key: "disabled", value: "disabled", text: "disabled" },
        { key: "smtps", value: "smtps", text: "SMTPS" },
        // { key: "starttls", value: "starttls", text: "STARTTLS" },
    ]
    const defaultConfig = {
        "smtp-server-port": 25,
        "smtp-server-address": "localhost",
        "smtp-server-ssl": "disabled",
        "mail-from": "",
        "mail-destination-list": "",
        ...Object.entries(props.template)
                        .map((e) => ({ [e[0].replaceAll(".", "-")]: e[1] }))
                        .reduce((e, k) => ({ ...e, ...k }), {}),
    };

    const {
        register,
        setValue,
        reset,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: defaultConfig });

    const { renderInput, renderDropdown } = useFormFields({ register, errors, setValue, watch });

    useEffect(() => {
        if (props.data) {
            reset({
                ...Object.entries(props.data.parameters)
                        .map((e) => ({ [e[0].replaceAll(".", "-")]: e[1] }))
                        .reduce((e, k) => ({ ...e, ...k }), {}),
                name: props.data.name,
                ...Object.entries(props.data.template)
                                .map((e) => ({ [e[0].replaceAll(".", "-")]: e[1] }))
                                .reduce((e, k) => ({ ...e, ...k }), {}),
            });
        }
    }, [reset, props.data]);

    return (
        <Form onSubmit={handleSubmit(props.post)}>
            <FormGroup widths={4}>{renderInput("Name", "name")}</FormGroup>
            <FormGroup widths={4}>
                {renderInput("SMTP server address", "smtp-server-address")}
                {renderInput("SMTP server port", "smtp-server-port", { inputType: "number" })}
                {renderDropdown("SMTP server SSL", "smtp-server-ssl", sslOptions)}
            </FormGroup>
            <Form.Group>
                {renderInput("From", "mail-from", { width: 4 })}
                {renderInput("Destination list", "mail-destination-list", { width: 8, infoText: "Recipient e-mails separated by commas ( , )" })}
            </Form.Group>
            <Form.Group widths={4}>
                {renderDropdown("Severity filter", "severity-filter", severityList, { width: 8, multiple: true, notRequired: true, infoText: "Select which alarm severity should trigger notification sending via this channel (leave empty for no filter)" })}
            </Form.Group>
            <CustomHeader
                title='Template'
                toggle={() => setShowTemplate(p => !p)}
                show={showTemplate}
            />
            {showTemplate && templateKeys.map(e =>
                    <Form.Group key={e[0][0]}>
                        {renderInput(e[0][1], e[0][0], { width: 11, inputType: 'textarea', rows: 7, mono: true })}
                        {renderInput(e[1][1], e[1][0], { width: 5, inputType: 'textarea', rows: 7, mono: true })}
                    </Form.Group>
                )}
            <Form.Group style={{ marginTop: '2rem' }}>
                <Form.Button primary type="submit" content={props.data?.id ? "Update" : "Add"} />
                <Form.Button type="button" content="Cancel" onClick={props.close} />
            </Form.Group>
        </Form>
    );
};
