import { useEffect, useMemo, useRef } from 'react';
import { Form, Grid, Button, Dropdown, Checkbox } from 'semantic-ui-react';
import { Controller, useForm } from 'react-hook-form';
import NMService from '../../../services/nm.service';
import { PermissionsGateV } from '../../../layouts/PermissionGate/PermissionGateV';
import { useFetchGroups } from '../../../hooks/useFetchGroups';
import { useFetchProbeModule } from '../../../hooks/useFetchProbeModule';

const Permissions = (props) => {
    const {
        register,
        reset,
        handleSubmit,
        watch,
        control,
        setValue,
    } = useForm();
    const managedByWatch = watch('permissions.managedby')
    const { nodeid, moduleid, instanceid } = useMemo(() => props.data, [props.data]);
    const initialDataRef = useRef();

    const {
        data: groupList,
        isLoading: groupListIsLoading
    } = useFetchGroups({});

    const {
        data: nodeModuleData,
        isLoading: nodeModuleDataIsLoading
    } = useFetchProbeModule({
        probeid: nodeid,
        moduleid,
        instanceid
    });

    const getGroupOptions = () => {
        if (groupListIsLoading) return [];

        return groupList.map((group) => ({
            key: `${group.name}_${group.description}`,
            value: `${group.name}`,
            text: `${group.name} -  ${group.description}`,
        }));
    };

    const onSubmit = async (values) => {
        let data = { nodeid, moduleid, instanceid, ...values };

        await NMService.updateNodeModulePermissions(data).then(() => {
            props.onFormSubmitted();
            initialDataRef.current = values;
        }).catch(e => null)
    };

    useEffect(() => {
        if (!nodeModuleDataIsLoading) {
            const { group, permissions } = nodeModuleData;
            initialDataRef.current = { group, permissions };
            reset(initialDataRef.current)
        }
    }, [nodeModuleData, nodeModuleDataIsLoading, reset]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: '1rem' }}>
            <Grid>
                <Grid.Row style={{ paddingTop: 5 }} verticalAlign="middle">
                    <Grid.Column width={2}>
                        <label align="right">Assign to a group</label>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Form.Field align="left">
                            <Controller
                                name="group"
                                control={control}
                                render={({ field: { value, ...rest } }) => (
                                    <Dropdown
                                        {...rest}
                                        fluid
                                        selection
                                        multiple
                                        placeholder="Select group"
                                        value={value || []}
                                        onChange={(_, { value }) => rest.onChange(value)}
                                        options={getGroupOptions()}
                                    />
                                )}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingTop: 5 }}>
                    <Grid.Column width={2}>Managed by</Grid.Column>
                    <Grid.Column width={2}>
                        <Form.Field align="left">
                            <Checkbox
                                {...register("permissions.managedby")}
                                radio
                                label="owner"
                                value="owner"
                                checked={managedByWatch === 'owner'}
                                onChange={(_, { value, name }) => setValue(name, value)}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <Form.Field align="left">
                            <Checkbox
                                {...register("permissions.managedby")}
                                radio
                                label="group admin"
                                value="groupadmin"
                                checked={managedByWatch === 'groupadmin'}
                                onChange={(_, { value, name }) => setValue(name, value)}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <Form.Field align="left">
                            <Checkbox
                                {...register("permissions.managedby")}
                                radio
                                label="members"
                                value="groupall"
                                checked={managedByWatch === 'groupall'}
                                onChange={(_, { value, name }) => setValue(name, value)}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <Form.Field align="left">
                            <Checkbox
                                {...register("permissions.managedby")}
                                radio
                                label="anyone"
                                value="any"
                                checked={managedByWatch === 'any'}
                                onChange={(_, { value, name }) => setValue(name, value)}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: '0.5rem' }}>
                    <Grid.Column width={6}>
                        <Form.Field align="left">
                            <PermissionsGateV hasPermission={props.hasPermission}>
                                <Button size="small" type="submit" primary>
                                    Update
                                </Button>
                            </PermissionsGateV>
                            <PermissionsGateV hasPermission={props.hasPermission}>
                                <Button size="small" type="button" onClick={reset.bind(null, initialDataRef.current)}>
                                    Reset
                                </Button>
                            </PermissionsGateV>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Permissions;