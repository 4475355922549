import { Grid, Form, Header, Dropdown, Checkbox } from 'semantic-ui-react';
import { Controller, useForm } from 'react-hook-form';
import NMService from '../../../services/nm.service';
import ModuleBoxLayout from '../../../layouts/ModuleBox/ModuleBox';
import { useAtomValue, useSetAtom } from 'jotai';
import { PermissionsGateV } from '../../../layouts/PermissionGate/PermissionGateV';
import { addGlobalMessageAtom } from '../../../store/globalMessage';
import { useFetchTemplates } from '../../../hooks/useFetchTemplates';
import { editProbeAtom } from '../../../store/editProbe';
import { useFetchProbeModuleBundle } from '../../../hooks/useFetchProbeModuleBundle';
import { useState } from 'react';

const TemplateToModules = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const probeAtomState = useAtomValue(editProbeAtom);
    const [loading, setLoading] = useState(false)

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        getValues,
        formState: { errors }
    } = useForm({ defaultValues: { modetype: 'copy' } });

    const applyMethodOptions = [
        { key: 'replace', value: 'replace', text: 'delete existing modules' },
        {
            key: 'mergekeep',
            value: 'mergekeep',
            text: 'merge and keep config of existing modules',
        },
        {
            key: 'mergeoverwrite',
            value: 'mergeoverwrite',
            text: 'merge and overwrite config of existing modules',
        },
    ];

    const {
        refetch: refetchModuleBundle
    } = useFetchProbeModuleBundle({ probeid: props.probeid });

    const {
        data: templateList,
        isLoading: templateListIsLoading,
        isFetching: templateListIsFetching
    } = useFetchTemplates({
        select: e => e.map(({ id, name, description }) => ({
                            key: `${id}_${name}`,
                            value: `${id}`,
                            text: `${id} ${name}  -  ${description}`,
                        }))
    });

    const onSubmit = (values) => {
        if (!props.probeid) return;
        const { modetype, ...rest } = values;

        setLoading(true)
        let data = {
            nodeid: String(props.probeid),
            ...rest,
            hardlink: modetype
        }

        NMService.assignTemplateToNode(data).then(() => 
                    addGlobalMessage({
                        header: 'Template assign!',
                        content: 'Template was successfully assigned to node.',
                        type: 'positive',
                    }))
                .catch(e => null)
                .finally(() => {
                    setLoading(false)
                    props.closeForm()
                    refetchModuleBundle()
                })
    }

    return templateListIsLoading || templateListIsFetching ? null : (
        <ModuleBoxLayout>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Grid padded verticalAlign="middle">
                    <Header as="h4" style={{ margin: '1rem 0' }}>
                        Assign template to node
                    </Header>
                    <Grid.Row style={{ paddingTop: 5 }}>
                        <Grid.Column width={3} textAlign="right">
                            <label>Template to assign</label>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Form.Field error={Boolean(errors.templateid?.type)}>
                                <Controller
                                    name="templateid"
                                    control={control}
                                    defaultValue={getValues("templateid")}
                                    rules={{ required: true }}
                                    render={({ field, ref }) => (
                                        <Dropdown
                                            {...field}
                                            ref={ref}
                                            fluid
                                            selection
                                            placeholder="Select template"
                                            onChange={(_, { value }) => field.onChange(value)}
                                            options={templateList}
                                        />
                                    )}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: 5 }}>
                        <Grid.Column width={3} textAlign="right">
                            <label align="right">Existing modules</label>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Form.Field error={Boolean(errors.howtoapply?.type)}>
                                <Controller
                                    name="howtoapply"
                                    control={control}
                                    defaultValue={getValues("howtoapply")}
                                    rules={{ required: true }}
                                    render={({ field, ref }) => (
                                        <Dropdown
                                            {...field}
                                            ref={ref}
                                            fluid
                                            selection
                                            placeholder="Select method"
                                            onChange={(_, { value }) => field.onChange(value)}
                                            options={applyMethodOptions}
                                        />
                                    )}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: 5 }}>
                        <Grid.Column width={3} textAlign="right">
                            <label>Choose mode:</label>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Checkbox
                                {...register("modetype")}
                                radio
                                label="Copy template to node"
                                value="copy"
                                checked={watch("modetype") === 'copy'}
                                onChange={(_, { value, name }) => setValue(name, value)}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Checkbox
                                {...register("modetype")}
                                radio
                                label="Hard link template to node"
                                value="link"
                                checked={watch("modetype") === 'link'}
                                onChange={(_, { value, name }) => setValue(name, value)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingLeft: '1rem' }}>
                        <Grid.Column width={6}>
                            <Form.Group>
                                <PermissionsGateV hasPermission={probeAtomState?.permissions?.assignmodules}>
                                    <Form.Button
                                        loading={loading}
                                        size="small"
                                        type="submit"
                                        content='Apply'
                                        primary
                                    />
                                </PermissionsGateV>
                                <PermissionsGateV hasPermission={probeAtomState?.permissions?.assignmodules}>
                                    <Form.Button
                                        size="small"
                                        type="button"
                                        content='Cancel'
                                        onClick={props.closeForm}
                                    />
                                </PermissionsGateV>
                            </Form.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </ModuleBoxLayout>
    );

}

export default TemplateToModules;
