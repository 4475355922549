import { useQuery } from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchSnmpTemplate = ({ 
    select,
    options 
} = {}) => {
    const query = useQuery({
        queryKey: ['getSnmpTemplate'],
        queryFn: () =>
            NMService.getSnmpTemplate()
                .then(r => r.data)
                .catch(e => []),
        select,
        ...options
    })
    return { ...query, data: query.data ?? [] }
}