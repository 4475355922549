export default class NMFieldValidator {
    static validateFloat(value, min, max, mandatory = false) {
        if (value === '' && mandatory) {
            return 'Field is mandatory';
        }
        if (isNaN(value)) {
            return 'Value is not valid number';
        }

        value = parseFloat(value);

        if (min !== undefined && value < min) {
            return 'Value must be greater than ' + min;
        }
        if (max !== undefined && value > max) {
            return 'Value must be less than ' + max;
        }
        return null;
    }

    static validateInt(value, min, max, mandatory = false) {
        if (value === '' && mandatory) {
            return 'Field is mandatory';
        }
        if (isNaN(value)) {
            return 'Value is not valid number';
        }

        value = parseInt(value);
        if (!Number.isInteger(value)) {
            return 'value is not valid integer';
        }

        if (min !== undefined && value < min) {
            return 'Value must be greater than ' + min;
        }
        if (max !== undefined && value > max) {
            return 'Value must be less than ' + max;
        }
        return null;
    }

    static validateString(value, regex, minLen, maxLen, mandatory = false, regexMessage = undefined) {
        if (value === undefined || value === '') {
            if (mandatory) {
                return 'Field is mandatory';
            }
            return null;
        }

        if (minLen !== undefined && value.length < minLen) {
            return 'Value is too short';
        }
        if (maxLen !== undefined && value.length > maxLen) {
            return 'Value is too long';
        }
        if (regex !== undefined) {
            let re = RegExp(regex);
            if (!re.test(value)) {
                if (regexMessage !== undefined) {
                    return regexMessage;
                } else {
                    return 'Invalid value, must match: /' + regex + '/';
                }
            }
        }

        return null;
    }

    static validateLatitude(value, mandatory = false) {
        return NMFieldValidator.validateFloat(value, -90, 90, mandatory);
    }
    static validateLongitude(value, mandatory = false) {
        return NMFieldValidator.validateFloat(value, -180, 180, mandatory);
    }

    static checkErrorObject(errors) {
        if (
            Object.values(errors).reduce((totalErrors, err) => {
                return totalErrors + (!err ? 0 : 1);
            }, 0) > 0
        ) {
            return false;
        }
        return true;
    }

    static validateRandomoffset(value) {
        const reRandomoffset = new RegExp(/^(\d+)$/);
        if (!reRandomoffset.test(value)) {
            return 'Value is not valid integer.';
        }
    
        if (Number(value) < 0 || Number(value) > 3600) {
            return 'Value must be positive integer (range from 0 to 3600).';
        }
        return null;
    };
}
