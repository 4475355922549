import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAtom, useSetAtom } from "jotai";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Header } from "semantic-ui-react";
import { useGetGlobalPermission } from "../../hooks/useGetGlobalPermission";
import { globalSnmpHostGroupActions, globalSnmpHostGroupAtom } from "../../store/globalSnmpHostGroups";
import { ADMIN } from "../../constants/layout";
import { PermissionsGateV } from "../../layouts/PermissionGate/PermissionGateV";
import { useFetchSnmpSecurityProfile } from "../../hooks/useFetchSnmpSecurityProfile";
import { useFetchSnmpTemplate } from "../../hooks/useFetchSnmpTemplate";
import NMService from "../../services/nm.service";
import { useFetchSnmpHostGroup } from "../../hooks/useFetchSnmpHostGroup";
import { useFormFields } from "../../hooks/useFormFields";
import { addGlobalMessageAtom } from "../../store/globalMessage";


const SnmpFormSchema = yup.object().shape({
    name: yup.string()
        .required("Field is required."),
});


const HostGroupForm = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const hasPermission = useGetGlobalPermission(ADMIN);
    const [state, dispatch] = useAtom(globalSnmpHostGroupAtom);
    const { AddHost, EditHost, ClearShowType } = globalSnmpHostGroupActions;

    const methods = useForm({
        resolver: yupResolver(SnmpFormSchema)
    });
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = methods;

    const { renderInput, renderCheckbox, renderDropdown } = useFormFields({ register, errors, setValue, watch });

    const {
        refetch: refetchSnmpHostGroups
    } = useFetchSnmpHostGroup({
        options: {
            refetchOnMount: 'always'
        }
    });

    const {
        data: snmpTemplates = [],
        isLoading: snmpTemplatesIsLoading,
    } = useFetchSnmpTemplate({
        options: {
            refetchOnMount: 'always'
        },
        select: e => e.map(e => ({ key: e.template_name, value: e.template_name, text: e.template_name }))
    });

    const {
        data: snmpSecurityProfiles = [],
        isLoading: snmpSecurityProfilesIsLoading,
    } = useFetchSnmpSecurityProfile({
        options: {
            refetchOnMount: 'always'
        },
        select: e => e.map(e => ({ key: e.uuid, value: e.uuid, text: e.name }))
    });

    const onSubmit = async (values) => {
        const { check_ping, specify_ping, ...rest } = values;
        const snmpHost = {
            ...rest,
            ...specify_ping && { check_ping: check_ping }
        };
        
        if (snmpHost.security_profile === "") { delete snmpHost.security_profile }

        values.uuid ?
            await NMService.updateSnmpHostGroup(snmpHost).then(() =>
                        addGlobalMessage({
                            header: 'SNMP template successfully updated',
                            content: 'SNMP template successfully updated',
                            type: 'positive',
                    })).catch(e => null)
            :
            await NMService.addSnmpHostGroup(snmpHost).then(() =>
                        addGlobalMessage({
                            header: 'SNMP template successfully added',
                            content: 'SNMP template successfully added',
                            type: 'positive',
                    })).catch(e => null)

        refetchSnmpHostGroups()
        dispatch({ type: ClearShowType })
    };

    useEffect(() => {
        const values = getValues();
        const check_ping = state.selectedHost.check_ping;
        const specify_ping = (check_ping === false || check_ping === true) ? true : false;

        if (state.showType === EditHost) {
            reset({ ...values, ...state.selectedHost, specify_ping, isHostVisible: true, })
        }

        if (state.showType === AddHost) {
            reset({
                ...values,
                specify_ping: false,
                check_ping: false,
                name: "",
                security_profile: "",
                templates: [],
                port: 0
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.showType, state.selectedHost]);

    return (snmpTemplatesIsLoading || snmpSecurityProfilesIsLoading) ? null : (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Header as="h4" dividing>
                {(state.showType === AddHost ? 'Add new' : 'Edit') + ' host group'}
            </Header>
            <Form.Group widths={4}>
                {renderInput("Name", "name")}
            </Form.Group>
            <Form.Group>
                {renderDropdown("Security profile", "security_profile", snmpSecurityProfiles, { notRequired: true, width: 4, clearable: true })}
                {renderDropdown("Templates", "templates", snmpTemplates, { notRequired: true, width: 8, multiple: true })}
                {renderInput("Port", "port", { notRequired: true, inputType: "number" })}
            </Form.Group>
            <Form.Group widths={6}>
                {renderCheckbox("Specify ping", "specify_ping", { toggle: true })}
                {watch("specify_ping") && renderCheckbox("Check ping", "check_ping")}
            </Form.Group>
            <Form.Group>
                <PermissionsGateV hasPermission={hasPermission}>
                    <Form.Button
                        type="submit"
                        size="small"
                        primary
                        content={state.showType === AddHost ? "Add" : "Update"}
                    />
                </PermissionsGateV>
                <Form.Button
                    type="button"
                    size="small"
                    content="Cancel"
                    onClick={() => dispatch({ type: ClearShowType })}
                />
            </Form.Group>
        </Form>
    )
}

export default HostGroupForm;