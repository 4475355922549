import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Form, Header } from "semantic-ui-react";
import NMService from "../../../../../services/nm.service";
import { RouteValidation } from "../../networkValidation";
import { addGlobalMessageAtom } from "../../../../../store/globalMessage";
import { useAtomValue, useSetAtom } from "jotai";
import { useFormFields } from "../../../../../hooks/useFormFields";
import { editProbeAtom } from "../../../../../store/editProbe";
import { PermissionsGateV } from "../../../../../layouts/PermissionGate/PermissionGateV";

function NamespaceRouteForm(props) {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const probeAtomState = useAtomValue(editProbeAtom);
    const interfaces = useMemo(() => (props.interfaces || []).map((intf) => ({ key: intf.name, value: intf.name, text: `${intf.name}` })), [props.interfaces]);
    const { handleSubmit, register, watch, reset, setValue, formState: { errors } } = useForm({ defaultValues: { metric: 100 } });
    const { renderInput, renderDropdown } = useFormFields({ register, errors, setValue, watch });

    const onSubmit = (values) => {
        if (!!watch('via')) { delete values.dev } else { delete values.via }
        values = props.type === "edit" ? values : { ...values, namespace: props.namespace }
        NMService.postRouteConfig(props.nodeId, values).then((r) =>
                addGlobalMessage({
                    header: `${props.type === "edit" ? 'Update' : 'Add new'} route configuration`,
                    content: `Route successfully ${props.type === "edit" ? 'updated' : 'added'}`,
                    type: "positive",
                })
            ).then(() => {
                props.toggleForm();
                props.updateNamespacesConfig();
            }).catch(e => null)
    }

    useEffect(() => {
        if (props.data) { reset(props.data) }
    }, [props.data]); // eslint-disable-line

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="basic segment" style={{ padding: "1rem 1rem" }}>
            <Header dividing as="h4" style={{ marginBottom: "1.5rem" }}>
                {props.type === "edit" ? `Edit route ${props.data.destination}` : "Add new route"}
            </Header>
            <Form.Group widths={5}>
                {renderInput('Destination', 'destination', { disabled: props.type !== "add", validate: (v) => RouteValidation.validateDestination(v) })}
                {renderInput('Via', 'via', { disabled: !!watch("dev"), notRequired: true, validate: (v) => RouteValidation.validateVia(v, !!watch('dev'))})}
                {renderDropdown('Device', 'dev', interfaces, { notRequired: true, disabled: !!watch("via"), validate: (v) => (!v && !!watch("via")) ? "Field is mandatory" : undefined })}
                {renderInput('Metric', 'metric', { min: 0, max: 65536, inputType: 'number' })}
            </Form.Group>
            <Form.Group style={{ paddingLeft: "0.5rem" }}>
                <PermissionsGateV hasPermission={probeAtomState?.permissions?.editbasicdata}>
                    <Form.Button type="submit" size="small" primary content="Save" />
                </PermissionsGateV>
                <PermissionsGateV hasPermission={probeAtomState?.permissions?.editbasicdata}>
                    <Form.Button type="button" size="small" onClick={() => props.toggleForm()} content="Cancel" />
                </PermissionsGateV>
            </Form.Group>
        </Form>
    );
}

export default NamespaceRouteForm;
