import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Form, Icon } from 'semantic-ui-react';

export default function LoggerOutputOption(props) {
    const { control } = useFormContext();

    const {
        fields: outputOptionFields,
        remove: removeOutputOption,
        append: appendOutputOption,
    } = useFieldArray({ control, name: `loggers.${props.parentIndex}.output_options` });

    return (
        <>
            <Form.Group style={{ marginLeft: '11rem' }}>
                <label>Add new output option</label>
                <Icon
                    name="plus"
                    link
                    style={{ color: '#4183c4', paddingLeft: '1rem' }}
                    title="Add new output option"
                    onClick={() => appendOutputOption({ output: ''})}
                />
            </Form.Group>
            {outputOptionFields.map((optionField, optionIndex) => (
                <Form.Group key={optionField.id} style={{ paddingLeft: '14rem' }}>
                    <Icon
                        link
                        name="trash alternate"
                        style={{ color: '#4183C4', position: 'relative', marginTop: '1.8rem' }}
                        onClick={() => removeOutputOption(optionIndex)}
                    />
                    {props.renderInput('Output option', `loggers.${props.parentIndex}.output_options.${optionIndex}.output`, { width: 6 })}
                </Form.Group>
            ))}
        </>
    );
}
