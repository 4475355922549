import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAtom, useSetAtom } from "jotai";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Header } from "semantic-ui-react";
import { ADMIN } from "../../constants/layout";
import { useGetGlobalPermission } from "../../hooks/useGetGlobalPermission";
import { globalSnmpSecurityProfileActions, globalSnmpSecurityProfileAtom } from "../../store/globalSnmpSecurityProfiles";
import { PermissionsGateV } from "../../layouts/PermissionGate/PermissionGateV";
import { useFormFields } from "../../hooks/useFormFields";
import NMService from "../../services/nm.service";
import { addGlobalMessageAtom } from "../../store/globalMessage";


const SnmpFormSchema = yup.object().shape({
    name: yup.string()
        .required("Field is required."),
    snmp_version: yup.string()
        .required()
});
const defaultValues ={ name: "", community: "", snmp_version: "" }

const SecurityProfileForm = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const hasPermission = useGetGlobalPermission(ADMIN);
    const [state, dispatch] = useAtom(globalSnmpSecurityProfileAtom);
    const { AddSecurityProfile, ClearShowType } = globalSnmpSecurityProfileActions;
    const snmpVersions = ["v1", "v2c", "v3"].map(e => ({ key: e, text: e, value: e }))

    const {
        register,
        setValue,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm({
            resolver: yupResolver(SnmpFormSchema),
            defaultValues: defaultValues
    });

    const { renderInput, renderDropdown } = useFormFields({ register, errors, setValue, watch });

    const onSubmit = async (values) => {
        values.uuid ? 
        await NMService.updateSnmpSecurityProfile(values).then(() =>
                addGlobalMessage({
                    header: 'SNMP security profile successfully updated',
                    content: 'SNMP security profile successfully updated',
                    type: 'positive',
            })
        ).catch(e => null)
        :
        await NMService.addSnmpSecurityProfile(values).then(() => 
                addGlobalMessage({
                    header: 'SNMP security profile successfully added',
                    content: 'SNMP security profile successfully added',
                    type: 'positive',
            })
        ).catch(e => null)
        props.refetch()
        dispatch({ type: ClearShowType })
    };

    useEffect(() => {
        state.showType === AddSecurityProfile ?
            reset(defaultValues)
        :
            reset(state.selectedSecurityProfile)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.showType, state.selectedSecurityProfile]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Header as="h4" dividing>
                {(state.showType === AddSecurityProfile ? 'Add new' : 'Edit') + ' security profile'}
            </Header>
            <Form.Group widths={4}>
                {renderInput("Name", "name")}
                {renderInput("Community", "community", { notRequired: true })}
                {renderDropdown("SNMP version", "snmp_version", snmpVersions)}
            </Form.Group>
            <Form.Group>
                    <PermissionsGateV hasPermission={hasPermission}>
                        <Form.Button
                            type="submit"
                            size="small"
                            primary
                            content={state.showType === AddSecurityProfile ? "Add" : "Update"}
                        />
                    </PermissionsGateV>
                    <Form.Button
                        type="button"
                        size="small"
                        content="Cancel"
                        onClick={() => dispatch({ type: ClearShowType })}
                    />
            </Form.Group>
        </Form>
    )
}

export default SecurityProfileForm;