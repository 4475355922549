import React, { Fragment, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import RunningConfig from './RunningConfig';
import ModuleConfig from './ModuleConfig';
import NMService from '../../../services/nm.service';
import ModuleBoxLayout from '../../../layouts/ModuleBox/ModuleBox';
import { useAtom, useSetAtom } from 'jotai';
import { selectedTemplateModuleAtom, templateModulesHasPermission } from './AssignedModules';
import AnchorStyleButton from '../../../layouts/AnchorStyleButton/AnchorStyleButton';
import { useFetchSwModules } from '../../../hooks/useFetchSwModules';
import { useFetchTemplateDetails } from '../../../hooks/useFetchTemplateDetails';
import { addGlobalMessageAtom } from '../../../store/globalMessage';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';

const AssignedModule = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const moduleUuid = useMemo(() => props.moduleUuid, [props.moduleUuid]);
    const [state, setState] = useState({
        isSelected: false,
        subFrameworks: [],
        subModules: [],
        showForm: '',
        showDeleteModal: false
    });
    const [selectedModule, selectModule] = useAtom(selectedTemplateModuleAtom);
    const [modulesHasPermission] = useAtom(templateModulesHasPermission);

    const {
        data: swModuleList,
        isLoading: swModuleListIsLoading,
    } = useFetchSwModules({ options: { staleTime: Infinity } });

    const {
        data: templateDetails,
        refetch: refetchTemplate,
        isLoading: templateDetailsIsLoading
    } = useFetchTemplateDetails({ templateId: props.templateId, options: { staleTime: Infinity } })

    const {
        data: moduleData,
        refetch: refetchModuleData
    } = useFetchTemplateDetails({
        templateId: props.templateId,
        select: (data) => data.modules.find(e => e.uuid === moduleUuid),
        options: {
            enabled: !!moduleUuid,
            staleTime: Infinity
        }
    })

    const noConfigChangeMessage = () => addGlobalMessage({
                                            header: 'No configuration change made.',
                                            content: 'Module configuration not changed.',
                                            type: 'positive',
                                        })


    const deleteModuleFromTemplate = () => {
        NMService.deleteNMTemplateModule(props.templateId, moduleData.moduleid, moduleData.instanceid)
            .then((r) =>
                addGlobalMessage({
                    header: "Module deleted from template!",
                    content: "Module deleted from template!",
                    type: "positive",
                })
            )
            .catch(e => null)
            .finally((r) => {
                setState((p) => ({ ...p, showDeleteModal: false }))
                refetchTemplate()
            });
    };

    const onRunningFormSubmit = (data) => {
        NMService.updateNMTemplateModuleRunConfig(data).then(() =>
                addGlobalMessage({
                    header: 'Running configuration was updated!',
                    content: `Running configuration was successfully updated.`,
                    type: 'positive',
                }))
            .then(() => refetchModuleData())
            .catch(e => null)
    };

    useEffect(() => {
        setState((prevState) => ({ ...prevState, isSelected: !!(selectedModule?.uuid === moduleData.uuid) }))
    }, [setState, selectedModule, moduleData.uuid]);

    useLayoutEffect(() => {
        if (templateDetailsIsLoading || swModuleListIsLoading) {
            return;
        };

        const subFrameworkArr = templateDetails.modules.filter(e => e.parent === moduleData.uuid && swModuleList.find(model => (model.id === e.moduleid && model.data.framework && model.data.toplevel)));
        const subModulArr = templateDetails.modules.filter(e => e.parent === moduleData.uuid && swModuleList.find(model => (model.id === e.moduleid && !model.data.framework && !model.data.toplevel)));

        setState(prevState => ({
            ...prevState,
            subFrameworks: subFrameworkArr,
            subModules: subModulArr,
        }))

    }, [templateDetails, moduleData, swModuleList, templateDetailsIsLoading, swModuleListIsLoading]);

    return (
        <>
            <ModuleBoxLayout level={props.level}>
                <Grid padded>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            {moduleData.moduleid} (v{moduleData.version})
                        </Grid.Column>
                        <Grid.Column width={3}>{moduleData.instanceid}</Grid.Column>
                        <Grid.Column width={7}>
                        {moduleData.moduleid !== 'ethernetframework' ?
                            <>
                                {(props.level === 1 || props.top === 'ethernetframework' || props.top === "" || props.top === undefined) && 
                                    <>
                                        <AnchorStyleButton
                                            selected={state.isSelected && state.showForm === 'runningConfig'}
                                            onClick={() => {
                                                refetchModuleData();
                                                if (state.isSelected && state.showForm === 'runningConfig'){
                                                    selectModule(p => ({...p, uuid: ''}))
                                                    setState(p => ({...p, showForm: ''}))
                                                } else {
                                                    selectModule({ uuid: moduleData.uuid });
                                                    setState((prevState) => ({ ...prevState, showForm: 'runningConfig' }));
                                                }
                                            }}
                                        >
                                            <Icon name="cog" /> running config
                                        </AnchorStyleButton>&emsp;
                                    </>}
                                <AnchorStyleButton
                                    selected={state.isSelected && state.showForm === 'moduleConfig'}
                                    onClick={() => {
                                        refetchModuleData();
                                        if (state.isSelected && state.showForm === 'moduleConfig'){
                                            selectModule(p => ({...p, uuid: ''}))
                                            setState(p => ({...p, showForm: ''}))
                                        } else {
                                            selectModule({ uuid: moduleData.uuid });
                                            setState((prevState) => ({ ...prevState, showForm: 'moduleConfig' }));
                                        }
                                    }}
                                >
                                    <Icon name="cog" /> module config
                                </AnchorStyleButton>
                                &emsp;
                            </> : null}
                        </Grid.Column>
                        <Grid.Column width={1}>
                            {state.isSelected && state.showForm && (
                                <AnchorStyleButton
                                    style={{position: "absolute", right: "0"}}
                                    onClick={() => selectModule(null)}
                                >
                                    <Icon name="close" />
                                </AnchorStyleButton>
                            )}
                        </Grid.Column>
                        <Grid.Column width={1}>
                            <AnchorStyleButton
                                style={{position: "absolute", right: "20px"}}
                                disabled={state.subFrameworks.length !== 0 || state.subModules.length !== 0 || props.cantDeleteFramework}
                                onClick={() => setState(p => ({...p, showDeleteModal: true}))}
                            >
                                <Icon name="trash alternate" link />
                            </AnchorStyleButton>
                        </Grid.Column>
                    </Grid.Row>
                    {state.isSelected && state.showForm && (
                        <Grid.Row>
                            <Grid.Column>
                                {state.showForm === 'runningConfig' && (
                                    <RunningConfig
                                        data={moduleData}
                                        hasPermission={modulesHasPermission}
                                        onFormSubmit={onRunningFormSubmit}
                                        noConfigChangeMessage={noConfigChangeMessage}
                                    />
                                )}
                                {(state.showForm === 'moduleConfig' || state.showForm === 'rawJsonModuleConfig') && (
                                    <ModuleConfig
                                        data={moduleData}
                                        hasPermission={modulesHasPermission}
                                        noConfigChangeMessage={noConfigChangeMessage}
                                    />
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>
            </ModuleBoxLayout>

            {state.showDeleteModal && (
                <ConfirmationModal
                    open={true}
                    header={"Delete module from template"}
                    content={`Do you want to delete module ${moduleData.moduleid}:${moduleData.instanceid} from template?`}
                    onConfirm={deleteModuleFromTemplate}
                    onDismiss={() => setState((p) => ({ ...p, showDeleteModal: false }))}
                />
            )}

            {state.subFrameworks.map(({ uuid }) => (
                <AssignedModule
                    key={uuid}
                    top={props.top}
                    level={props.level + 1}
                    templateId={props.templateId}
                    moduleUuid={uuid}
                />
            ))}

            {state.subModules.map(({ uuid }) => (
                <AssignedModule
                    key={uuid}
                    top={props.top}
                    level={props.level + 1}
                    templateId={props.templateId}
                    moduleUuid={uuid}
                />
            ))}
        </>
    )
}

export default AssignedModule;