import { useQuery } from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchGrafanaModuleDashboards = ({
    moduleid,
    select,
    options
} = {}) => {
    const query = useQuery({
        queryKey: ['getSWModuleVisDashTemplates', String(moduleid)],
        queryFn: () => 
            NMService.getSWModuleVisDashTemplates(moduleid)
                .then(r => r.data)
                .catch(e => []),
        staleTime: Infinity,
        placeholderData: (data) => data ?? [],
        enabled: !!moduleid,
        select,
        ...options
    })
    return { ...query, data: query.data ?? [] }
}