import { atomWithReducer } from 'jotai/utils';

export const locationsActions = {
    ADD_NEW_LOCATION: 'add-new-location',
    DELETE_LOCATION: 'delete-location',
    EDIT_LOCATION: 'edit-location',
    SHOW_LOCATION_INFO: 'show-location-info',
    CLEAR_TYPE: 'clear-type',
}

const INITIAL_STATE = {
    selectedLocation: {},
    showType: ''
}

const locationParametersReducer = (prevState, { type, value }) => {
    switch (type) {
        case locationsActions.ADD_NEW_LOCATION:
            return {
                ...prevState,
                selectedLocation: {},
                showType: locationsActions.ADD_NEW_LOCATION
            };
        case locationsActions.DELETE_LOCATION:
            return {
                ...prevState,
                selectedLocation: value,
                showType: locationsActions.DELETE_LOCATION
            };
        case locationsActions.EDIT_LOCATION:
            return {
                ...prevState,
                selectedLocation: value,
                showType: locationsActions.EDIT_LOCATION
            };
        case locationsActions.SHOW_LOCATION_INFO:
            return {
                ...prevState,
                selectedLocation: value,
                showType: locationsActions.SHOW_LOCATION_INFO
            };
        case locationsActions.CLEAR_TYPE:
            return {
                ...prevState,
                selectedLocation: {},
                showType: ''
            }
        default:
            return INITIAL_STATE

    }
}

export const locationsAtom = atomWithReducer(INITIAL_STATE, locationParametersReducer);
