import { atomWithReducer } from 'jotai/utils';


export const unmanagedProbesActions = {
    ShowProbeInfo: 'show-probe-info',
    DeleteProbe: 'delete-probe',
    DeselectProbe: 'close-probe-info',
}


const INITIAL_STATE = {
    selectedProbeData: {},
    showType: ''
}

const unmanagedProbesReducer = (prevState, { type, value }) => {
    switch (type) {
        case unmanagedProbesActions.ShowProbeInfo:
            return {
                ...prevState,
                showType: unmanagedProbesActions.ShowProbeInfo,
                selectedProbeData: value
            };
        case unmanagedProbesActions.DeleteProbe:
            return {
                ...prevState,
                showType: unmanagedProbesActions.DeleteProbe,
                selectedProbeData: value
            };
        case unmanagedProbesActions.DeselectProbe:
            return {
                ...prevState,
                showType: '',
                selectedProbeData: {}
            };
        default:
            return INITIAL_STATE

    }
}

export const unmanagedProbesAtom = atomWithReducer(INITIAL_STATE, unmanagedProbesReducer);
