export const adjustConfig = (config) => {
    let formattedConfig = {};
    Object.keys(config).forEach((e) => {
        if (config[e] !== null && config[e] !== undefined) {
            if (typeof config[e] === 'object' && Array.isArray(config[e])) {
                formattedConfig[e] = [];
                config[e].forEach((i) => {
                    if (typeof i === 'object') {
                        formattedConfig[e].push(adjustConfig(i));
                    } else {
                        formattedConfig[e].push({ var: i });
                    }
                });
            } else if ((typeof config[e] === 'object') & !Array.isArray(config[e])) {
                formattedConfig[e] = adjustConfig(config[e]);
            } else {
                formattedConfig[e] = config[e];
            }
        }
    });
    return formattedConfig;
};