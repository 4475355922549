import { useQuery } from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchProbeDetails = ({ 
    probeid, 
    select, 
    options 
} = {}) => {
    const query = useQuery({
        queryKey: ['getNodeDetails', String(probeid)],
        queryFn: () =>
            NMService.getNodeDetails(String(probeid))
                .then(r => r.data)
                .catch(e => {}),
        staleTime: Infinity,
        enabled: !!probeid,
        select,
        ...options
    })
    return { ...query, data: query.data ?? {} }
}