import { Form, Button, Grid } from "semantic-ui-react";
import { useSetAtom } from "jotai";
import { useForm } from "react-hook-form";
import NMService from "../../../services/nm.service";
import { addGlobalMessageAtom } from "../../../store/globalMessage";
import { useGetGlobalPermission } from "../../../hooks/useGetGlobalPermission";
import { NODE_ADMIN } from "../../../constants/layout";
import { PermissionsGateV } from "../../../layouts/PermissionGate/PermissionGateV";
import { useFormFields } from "../../../hooks/useFormFields";

const SkippedMeasurementsForm = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const hasPermission = useGetGlobalPermission(NODE_ADMIN);

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const { renderInput } = useFormFields({register, errors, setValue, watch});

    const onSubmit = (values) => 
        NMService.updateSkippedMeasurementsPeriod(values.hours).then(() => 
            addGlobalMessage({
                header: 'Hours scanning period was updated!',
                content: 'Hours scanning period was successfully updated.',
                type: "positive",
            })
        )
        .catch(e => null)
        .finally(() => props.refetch())

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Grid padded>
                <Grid.Row columns={5}>
                    <Grid.Column>
                        {renderInput('Scan last N hours', 'hours', { validate: (e) => e >= 24*10 ? 'Scanning period can not be more than 10 days!' : e < 1 ? 'Scanning period must be a positive integer' : undefined, inputType: 'number', infoText: 'Will set scanning period with offset of -30 minutes from update' })}
                    </Grid.Column>
                    <Grid.Column>
                        <PermissionsGateV hasPermission={hasPermission}>
                            <Button
                                type="submit"
                                primary
                                content="Update Scanning Hours Period"
                                style={{ fontSize: "0.875rem", marginTop: '26px' }}
                            />
                        </PermissionsGateV>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
}

export default SkippedMeasurementsForm;