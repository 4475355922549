import { useQuery } from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchSwModules = ({ 
    select, 
    options 
} = {}) => {
    const query = useQuery({
        queryKey: ['getSWModules'],
        queryFn: () =>
            NMService.getSWModules()
                .then(r => r.data)
                .catch(e => []),
        staleTime: Infinity,
        placeholderData: (data) => data ?? [], 
        select,
        ...options
    })
    return { ...query, data: query.data ?? [] }
}