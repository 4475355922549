import { Breadcrumb, Button, Divider } from "semantic-ui-react";
import ConfirmationModal from "../../../../components/modal/ConfirmationModal";
import EventCollectorSourceForm from "./EventCollectorSourceForm";
import { useSetAtom } from "jotai";
import { useMemo, useState } from "react";
import { addGlobalMessageAtom } from "../../../../store/globalMessage";
import NMService from "../../../../services/nm.service";
import CustomTable from "../../../../components/CustomTable";
import { useFetchEventCollectorSources } from "../../../../hooks/useFetchEventCollectorSources";
import EventCollectorSourceDetails from "./EventCollectorSourceDetails";

const EventCollectorSources = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [source, setSource] = useState(undefined);
    const [view, setView] = useState(undefined);
    const viewActions = {
        FORM: 'form',
        DELETE: 'delete',
        DETAILS: 'details'
    }

    const {
        data,
        isFetching,
        refetch
    } = useFetchEventCollectorSources({
        options: {
            refetchOnMount: "always",
        },
    });

    const columns = useMemo(
        () => [
            {
                id: "name",
                Header: "Name",
                accessor: "name"
            },
            {
                id: "description",
                Header: "Description",
                accessor: d => d.description ?? '-'
            },
            {
                id: "sourceid",
                Header: "Source ID",
                accessor: d => d.sourceid ?? '-'
            },
            {
                id: "sourceaddress",
                Header: "Source address",
                accessor: d => d.sourceaddress ?? '-'
            },
            {
                id: "protocol",
                Header: "Protocol",
                accessor: 'protocol'
            },
            {
                id: "tsformat",
                Header: "Timestamp format",
                accessor: 'tsformat'
            },
            // {
            //     id: "templatelist",
            //     Header: "Template list",
            //     accessor: d => d?.templates?.join("/n") || "-"
            // },
            // {
            //     id: "sourcegroups",
            //     Header: "Source groups",
            //     accessor: d => d?.sourcegroups?.join("/n") || "-"
            // },
        ],
        []
    );
    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: "actions",
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            circular
                            icon="info"
                            type="button"
                            size="small"
                            onClick={() => {
                                setSource(row.original);
                                setView(viewActions.DETAILS)
                            }}
                        />
                        <Button
                            circular
                            icon="settings"
                            type="button"
                            size="small"
                            onClick={() => {
                                setSource(row.original);
                                setView(viewActions.FORM)
                            }}
                        />
                        <Button
                            circular
                            icon="trash"
                            type="button"
                            size="small"
                            onClick={() => {
                                setSource(row.original);
                                setView(viewActions.DELETE)
                            }}
                        />
                    </div>,
                width: 2,
                disableGlobalFilter: true,
                disableFilters: true,
            },
        ]);
    };

    const deleteSource = () => {
        NMService.deleteECSource(source.uuid)
            .then((r) =>
                addGlobalMessage({
                    header: `Source ${source.name} deleted successfully`,
                    content: "Source deleted successfully",
                    type: "positive",
                })
            )
            .catch(e => null)
            .finally(() => {
                setView(undefined)
                setSource(undefined);
                refetch()
            });
    };

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Section>Event collector</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section active>Sources</Breadcrumb.Section>
            </Breadcrumb>
            <Divider clearing />
            <CustomTable
                key={isFetching}
                data={data}
                active={source?.uuid}
                columns={columns}
                hideSearch={true}
                tableHooks={tableHooks}
                footerButton={() =>
                    <Button
                        floated="right"
                        primary
                        size="tiny"
                        type="button"
                        content="Add new source"
                        onClick={() => {
                            setSource(undefined);
                            setView(viewActions.FORM)
                        }}
                    />
                }
            />

            {view === viewActions.DELETE && (
                <ConfirmationModal
                    open={!!source?.name}
                    header="Delete source?"
                    content={`Are you sure you want to delete source: ${source?.name}`}
                    onConfirm={() => {
                        data.splice(source.id, 1);
                        deleteSource();
                    }}
                    onDismiss={() => setView(undefined)}
                />
            )}

            {view === viewActions.FORM && (
                <EventCollectorSourceForm
                    key={`${source?.uuid || "new"}`}
                    data={source}
                    close={() => {
                        refetch()
                        setView(undefined)
                        setSource(undefined);
                    }}
                />
            )}

            {view === viewActions.DETAILS && (
                <EventCollectorSourceDetails
                    key={`${source?.uuid || "new"}`}
                    data={source}
                    close={() => {
                        refetch()
                        setView(undefined)
                        setSource(undefined);
                    }}
                />
            )}
        </>)
}

export default EventCollectorSources;