

import { useQuery } from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchLatestMeasurements = ({
    hwid,
    moduleid,
    instanceid,
    select,
    options
} = {}) => {
    const query = useQuery({
        queryKey: ['getLatestMeasurements', hwid, moduleid, instanceid],
        queryFn: () => 
            NMService.getLatestMeasurements(hwid, moduleid, instanceid)
                .then(r => r.data)
                .catch(e => []),
        enabled: !!moduleid && !!hwid && !!instanceid,
        placeholderData: (data) => data ?? [],
        staleTime: Infinity,
        select,
        ...options
    })
    return { ...query, data: query.data ?? [] }
}