import React, { useEffect, useState } from "react";
import L from "leaflet";
import { TileLayer, Marker, MapContainer, useMapEvents, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import icon from "./marker-icon.png";
import iconShadow from "./marker-icon.png";

const LocationMap = ({ setValue, watch }) => {
    const lat = watch("latitude")
    const lng = watch("longitude")
    const [position, setPosition] = useState({ lat, lng });
    L.Marker.prototype.options.icon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 40],
    });

    const checkCoordinate = (v, min, max) => !isNaN(Number(v)) && v >= min && v <= max

    const Recenter = () => {
        const map = useMap()
        if (checkCoordinate(lat, -90, 90) && checkCoordinate(lng, -180, 180))
            map.flyTo({ lat, lng }, map.getZoom(), { animate: true, duration: 0.5 })
        return null
    }

    const LocationMarker = () => {
        const map = useMapEvents({
            click: (e) => {
                let latlng = {
                    lat: e.latlng.lat,
                    lng: e.latlng.lng % 180 + (Math.floor(Math.abs(e.latlng.lng) / 180 % 2) === 1 ? (e.latlng.lng > 0 ? -180 : 180) : 0)
                }
                setPosition(latlng)
                setValue("latitude", latlng.lat)
                setValue("longitude", latlng.lng)
                map.flyTo(latlng, map.getZoom(), { animate: true, duration: 0.5 })
            },
        });

        return position === null ? null : <Marker position={position} />;
    };

    useEffect(() => {
        if (checkCoordinate(lat, -90, 90) && checkCoordinate(lng, -180, 180))
            setPosition({ lat, lng })
    }, [lat, lng])

    return (
        <MapContainer center={[lat, lng]} zoom={6} scrollWheelZoom={true}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Recenter />
            <LocationMarker />
        </MapContainer>
    );
};

export default LocationMap;
