import { Fragment } from "react";
import { useOutlet, useSearchParams } from "react-router-dom";

export const ReturnUrlLayout = () => {
    const [searchParams] = useSearchParams();
    const url = searchParams.get("returl");
    const outlet = useOutlet();

    if (url) {
        window.location.replace(url)
    }

    return <Fragment>{outlet}</Fragment>;
};