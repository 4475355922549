import React, { useEffect, useReducer } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import NMService from '../../../../services/nm.service';
import NetworkNamespaceForm from '../NetworkNamespaceForm';
import NamespaceInterfaces from './Interfaces/NamespaceInterfaces';
import NamespaceRoutes from './Routes/NamespaceRoutes';
import ConfirmationModal from '../../../../components/modal/ConfirmationModal';
import ModuleBoxLayout from '../../../../layouts/ModuleBox/ModuleBox';
import AnchorStyleButton from '../../../../layouts/AnchorStyleButton/AnchorStyleButton';
import { useAtomValue, useSetAtom } from 'jotai';
import { PermissionsGateV } from '../../../../layouts/PermissionGate/PermissionGateV';
import { addGlobalMessageAtom } from '../../../../store/globalMessage';
import { editProbeAtom } from '../../../../store/editProbe';

const reducer = (state, action) => {
    switch (action.type) {
        case 'setState':
            return { ...state, ...action.payload };
        default:
            throw new Error();
    }
};

function Namespace(props) {
    const probeAtomState = useAtomValue(editProbeAtom);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [state, dispatchLocal] = useReducer(reducer, {
        nodeId: undefined,
        namespace: {},
        namespaceType: undefined,
        showNamespace: false,
        showNamespaceEditForm: false,
        showDeleteModal: false,
    });

    useEffect(() => {
        const { type, ...namespace } = props.data;
        if (type && props.nodeId && namespace.hasOwnProperty('name') && namespace.name) {
            dispatchLocal({ type: 'setState', payload: { namespace: namespace, type: type } });
        }
    }, [props.data, props.nodeId]);

    const toggleEditForm = (value = false) => {
        dispatchLocal({ type: 'setState', payload: { showNamespaceEditForm: value, showNamespace: true } });
    };

    const deleteNamespace = () =>
        NMService.deleteNamespaceConfig(props.nodeId, state.namespace.name).then((r) =>
                addGlobalMessage({
                    header: 'Deleted namespace',
                    content: 'Namespace was successfully deleted',
                    type: 'positive',
                })
        ).then(() => {
            dispatchLocal({ type: 'setState', payload: { showDeleteModal: false, showNamespaceEditForm: false } });
            props.updateNamespacesConfig();
        }).catch(e => null)

    return (
        <>
            <ModuleBoxLayout onClick={() => dispatchLocal({ type: 'setState', payload: { showNamespace: !state.showNamespace } })}>
                <Grid padded>
                    <Grid.Row>
                        <Grid.Column width={3}>Namespace:&emsp;{state.namespace.name}</Grid.Column>
                        <Grid.Column width={4}>
                            {state.namespace.name !== 'default' ? (
                                <div>
                                    create /etc/netns/{state.namespace.name}:&emsp;
                                    {state.namespace.etc ? <Icon name="checkmark" /> : <Icon name="close" />}
                                </div>
                            ) : null}
                        </Grid.Column>

                        <Grid.Column width={7}>
                            {state.type === 'explicit' ? (
                                <>
                                    &emsp;
                                    <PermissionsGateV hasPermission={probeAtomState?.permissions?.editbasicdata}>
                                        <AnchorStyleButton onClick={(e) => {
                                            e.stopPropagation()
                                            toggleEditForm(!state.showNamespaceEditForm)
                                        }}>
                                            <Icon name="cog" /> edit
                                        </AnchorStyleButton>
                                    </PermissionsGateV>
                                    &emsp;&emsp;
                                    <PermissionsGateV hasPermission={probeAtomState?.permissions?.editbasicdata}>
                                        <AnchorStyleButton
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                dispatchLocal({ type: 'setState', payload: { showDeleteModal: true } })
                                            }}
                                        >
                                            <Icon name="trash" /> delete
                                        </AnchorStyleButton>
                                    </PermissionsGateV>
                                </>
                            ) : null}
                        </Grid.Column>

                        <Grid.Column width={2}>
                            <Icon
                                name={`caret ${state.showNamespace ? 'up' : 'down'}`}
                                size="large"
                                style={{ float: "right", marginRight: "0" }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {state.showNamespace && (
                        <Grid.Row style={{ paddingTop: '0', paddingBottom: '1rem,' }} onClick={(e) => {e.stopPropagation()}}>
                            <Grid.Column>
                                {state.showNamespaceEditForm && (
                                    <NetworkNamespaceForm
                                        data={state.namespace}
                                        nodeId={props.nodeId}
                                        toggleForm={toggleEditForm}
                                        updateNamespacesConfig={props.updateNamespacesConfig}
                                    />
                                )}
                                <NamespaceInterfaces
                                    existing={(props.existingIntf || [])}
                                    all={(props.all || [])}
                                    data={state.namespace}
                                    nodeId={props.nodeId}
                                    interfaces={props.interfaces}
                                    updateNamespacesConfig={props.updateNamespacesConfig}
                                />
                                <NamespaceRoutes
                                    data={state.namespace}
                                    nodeId={props.nodeId}
                                    updateNamespacesConfig={props.updateNamespacesConfig}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>
            </ModuleBoxLayout>
            <ConfirmationModal
                open={state.showDeleteModal}
                header="Delete namespace"
                content={`Are you sure you want to delete namespace ${state.namespace.name}?`}
                onConfirm={deleteNamespace}
                onDismiss={() => dispatchLocal({ type: 'setState', payload: { showDeleteModal: false } })}
            />
        </>
    );
}

export default Namespace;
