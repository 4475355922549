import { useMemo } from "react";
import { useAtom } from "jotai";
import { Button } from "semantic-ui-react";
import { licenseMgmtActions, licenseMgmtAtom } from "../../store/licenseManagment";
import CustomTable from "../../components/CustomTable";
import { getExpiryColor, getExpiryDays } from "../../utils/LicenseExpiry";
import { useFetchNodes } from "../../hooks/useFetchNodes";


const LicenseTable = (props) => {
    const [state, dispatch] = useAtom(licenseMgmtAtom);
    const data = useMemo(() => props.data, [props.data])

    const {
        data: nodeList, 
        isLoading: nodeListIsLoading
    } = useFetchNodes();

    const columns = useMemo(
        () => [
            {
                id: "name",
                Header: "Name",
                accessor: "name",
            },
            {
                id: "quantity",
                Header: "Quantity",
                accessor: "quantity",
            },
            {
                id: "description",
                Header: "Description",
                accessor: "description",
            },
            {
                id: "issueDate",
                Header: "Issue date",
                accessor: "issueDate",
            },
            {
                id: "warranty",
                Header: "Warranty",
                accessor: "warranty",
            },
            {
                Header: "Actions",
                id: "actions",
                Cell: ({ row }) => (
                    <div style={{ textAlign: "center" }}>
                        {!nodeListIsLoading &&
                            !nodeList.some(
                                (node) => node.license === row.original.uuid
                            ) && (
                                <>
                                    <Button
                                        circular
                                        icon="trash"
                                        size="small"
                                        onClick={() => dispatch({
                                            type: licenseMgmtActions.SHOW_DELETE_LICENSE,
                                            value: row.original,
                                        })}
                                    />
                                    &nbsp;&nbsp;
                                </>
                            )}
                        <Button
                            circular
                            icon="info"
                            size="small"
                            color={getExpiryColor(
                                getExpiryDays(
                                    row.original.issueDate,
                                    row.original.warranty
                                ),
                                true
                            )}
                            onClick={() =>
                                dispatch({
                                    type: licenseMgmtActions.SHOW_LICENSE_INFO,
                                    value: row.original,
                                })
                            }
                        />
                    </div>
                ),
                width: 2,
                disableGlobalFilter: true,
                disableFilters: true,
            },
        ],
        [nodeListIsLoading, nodeList, dispatch]
    );

    return (
        <CustomTable
            data={data}
            hideSearch={true}
            columns={columns}
            active={state?.selectedLicense?.uuid}
            footerButton={() =>
                <Button
                    primary
                    size="tiny"
                    floated="right"
                    type="button"
                    content="Add new license"
                    onClick={() => dispatch({ type: licenseMgmtActions.SHOW_LICENSE_FORM })}
                />
            }
        />
    )
}

export default LicenseTable;