import { useOutlet } from "react-router-dom";
import { ProtectedLicenseLayout } from "./ProtectedLicenseLayout";

export const ProtectedServicesLayout = () => {
    const outlet = useOutlet();
    return (
        <ProtectedLicenseLayout>
            {outlet}
        </ProtectedLicenseLayout>
    )
};