import { useEffect, useState } from "react";
import { Checkbox, Divider, Header, Icon, Segment, Table } from "semantic-ui-react";
import styled from "styled-components";
import UnManagedProbesTable from "./UnManagedProbesTable";
import ProbeData from "../managedNodeEdit/ProbeData";
import { useAtom } from "jotai";
import { unmanagedProbesActions, unmanagedProbesAtom } from "../../store/unmanagedProbes";
import { strftime } from "../../utils/strftime";
import { useFetchUnmanagedNoTenantProbes } from "../../hooks/useFetchUnmanagedNoTenantProbes";

export const DetailsLabel = styled.label`
    width: '20%',
    text-align: 'left',
    margin-right: '1rem',
`;

function UnManagedProbes(props) {
    const [state, dispatch] = useAtom(unmanagedProbesAtom);
    const [showUnassignProbes, setshowUnassignProbes] = useState(false);

    const {
        data: noTenantUnmanagedProbes = [],
        isLoading: noTenantUnmanagedProbesIsLoading,
        refetch: refetchNoTenantProbes,
    } = useFetchUnmanagedNoTenantProbes({
        options: {
            enabled: !!showUnassignProbes,
        },
    });

    useEffect(() => {
        if (showUnassignProbes) {
            refetchNoTenantProbes();
        } else {
            props.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showUnassignProbes]);

    let data = !showUnassignProbes ? props.data ?? [] : noTenantUnmanagedProbes;
    if (showUnassignProbes && noTenantUnmanagedProbesIsLoading) {
        data = props.data ?? [];
    }

    return (
        <>
            <Segment basic style={{ paddingLeft: "0", paddingBottom: "0" }}>
                <Checkbox
                    toggle
                    label="Show unassigned nodes"
                    defaultChecked={showUnassignProbes}
                    onChange={() => setshowUnassignProbes((p) => !p)}
                />
            </Segment>
            <UnManagedProbesTable
                refetch={props.refetch}
                refetchNoTenantProbes={refetchNoTenantProbes}
                data={data}
                showUnassignProbes={showUnassignProbes}
                showProbeSettings={(data) => dispatch({ type: unmanagedProbesActions.ShowProbeInfo, value: data })}
            />

            {state.showType === unmanagedProbesActions.ShowProbeInfo && (
                <>
                    <Header as="h4" style={{ margin: "2rem 0 0 0" }}>
                        Unmanaged node details
                        <Icon
                            link
                            name="close"
                            style={{ float: "right" }}
                            onClick={() => dispatch({})}
                        />
                    </Header>
                    <Divider style={{ marginTop: "0.5rem" }} />
                    <Segment basic>
                        <Table celled structured size="small" compact columns={3}>
                            <Table.Header>
                                <Table.Row textAlign="center">
                                    <Table.HeaderCell>Hardware ID</Table.HeaderCell>
                                    <Table.HeaderCell>IP address</Table.HeaderCell>
                                    <Table.HeaderCell>Last seen</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row textAlign="center">
                                    <Table.Cell>{state.selectedProbeData.hwid}</Table.Cell>
                                    <Table.Cell>{state.selectedProbeData.ip}</Table.Cell>
                                    <Table.Cell>
                                        {strftime(
                                            "%Y-%m-%d %H:%M:%S",
                                            new Date(Date.parse(state.selectedProbeData.ts))
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Segment>

                    <ProbeData unmanaged={state.selectedProbeData} />
                </>
            )}
        </>
    );
}

export default UnManagedProbes;
