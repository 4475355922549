import React, { useEffect, useState } from 'react';
import { Form, Grid, Header, Divider, Icon } from 'semantic-ui-react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useAtom, useSetAtom } from 'jotai';
import { useAuth, useUserInfo } from '../../hooks/useAuth';
import NMService from '../../services/nm.service';
import { addGlobalMessageAtom } from '../../store/globalMessage';
import { localUserActions, localUsersAtom } from '../../store/localUser';
import { useFetchLocalUser } from '../../hooks/useFetchLocalUser';
import { useFetchGroups } from '../../hooks/useFetchGroups';
import { useFetchUserRoles } from '../../hooks/useFetchUserRoles';
import { useFetchRoles } from '../../hooks/useFetchRoles';
import { useGetGlobalPermission } from '../../hooks/useGetGlobalPermission';
import { PermissionsGateV } from '../../layouts/PermissionGate/PermissionGateV';
import { ADMIN } from '../../constants/layout';
import { useFetchLocalUsers } from '../../hooks/useFetchLocalUsers';
import { useFormFields } from '../../hooks/useFormFields';


const LocalUserForm = () => {
    const [nodeIdentityVersion, setNodeIdentityVersion] = useState('0.0.0')
    const row = { paddingTop: '0.5rem', paddingBottom: '0.5rem' }
    const {
        register,
        reset,
        handleSubmit,
        control,
        setValue,
        setError,
        watch,
        formState: { errors },
    } = useForm();
    const { renderInput, renderDropdown } = useFormFields({ register, errors, setValue, watch });

    const { fields, append, remove } = useFieldArray({ control, name: 'groups' });

    const { logout } = useAuth();
    const { userdata } = useUserInfo();
    const hasPermission = useGetGlobalPermission(ADMIN);

    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [state, dispatch] = useAtom(localUsersAtom);

    const {
        refetch: refetchUserList
    } = useFetchLocalUsers({})

    const {
        data: userData,
        isLoading: userDataIsLoading
    } = useFetchLocalUser({
        user: state.selectedUser
    });

    const {
        data: userRoleList,
        isLoading: userRoleListIsLoading
    } = useFetchUserRoles({
        select: e => e.map((role) => ({
                        key: role.name,
                        value: role.name,
                        text: `${role.name} - ${role.description}`,
                    }))
    });

    const {
        data: roleList,
        isLoading: roleListIsLoading
    } = useFetchRoles({
        select: e => e.map((role) => ({
                        key: role.name,
                        value: role.name,
                        text: `${role.name} - ${role.description}`,
                    }))
    });

    const {
        data: groupList,
        isLoading: groupListIsLoading
    } = useFetchGroups({
        select: e => e.map((group) => ({
                        key: group.name,
                        value: group.name,
                        text: group.name,
                    }))
    });

    const validateUsername = (value) => {
        const usernamePattern = /^[a-zA-Z]{1}[a-zA-Z0-9@._-]{3,64}$/;
        if (!usernamePattern.test(value)) {
            return 'Username is not valid (a-z A-Z 0-9 @ . _ -)';
        }
    };

    const validatePassword = (value) => {
        if (value.length < 8) {
            return 'Password must be minimum 8 chars';
        } else if (value.length > 64) {
            return 'Password len must be maximum 64 chars';
        }
    };

    const onSubmit = async (values) => {
        let removedGroups = [];
        let addedGroups = [];

        if (state.selectedUser) {
            values.groups.forEach((group) => {
                const { groupname, role } = group;
                const foundIndex = userData?.groups?.findIndex(g => g.groupname === groupname);
                if (foundIndex > -1) {
                    if (userData.groups[foundIndex].role !== role) {
                        addedGroups.push(group)
                    }
                } else {
                    addedGroups.push(group)
                }
            });

            userData?.groups?.forEach((group) => {
                const { groupname } = group;
                const foundIndex = values.groups.findIndex(g => g.groupname === groupname);
                if (foundIndex === -1) {
                    removedGroups.push(group)
                }
            })
        }

        if (!state.selectedUser) {
            if (values.password1 !== values.password2) {
                setError('password1', { type: 'custom', message: 'Passwords do not match !' }, { shouldFocus: true });
                return
            }
        }

        (async () => {
            let requests = [];

            let payload = {
                username: values.username,
                fullname: values.fullname,
                email: values.email,
                role: values.role,
                // 'Foo!' - this will never be stored in database! but the field should be here
                password: state.selectedUser ? 'Foo!' : values.password1,
                active: values.active,
            }

            let message = {
                header: state.selectedUser ? "User's info was updated" : 'New user was added!',
                content: state.selectedUser ? "User's info was successfully updated." : 'New user was successfully added.',
                type: 'positive',
            };

            requests.push(nodeIdentityVersion.startsWith('0.0.') ?
                              NMService.addUserLegacy(payload).then((r) =>  addGlobalMessage(message)).catch(e => null)
                            : state.selectedUser ?
                              NMService.updateUser(payload).then((r) =>  addGlobalMessage(message)).catch(e => null)
                            : NMService.addUser(payload).then((r) =>  addGlobalMessage(message)).catch(e => null));

            if (addedGroups.length > 0) {
                for (const group of addedGroups) {
                    requests.push(
                        NMService.addLocalUserToGroup({
                            username: values.username,
                            role: group.role,
                            groupname: group.groupname,
                        }).then((response) => {
                            let userGroupMessage = {
                                header: 'Groups were added to local user',
                                content: 'Groups were added to local user.',
                                type: 'positive',
                            };

                            if (!response.data.rv) {
                                userGroupMessage.header = 'Groups were not added to local user';
                                userGroupMessage.content = response.data.error.message;
                                userGroupMessage.type = 'negative';
                            }

                            addGlobalMessage(userGroupMessage);
                        }).catch(e => null)
                    );
                }
            }

            if (removedGroups.length > 0) {
                for (const group of removedGroups) {
                    requests.push(
                        NMService.removeLocalUserFromGroup(state.selectedUser, group.groupname).then(
                            (response) => {
                                let userGroupMessage = {
                                    header: 'Group was removed from local user',
                                    content: 'Group was removed from local user.',
                                    type: 'positive',
                                };

                                if (!response.data.rv) {
                                    userGroupMessage.header =
                                        "Group wasn't removed from local user";
                                    userGroupMessage.content = response.data.error.message;
                                    userGroupMessage.type = 'negative';
                                }

                                addGlobalMessage(userGroupMessage);
                            }
                        ).catch(e => null)
                    );
                }
            }

            await Promise.all(requests).finally(() => {
                refetchUserList();
                dispatch({ type: localUserActions.RESET })
            });
            if (state.selectedUser && (state.selectedUser === userdata.username)) {
                logout();
            }
        })();
    };

    useEffect(() => {
        fetch('/api/v1.0/useridentity/version/')
            .then(r => r.json())
            .then((r) => setNodeIdentityVersion(r?.version || "0.0.")).catch(e => null)
        if (state.selectedUser) {
            if (!userDataIsLoading) {
                const { username, role, fullname, email, active, groups = [] } = userData;
                reset({ username, role, fullname, email, active, groups })
            }
        } else {
            reset({ username: '', role: '', fullname: '', email: '', active: '', groups: []});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.selectedUser, userDataIsLoading]);

    return userRoleListIsLoading && roleListIsLoading && groupListIsLoading ? null : (
        <>
            <Header as="h4" style={{ marginTop: '2rem', marginBottom: '0' }}>
                {!state.selectedUser ? 'Add new user' : 'Edit user'}
            </Header>
            <Divider clearing style={{ marginTop: '0.5rem', marginBottom: '1rem' }} />

            <Form className="basic segment" onSubmit={handleSubmit(onSubmit)}>
                <Grid padded>
                    {!state.selectedUser && (
                        <Grid.Row verticalAlign="middle" style={row}>
                            <Grid.Column width={2}>Username</Grid.Column>
                            <Grid.Column width={6}>
                                {renderInput("Username", "username", { hideLabel: true, validate: (v) => validateUsername(v) })}
                            </Grid.Column>
                        </Grid.Row>
                    )}

                    <Grid.Row verticalAlign="middle" style={row}>
                        <Grid.Column width={2}>Name</Grid.Column>
                        <Grid.Column width={6}>
                            {renderInput("Fullname", "fullname", { hideLabel: true })}
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row verticalAlign="middle" style={row}>
                        <Grid.Column width={2}>E-Mail</Grid.Column>
                        <Grid.Column width={6}>
                            {renderInput("E-mail address", "email", { hideLabel: true })}
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row verticalAlign="middle" style={row}>
                        <Grid.Column width={2}>Role</Grid.Column>
                        <Grid.Column width={6}>
                            {renderDropdown("Role", "role", userRoleList, { hideLabel: true })}
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row verticalAlign="middle" style={row} columns={1}>
                        <Grid.Column textAlign="left">
                            <label>Groups</label>
                            <Icon
                                link
                                name="plus"
                                style={{ color: '#4183C4', marginLeft: '1rem' }}
                                onClick={() => append({ groupname: '', role: '' })}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    {fields.map((r, index) => (
                        <Grid.Row key={r.id} verticalAlign="middle" style={row} >
                            <Grid.Column width={2} textAlign="right">
                                <Form.Field>
                                    <Icon
                                        link
                                        name="trash alternate"
                                        style={{ color: '#4183C4', marginLeft: '1rem' }}
                                        onClick={() => remove(index)}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                {renderDropdown("Group name", `groups.${index}.groupname`, groupList)}
                            </Grid.Column>
                            <Grid.Column width={5}>
                                {renderDropdown("Group role", `groups.${index}.role`, roleList)}
                            </Grid.Column>
                        </Grid.Row>
                    ))}

                    <Grid.Row verticalAlign="middle" style={row}>
                        <Grid.Column width={2}>Status</Grid.Column>
                        <Grid.Column width={3}>
                            {renderDropdown("Active", 'active', [{ key: 'active', value: 1, text: 'Active' }, { key: 'locked', value: 0, text: 'Locked' }], { hideLabel: true })}
                        </Grid.Column>
                    </Grid.Row>

                    {!state.selectedUser && (
                        <>
                            <Grid.Row verticalAlign="middle" style={row}>
                                <Grid.Column width={2}>Password</Grid.Column>
                                <Grid.Column width={6}>
                                    {renderInput("Password", "password1", { inputType: 'password', hideLabel: true, validate: (v) => validatePassword(v) })}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row verticalAlign="middle" style={row}>
                                <Grid.Column width={2}>Password (again)</Grid.Column>
                                <Grid.Column width={6}>
                                    {renderInput("Password", "password2", { inputType: 'password', hideLabel: true, validate: (v) => validatePassword(v) })}
                                </Grid.Column>
                            </Grid.Row>
                        </>
                    )}

                    <Grid.Row verticalAlign="middle">
                        <Grid.Column width={2}></Grid.Column>
                        <Grid.Column width={6}>
                            <Form.Group>
                                <PermissionsGateV hasPermission={hasPermission}>
                                    <Form.Button size="small" type='submit' primary content='Save'/>
                                </PermissionsGateV>
                                <Form.Button
                                    type="button"
                                    size="small"
                                    content='Cancel'
                                    onClick={() => dispatch({ type: localUserActions.RESET })}
                                />
                            </Form.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </>
    );
}

export default LocalUserForm;
