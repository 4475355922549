import { atomWithReducer } from 'jotai/utils';

export const localUserActions = {
    ADD_USER: 'add-user',
    EDIT_USER: 'edit-user',
    DELETE_USER: 'delete-user',
    CHANGE_USER_PASSWORD: 'change-user-password',
    SET_PERMISSION: 'set-permission',
    RESET: 'reset'
}

const INITIAL_STATE = {
    selectedUser: "",
    hasPermission: false,
    showType: "",
}

const localUsersReducer = (prevState, { type, value }) => {
    switch (type) {
        case localUserActions.ADD_USER:
            return {
                ...prevState,
                selectedUser: '',
                showType: localUserActions.ADD_USER,
            };
        case localUserActions.DELETE_USER:
            return {
                ...prevState,
                selectedUser: value,
                showType: localUserActions.DELETE_USER,
            };
        case localUserActions.EDIT_USER:
            return {
                ...prevState,
                selectedUser: value,
                showType: localUserActions.EDIT_USER,
            };
        case localUserActions.CHANGE_USER_PASSWORD:
            return {
                ...prevState,
                selectedUser: value,
                showType: localUserActions.CHANGE_USER_PASSWORD,
            };
        case localUserActions.RESET:
            return {
                ...prevState,
                ...{
                    selectedUser: "",
                    showType: "",
                }
            };
        default:
            return INITIAL_STATE

    }
}

export const localUsersAtom = atomWithReducer(INITIAL_STATE, localUsersReducer);
