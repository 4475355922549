import { Header, Icon } from "semantic-ui-react";

const CustomHeader = ({ toggle, title, show, showCaret = true, marginTop = '1rem', marginBottom = '0.5rem' }) => (
    <>
        <Header
            as="h4"
            style={{ cursor: "pointer", marginTop: marginTop, marginBottom: marginBottom }}
            onClick={toggle}
            dividing
        >
            {title}
            {showCaret && <Icon
                name={`caret ${show ? "up" : "down"}`}
                size="large"
                style={{ float: "right", marginRight: "0" }}
            />}
        </Header>
    </>
);

export default CustomHeader;
