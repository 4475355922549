import { useQuery } from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchAlarms = ({
    select,
    options
} = {}) => {
    const query = useQuery({
        queryKey: ['getAlarms'],
        queryFn: () => 
             NMService.getAlarms()
            .then(r => r?.data)
            .catch(e => [])
        ,
        staleTime: Infinity,
        select,
        ...options
    })
    return { ...query, data: query.data ?? [] }
}