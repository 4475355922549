import { useForm } from "react-hook-form";
import { useFormFields } from "../../../../hooks/useFormFields";
import { Divider, Form, Header } from "semantic-ui-react";
import NMFieldValidator from "../../../../utils/NMFieldValidator";
import { useEffect } from "react";
import NMService from "../../../../services/nm.service";
import { addGlobalMessageAtom } from "../../../../store/globalMessage";
import { useSetAtom } from "jotai";

const EventCollectorSourceGroupForm = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);

    const {
        register,
        setValue,
        watch,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const { renderInput } = useFormFields({ register, errors, setValue, watch });

    const onSubmit = (values) =>{
        if (typeof values.description === 'string' && values.description.trim() === '')
            delete values.description
        values.uuid ? 
        NMService.putECSourceGroup(values).then((r) =>
            addGlobalMessage({
                header: "Source group updated successfully",
                content: "Source group updated successfully",
                type: "positive",
            })
        ).catch(e => null)
        .finally(() => props.close()) :
        NMService.postECSourceGroup(values).then((r) =>
            addGlobalMessage({
                header: "Source group added successfully",
                content: "Source group added successfully",
                type: "positive",
            })
        ).catch(e => null)
        .finally(() => props.close())
    }

    useEffect(() => {
        props.data?.uuid ? reset(props.data) : reset({ name: "", description: "" })
    }, [props.data, reset])

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Header as="h4" style={{ margin: "1rem 0.5rem" }}>
                {props.data?.uuid ? "Edit source group" : "Add new source group"}
            </Header>
            <Divider style={{ marginLeft: "0.5rem", marginTop: "0.5rem", marginBottom: "1.5rem" }} />
            <div style={{ padding: "1rem" }}>
                <Form.Group widths={3}>
                    {renderInput("Name", "name", { validate: v => NMFieldValidator.validateString(v, /^[a-zA-Z0-9._-]{1,32}$/, 1, 32, true) })}
                    {renderInput("Description", "description", { validate: v => NMFieldValidator.validateString(v, /^[a-zA-Z0-9._ -]{1,255}$/, 1, 255), notRequired: true })}
                </Form.Group>
                <Form.Group>
                    <Form.Button size="small" type="submit" primary content="Save" />
                    <Form.Button
                        size="small"
                        type="button"
                        onClick={() => props.close()}
                        content="Cancel"
                    />
                </Form.Group>
            </div>
        </Form>
    );
}

export default EventCollectorSourceGroupForm;