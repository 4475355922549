import React, { useState } from 'react';
import { Header, Divider, Table, Icon, Button } from 'semantic-ui-react';
import VisualizationDashTemplateForm from './VisualizationDashTemplateForm';
import NMService from '../../services/nm.service';
import { useUserInfo } from '../../hooks/useAuth';
import { useFetchVisDashTemplates } from '../../hooks/useFetchVisDashTemplates';
import ConfirmationModal from '../../components/modal/ConfirmationModal';


const VisualizationDashTemplates = (props) => {
    const { userdata: { role, tenantid } } = useUserInfo();

    const [state, setState] = useState({
        selectedVisDashTemplateId: '',
        showSegment: false,
        showForm: false,
        showDeleteModal: false,
    })

    const { data, isLoading, refetch } = useFetchVisDashTemplates({
        moduleId: props.moduleId,
        options: {
            refetchOnMount: 'always',
        }
    });

    const deleteVisTemplate = () => {
        NMService.deleteSWModuleVisDashTemplate(props.moduleId, state.selectedVisDashTemplateId).then(() =>
            setState({
                showDeleteModal: false,
                showSegment: true,
                showForm: false,
                selectedVisDashTemplateId: '',
            })).catch(e => null)
        .finally(() => refetch());
    }

    const enabled = (global) =>
        (role === 'tenantadmin' && 
            ((tenantid === "public" && !!(global ?? true))
          || (tenantid !== "public" && !!!(global ?? false))))
        || (role === 'admin' && !!!(global ?? false))

    return (isLoading ? null :
        <>
            <Header
                as="h4"
                style={{ cursor: 'pointer', marginTop: '1rem', marginBottom: '0' }}
                onClick={() => setState(p => ({ ...p, showSegment: !p.showSegment }))}
            >
                Visualization dashboard templates
                <span>
                    <Icon
                        name="add"
                        color="blue"
                        disabled={!enabled(undefined)}
                        style={{ marginLeft: '1rem', marginRight: '0' }}
                        onClick={(e) => {
                            e.stopPropagation()
                            setState(prevState => ({
                                ...prevState,
                                showForm: true,
                                showSegment: true,
                                selectedVisDashTemplateId: '',
                            }))
                        }}
                    />
                </span>
                <Icon
                    name={`caret ${state.showSegment ? 'up' : 'down'}`}
                    size="large"
                    style={{ float: "right", marginRight: "0" }}
                />
            </Header>
            <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
            {state.showSegment && data.length > 0 && (
                <Table singleline="true" collapsing style={{ marginLeft: "2rem" }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1} />
                            <Table.HeaderCell>Dashboard template ID</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {data.map((row) => (
                            <Table.Row key={row.id}>
                                <Table.Cell>
                                    {row?.global &&
                                        <Icon
                                            name="globe"
                                            circular
                                            inverted
                                            title="Global template"
                                            color='green'
                                            disabled
                                        />
                                    }
                                </Table.Cell>
                                <Table.Cell>{row.id}</Table.Cell>
                                <Table.Cell>
                                    <Button
                                        circular
                                        icon="edit"
                                        size="tiny"
                                        onClick={() => {
                                            setState((prevState) => ({
                                                ...prevState,
                                                showForm: true,
                                                selectedVisDashTemplateId: row.id,
                                            }));
                                        }}
                                    />
                                    <Button
                                        circular
                                        disabled={!enabled(row.global)}
                                        icon="trash"
                                        size="tiny"
                                        onClick={() => {
                                            setState((prevState) => ({
                                                ...prevState,
                                                showDeleteModal: true,
                                                showSegment: true,
                                                showForm: false,
                                                selectedVisDashTemplateId: row.id,
                                            }));
                                        }}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )}

            {(state.showSegment && (state.showForm || data.length === 0)) && (
                <VisualizationDashTemplateForm
                    key={state.selectedVisDashTemplateId}
                    moduleid={props.moduleId}
                    version={props.version}
                    disabled={!enabled(data.find(e => e.id === state.selectedVisDashTemplateId)?.global)}
                    selectedVisDashTemplateId={state.selectedVisDashTemplateId}
                    visDashTemplates={data}
                    closeForm={() => setState((prevState) => ({ ...prevState, showForm: false, selectedVisDashTemplateId: '' }))}
                    refreshTemplates={refetch}
                />
            )}
            
            <ConfirmationModal
                open={state.showDeleteModal && state.selectedVisDashTemplateId}
                header="Delete panel template"
                content="Are you sure you want to delete selected visualization panel template?"
                onConfirm={deleteVisTemplate}
                onDismiss={() =>
                    setState({
                        showDeleteModal: false,
                        showSegment: true,
                        showForm: false,
                        selectedVisPanelTemplateId: '',
                    })}
            />
        </>
    );
}

export default VisualizationDashTemplates;