import { useQuery } from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchLocalUsers = ({
    select,
    options
}) => {
    const query = useQuery({
        queryKey: ['getLocalUsers'],
        queryFn: () =>
            NMService.getLocalUsers()
                .then(r => r.data)
                .catch(e => []),
        staleTime: Infinity,
        select,
        ...options
    })
    return { ...query, data: query.data ?? [] }
}