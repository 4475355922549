import { Fragment } from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import useErrorHandler from "../hooks/useErrorHandler";

export const ProtectedLayout = () => {
    const { user, logout } = useAuth();
    const token = window.localStorage.getItem("token");
    const outlet = useOutlet();

    useErrorHandler(logout);

    if (!token || !user?.userdata?.username) {
        return <Navigate to="/login" />;
    }

    return <Fragment>{outlet}</Fragment>;
};