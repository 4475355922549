import { atomWithReducer } from 'jotai/utils';

export const groupsActions = {
    AddGroup: 'add-group',
    DeleteGroup: 'delete-groups',
    ClearShowType: 'close-all'
}

const INITIAL_STATE = {
    selectedGroup: '',
    showType: '',
}

const groupsReducer = (prevState, { type, value }) => {
    switch (type) {
        case groupsActions.AddGroup:
            return {
                ...prevState,
                selectedGroup: '',
                showType: groupsActions.AddGroup,
            };
        case groupsActions.DeleteGroup:
            return {
                ...prevState,
                selectedGroup: value,
                showType: groupsActions.DeleteGroup,
            };
        case groupsActions.ClearShowType:
            return INITIAL_STATE
        default:
            return INITIAL_STATE

    }
}

export const groupsAtom = atomWithReducer(INITIAL_STATE, groupsReducer);
