import LicenseSegment from "./LicensesSegment";
import UsedLicenseTable from "./UsedLicenseTable";

const LicenseManagment = () => (
    <>
        <UsedLicenseTable />
        <LicenseSegment />
    </>
);

export default LicenseManagment;
