import { useForm } from "react-hook-form";
import { useFormFields } from "../../../../hooks/useFormFields";
import { Form, FormGroup } from "semantic-ui-react";
import { useEffect, useMemo, useState } from "react";
import CustomHeader from "../../../../components/CustomHeader";

export const SMSNotificationForm = (props) => {
    const severityList = useMemo(() => props.severityList || [], [props.severityList])
    const [showTemplate, setShowTemplate] = useState(false)
    const templateKeys = [
        [["alarm", "Measurements alarm trigger body"], ["clear", "Measurements alarm clear body"]],
        [["alarmprobe", "Probe alarm trigger body"], ["clearprobe", "Probe alarm clear body"]],
        [["alarmsystem", "System alarm trigger body"], ["clearsystem", "System alarm clear body"]],
    ];
    const defaultConfig = {
        "base-url": "",
        "api-key": "",
        "recipients": "",
        ...Object.entries(props.template)
                        .map((e) => ({ [e[0].replaceAll(".", "-")]: e[1] }))
                        .reduce((e, k) => ({ ...e, ...k }), {}),
    };
    const {
        register,
        setValue,
        reset,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: defaultConfig });

    const { renderInput, renderDropdown } = useFormFields({ register, errors, setValue, watch });

    useEffect(() => {
        if (props.data) {
            reset({
                ...Object.entries(props.data.parameters)
                        .map((e) => ({ [e[0].replaceAll(".", "-")]: e[1] }))
                        .reduce((e, k) => ({ ...e, ...k }), {}),
                name: props.data.name,
                ...Object.entries(props.data.template)
                                .map((e) => ({ [e[0].replaceAll(".", "-")]: e[1] }))
                                .reduce((e, k) => ({ ...e, ...k }), {}),
            });
        }
    }, [reset, props.data]);

    return (
        <Form onSubmit={handleSubmit(props.post)}>
            <FormGroup widths={4}>{renderInput("Name", "name")}</FormGroup>
            <FormGroup widths={4}>
                {renderInput("Base URL", "base-url", { infoText: "Infobip API base URL ([...].api.infobip.com)" })}
                {renderInput("API key", "api-key", { inputType: "password", infoText: "API key associated with Infobip account" })}
            </FormGroup>
            <FormGroup widths={2}>
                {renderInput("Recipient phone number(s)", "recipients", { infoText: "Phone numbers in international notation (ie. 385...) separated by commas ( , )" })}
            </FormGroup>
            <Form.Group widths={4}>
                {renderDropdown("Severity filter", "severity-filter", severityList, { width: 8, multiple: true, notRequired: true, infoText: "Select which alarm severity should trigger notification sending via this channel (leave empty for no filter)" })}
            </Form.Group>
            <CustomHeader
                title='Template'
                toggle={() => setShowTemplate(p => !p)}
                show={showTemplate}
            />
            {showTemplate && templateKeys.map(e =>
                    <Form.Group key={e[0][0]}>
                        {renderInput(e[0][1], e[0][0], { width: 11, inputType: 'textarea', rows: 7, mono: true })}
                        {renderInput(e[1][1], e[1][0], { width: 5, inputType: 'textarea', rows: 7, mono: true })}
                    </Form.Group>
                )}
            <Form.Group>
                <Form.Button primary type="submit" content={props.data?.id ? "Update" : "Add"} />
                <Form.Button type="button" content="Cancel" onClick={props.close} />
            </Form.Group>
        </Form>
    );
};
