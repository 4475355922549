import { atomWithReducer } from 'jotai/utils';

export const locationParametersActions = {
    ADD_NEW_LOCATION_PARAMETER: 'add-new-location-parameter',
    DELETE_LOCATION_PARAMETER: 'delete-location-parameter',
    CLEAR_TYPE: 'clear-type'
}

const INITIAL_STATE = {
    selectedLocation: {},
    showType: ''
}

const locationParametersReducer = (prevState, { type, value }) => {
    switch (type) {
        case locationParametersActions.ADD_NEW_LOCATION_PARAMETER:
            return {
                ...prevState,
                selectedLocation: {},
                showType: locationParametersActions.ADD_NEW_LOCATION_PARAMETER
            };
        case locationParametersActions.DELETE_LOCATION_PARAMETER:
            return {
                selectedLocation: value,
                showType: locationParametersActions.DELETE_LOCATION_PARAMETER
            }
        case locationParametersActions.CLEAR_TYPE:
            return INITIAL_STATE
        default:
            return INITIAL_STATE

    }
}

export const locationParametersAtom = atomWithReducer(INITIAL_STATE, locationParametersReducer);
