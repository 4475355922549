import { atomWithReducer } from 'jotai/utils';

export const globalSnmpSecurityProfileActions = {
    AddSecurityProfile: 'add-security-profile',
    EditSecurityProfile: 'edit-security-profile',
    DeleteSecurityProfile: 'delete-hosecurity-profilest',
    ClearShowType: 'close-security-profile'
}

const INITIAL_STATE = {
    selectedSecurityProfile: {},
    showType: '',
}

const reducer = (prevState, { type, value }) => {
    switch (type) {
        case globalSnmpSecurityProfileActions.AddSecurityProfile:
            return {
                ...prevState,
                selectedSecurityProfile: {},
                showType: globalSnmpSecurityProfileActions.AddSecurityProfile,
            };
        case globalSnmpSecurityProfileActions.EditSecurityProfile:
            return {
                ...prevState,
                selectedSecurityProfile: value,
                showType: globalSnmpSecurityProfileActions.EditSecurityProfile,
            };
        case globalSnmpSecurityProfileActions.DeleteSecurityProfile:
            return {
                ...prevState,
                selectedSecurityProfile: value,
                showType: globalSnmpSecurityProfileActions.DeleteSecurityProfile,
            };
        case globalSnmpSecurityProfileActions.ClearShowType:
            return INITIAL_STATE;
        default:
            return INITIAL_STATE;
    }
}

export const globalSnmpSecurityProfileAtom = atomWithReducer(INITIAL_STATE, reducer);
