import { 
    ADMIN, 
    GROUP_ADMIN, 
    MEMBERS, 
    NODE_ADMIN, 
    READ_ONLY, 
    TENANT_ADMIN, 
    USER 
} from "../constants/layout";

export const getRoleValue = (role) => {
    if ([TENANT_ADMIN, ADMIN].includes(role)) {
        return 4;
    } else if ([NODE_ADMIN, GROUP_ADMIN].includes(role)) {
        return 3;
    } else if ([USER, MEMBERS].includes(role)) {
        return 2;
    } else if (role === READ_ONLY) {
        return 1;
    } else {
        return 0;
    }
};

export const getPermission = ({ userRole, requiredRole }) => {
    return getRoleValue(requiredRole) <= getRoleValue(userRole);;
};

export const getGroupRoleValue = (userGroups, requiredGroups) => {
    const userGroupList = userGroups.map((group) => group.groupname);
    return requiredGroups.some((reqGroup) => userGroupList.includes(reqGroup));
};

export const getModulePermission = ({ userRole, userGroups, requiredGroups }) => {
    const reqRoleValue = 3;
    const userRoleValue = getRoleValue(userRole);

    if (Array.isArray(requiredGroups)) {
        if (!requiredGroups.length) return false;
        if (userRoleValue < reqRoleValue) {
            return getGroupRoleValue(userGroups, requiredGroups);
        } else {
            return userRoleValue >= reqRoleValue;
        }
    } else {
        return userRoleValue >= reqRoleValue;
    }
};

const getPermissionByUserGroups = ( userGroups, requiredGroups, requiredPermissions ) => {
    let permissions = {
        assignmodules: false,
        editbasicdata: false,
        editpermissions: false
    }

    if (!Array.isArray(userGroups) || !Array.isArray(requiredGroups)) {
        return permissions
    }

    userGroups.forEach(userGroup => {
        const { groupname, role } = userGroup;

        if (requiredGroups.includes(groupname)) {
            Object.keys(requiredPermissions).forEach(key => {
                switch (requiredPermissions[key]) {
                    case 'owner':
                        break;
                    case 'any':
                        if (getRoleValue(role) > 1) {
                            permissions[key] = true;
                        }
                        break
                    case ('members'):
                        permissions[key] = true;
                        break
                    case ('groupadmin'):
                        if (getRoleValue(role) >= getRoleValue(requiredPermissions[key])) {
                            permissions[key] = true;
                        }
                        break;
                    default:
                        break
                }
            })
        }
    });
    return permissions
}

export const getProbePermission = (userRole, userGroups, requiredGroups, requiredPermissions) => {
    let permissions = {
        assignmodules: false,
        editbasicdata: false,
        editpermissions: false
    }

    if ([ADMIN, NODE_ADMIN, TENANT_ADMIN].includes(userRole)) {
        permissions = {
            assignmodules: true,
            editbasicdata: true,
            editpermissions: true
        };
    } else if (userRole === USER) {
        permissions = getPermissionByUserGroups(userGroups, requiredGroups, requiredPermissions);
    }

    return permissions
}